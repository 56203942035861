import "../css/layout.css";
import "../css/3dlogo.css";
import React, { useEffect, useState, useRef } from "react";
import DashboardMode from "./DashboardMode";
import { state as state, StateManager } from "../state/stateManager";
import fetcher from "../lib/api-fetcher";
import FullGlobeMode from "./FullGlobeMode";
import { observer } from "mobx-react-lite";
import LoginSplash from "./boxes/LoginSplash";
import ConnectionError from "./boxes/ConnectionError";
import Alerts from "./Alert";
import { config } from "../state/config";
const DashboardRootV3 = observer((props) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [authenticated, setAuthenticated] = useState(null);
  const [loadError, setLoadError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [getDataException, setGetDataException] = useState(false);
  const [isAlertActive, setIsAlertActive] = useState(false);

  useEffect(() => {
    //stateManager.registerSockets();
    //first time loading, force load data
    if (!state.initiated) {
      getDashboardData();

      // config.loadConfig((data)=> {
      //     if (data.authenticated) {
      //         getDashboardData();
      //     }
      //     else {
      //         //login
      //         setAuthenticated(false);
      //     }
      // });
    } else if (!loadError) {
      setDataLoaded(true);
    }
  }, [dataLoaded]);

  useEffect(() => {
    if (!state.connection_error) {
      let init = state.initiated;
      let loaded = dataLoaded;
      let excption = getDataException;
      if (excption && !loaded) {
        getDashboardData();
      }
      //debugger;
      // alert("connection error")
      //setDataLoaded(!dataLoaded);
    }
  }, [state.connection_error]);

  const getComponent = () => {
    if (!state.connection_error) {
      let globeFullscreenState = state.getMenuItemLogicalState(
        StateManager.MenuKeys.FULL_SCREEN_ACTIVATION
      );
      let isFullscreenEnabled =
        globeFullscreenState == StateManager.MenuLogicalStates.ENABLED;
      return dataLoaded ? (
        !isFullscreenEnabled ? (
          <DashboardMode />
        ) : (
          <FullGlobeMode />
        )
      ) : (
        <LoginSplash
          isError={loadError}
          errorMessage={errorMessage}
          authenticated={authenticated}
        />
      );
    } else return <ConnectionError />;
  };

  const getDashboardData = () => {
    //get query params
    fetcher.getDashboardData(document.location.search).then((result) => {
      if (result.authenticated) {
        //set config
        config.bypass = result.bypass;
        config.setConfig(result["config"]);
        config.initiated = true;
        config.isAdmin = result.isAdmin;
        //check for errors
        let errors = [];

        Object.keys(result).forEach((key) => {
          let item = result[key];
          if (item.error) {
            if (state.showStoppersConfig[key]) {
              errors.push(key);
            }
            state.updateFailedObject(key, false, state.showStoppersConfig[key]);
          }
        });

        if (errors.length > 0) {
          setLoadError(true);
          let msg = errors.length === 1 ? errors[0] : "multiple objects";
          setErrorMessage(`error. error loading ${msg}`);
          state.startListening();
          return;
        }
        if (Object.keys(result.dashboard_data).length === 0) {
          //error no dashboard data
          setLoadError(true);
          setErrorMessage("error. no dashboard data found");
        } else {
          state.setAll(result);
          setDataLoaded(true);
          state.startListening();
        }
        setGetDataException(false);
      } else if (result.authenticated === false) {
        setAuthenticated(false);
      } else if (result.error) {
        setGetDataException(true);
        //alert("error")
        //will be handled by the iosocket
      }
    });
  };
  useEffect(() => {
    if (state.failedToLoad) {
      let keys = Object.keys(state.failedToLoad);
      if (keys.length === 0 && state.initiated) {
        //all good
        setDataLoaded(true);
      } else if (keys.length > 0) {
        //check if errors are show stoppers
        let showStoppersExists = Object.values(state.failedToLoad).find(
          (x) => x.isShowStopper === true
        );
        //error
        if (showStoppersExists) {
          setLoadError(true);
          setDataLoaded(false);
          setErrorMessage(
            `error. error loading <b class='amount'> ${keys.join()} </b>, the page will reload when the data stream will be available`
          );
        }
      }
    }

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible' && state.socketDisconnected) {
       state.connectSocket();
       getDashboardData();
        //setSocket(reconnectedSocket);
       // setIsDisconnected(false);
      }
      //check if visibility is not visible and disconnect socket
      if (document.visibilityState === 'hidden' && state.socket) {
        state.socket.disconnect();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      state.socket.disconnect();
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [state.failedToLoad]);
  const onAlert = (isAlertActive) => {
    setIsAlertActive(isAlertActive);
  };
  return (
    <>
      <div className="root-alerts-container" data-isalertactive={isAlertActive}>
        {getComponent()}
      </div>
      <Alerts onAlert={onAlert} />
    </>
  );
});

export default DashboardRootV3;
