import { useEffect } from 'react';

function useMetaRefresh(refreshIntervalInMinutes) {
  useEffect(() => {
    const refreshIntervalInMillis = refreshIntervalInMinutes * 60 * 1000;
    const metaTag = document.createElement('meta');
    metaTag.httpEquiv = 'refresh';
    metaTag.content = refreshIntervalInMillis / 1000;
    document.head.appendChild(metaTag);

    // Cleanup function to remove the meta tag when the component unmounts
    return () => {
      document.head.removeChild(metaTag);
    };
  }, [refreshIntervalInMinutes]);
}

export default useMetaRefresh;
