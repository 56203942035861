class GlobeHelper {
  getWarmerColor(number, maxNumber) {
    // Ensure the number is within the range
    number = Math.min(Math.max(number, 0), maxNumber);

    // Normalize the number to a range of 0 to 1
    const normalized = number / maxNumber;

    // Interpolate the green component of the color
    // Both red and green start at 255 (FF in hex), and green decreases to 0
    const green = 255 - Math.round(normalized * 255);

    // Convert the green component to a hex string
    const greenHex = green.toString(16).padStart(2, "0").toUpperCase();

    // Return the color in hex format (#RRGGBB)
    return `#FF${greenHex}00`;
  }

  getPointAltitude(amount, maxOrderAmount) {
    // Define the minimum and maximum values for the altitude
    const minAltitude = 0.02;
    const maxAltitude = 0.1;

    // Define the minimum and maximum order amounts
    // Adjust these values according to your needs
    const minOrderAmount = 0;

    // Ensure the order amount falls within the expected range
    amount = Math.max(minOrderAmount, Math.min(amount, maxOrderAmount));

    // Calculate the proportion of the order amount within its range
    const proportion =
      (amount - minOrderAmount) / (maxOrderAmount - minOrderAmount);

    // Calculate and return the altitude based on this proportion
    const ret = minAltitude + proportion * (maxAltitude - minAltitude);

    return ret;
  }

  getLabelSize(input, maxInput) {
    // Define the minimum and maximum input values
    const minInput = 0; // Adjust as needed

    // Define the output range
    const minOutput = 0.2;
    const maxOutput = 2.0;

    // Ensure the input is within the expected range
    input = Math.max(minInput, Math.min(input, maxInput));

    // Calculate the proportion of the input within its range
    const proportion = (input - minInput) / (maxInput - minInput);

    // Calculate and return the scaled output
    return minOutput + proportion * (maxOutput - minOutput);
  }

  getMarkersData(routesInfo) {
    const data = routesInfo.map((route) => {
      if (!route.cityTarget) {
        let cp = this.getCloseCoord(route.to_latitude, route.to_longitude);
        return {
          lat: cp.latitude,
          lng: cp.longitude,
          size: 6,
        };
      } else {
        return {
          lat: route.to_latitude,
          lng: route.to_longitude,
          size: 6,
        };
      }
    });

    return data;
  }
  /*
   getLabelsData(routesInfo,maxOrderAmount) {
        const data = routesInfo.map((route)=> {
            let cp = this.getCloseCoord(route.to_latitude,route.to_longitude);
            return {
                lat : cp.latitude,
                lng : cp.longitude,
                text : "$" + route.amount,
                altitude :  this.getPointAltitude(route.amount,maxOrderAmount) + 0.05,
                size : this.getLabelSize(route.amount,maxOrderAmount),
                labelDotRadius : 0.1,
                color : this.getPointColor(route.amount,maxOrderAmount),
                labelResolution : 2
            }
        }); 
        return [];
        //return data;
   }
   */
  getCloseCoord(lat, lng) {
    // Convert latitude and longitude to radians
    let latRad = lat * (Math.PI / 180);
    let lngRad = lng * (Math.PI / 180);

    // Random distance in kilometers, up to 5km
    let randomDistance = Math.random() * 25;

    // Convert distance to radians (1 degree is approx. 111 kilometers)
    let distanceRad = randomDistance / 111;

    // Random angle
    let randomAngle = Math.random() * 2 * Math.PI; // Angle in radians

    // Calculate new lat and lng in radians
    let newLatRad = latRad + distanceRad * Math.cos(randomAngle);
    let newLngRad =
      lngRad + (distanceRad * Math.sin(randomAngle)) / Math.cos(latRad);

    // Convert radians back to degrees
    let newLat = newLatRad * (180 / Math.PI);
    let newLng = newLngRad * (180 / Math.PI);

    return { latitude: newLat, longitude: newLng };
  }

  /*
  getPointsData(routesInfo, maxOrderAmount) {
    const data = routesInfo.map((route) => {
      return {
        amount: route.amount,
        lat: route.to_latitude,
        lng: route.to_longitude,
        //altitude:  getPointAltitude(route.amount),
        size: 0.5,
        //color : getPointColor(route.amount,maxOrderAmount)
      };
    });

    return [];
    //return data;
  }
*/
  getRndTime() {
    const min = 3000;
    const max = 6000;
    const randTime = Math.floor(Math.random() * (max - min + 1) + min);
    return randTime;
  }

  getPointColor(orderAmount, maxOrderAmount) {
    const minOrderAmount = 0;

    // Ensure the order amount is within the expected range
    orderAmount = Math.max(
      minOrderAmount,
      Math.min(orderAmount, maxOrderAmount)
    );

    // Calculate the proportion of the order amount within its range
    const proportion =
      (orderAmount - minOrderAmount) / (maxOrderAmount - minOrderAmount);

    // Interpolate between soft yellow (RGB(255, 255, 100)) and green (RGB(0, 255, 0))
    const red = Math.round((1 - proportion) * 255);
    const green = 255;
    const blue = Math.round(100 + (1 - proportion) * 155); // Decreasing from 100 to 0

    // Convert RGB values to a hex color string
    return (
      "#" +
      red.toString(16).padStart(2, "0") +
      green.toString(16).padStart(2, "0") +
      blue.toString(16).padStart(2, "0")
    );
  }
}
const gh = new GlobeHelper();
export default gh;
