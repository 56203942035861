import React, { useEffect, useState } from "react";
import DBLineChart from "../../common/DBLIneChart";
import { observer } from "mobx-react-lite";
import { state } from "../../../state/stateManager";
import GeneralBox from "../../general/GeneralBox";
import moment from "moment";

import ZoomSwitch from "../../common/ZoomSwitch";
const WeeklyAmounts = observer(() => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [zoomEnabled, setZoomEnabled] = useState(false);
  useEffect(() => {
    //OrderCounter
    if (state.weeklyStatistics) {
      const dates = Object.keys(state.weeklyStatistics.chart_amount.data);
      const formatedFrom = moment(dates[0]).format("DD/MM/YYYY");
      const formatedTo = moment(dates[dates.length - 1]).format("DD/MM/YYYY");
      setFromDate(formatedFrom);
      setToDate(formatedTo);
    }
  }, [state.weeklyStatistics]);
  const handleChange = (event) => {
    setZoomEnabled(event.target.checked);
  };
  return (
    <GeneralBox
      dPadding="2"
      hasBorder={false}
      hasBackground={true}
      css={"d-flex flex-column flex-grow-1 main-charts-container"}
    >
      <div className="unique-sessions-title weekly-trends d-flex">
        <div>
          <i className="bi bi-coin"></i>
        </div>
        <div className="trends-header-container d-flex align-items-center flex-wrap justify-content-between w-100">
          <div className="trends-header-text">
            {" "}
            Sales information for the last week (
            <span className="highlight-amount">{fromDate}</span> -{" "}
            <span className="highlight-amount">{toDate}</span>)
          </div>
          <div className="trends-switch-container">
            <ZoomSwitch label={"Zoom"} onChange={handleChange} />
          </div>
        </div>
      </div>
      <DBLineChart
        enableZoom={zoomEnabled}
        startZoomed={true}
        interaction_mode="index"
        width=""
        AxisYModulo={2000000}
        AxisYReduction={1000000}
        AxisYCaption="Total orders value"
        Suffix="M"
        AxisXCaption="Hours"
        statistics={
          state.weeklyStatistics && state.weeklyStatistics.chart_amount
        }
      />
    </GeneralBox>
  );
});

export default WeeklyAmounts;
