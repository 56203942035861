const ComponentError = ({ componentKey, height = "100%", width= "100%" }) => {

    return  (
        <div className="d-flex align-items-center justify-content-center" style={{width:width, height:height,textAlign:"center"}}>
            error loading component {componentKey} <br />
            data will be reloaded when data stream is available
        </div>
    )

}

export default ComponentError;