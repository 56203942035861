import { useEffect } from "react"

const GMVPercentChange = ({ percentDiff }) => { 
    useEffect(() => {
 
    }, [percentDiff]);

    return (
        <div data-visible={percentDiff!==null} title="Today previous hour compared to yesterday previous hour" data-positive={percentDiff>=0} className="d-flex GMVPercentChange align-items-end">
            <i className={"bi " + (percentDiff>=0?"bi-caret-up-fill":"bi-caret-down-fill")}></i>
            {percentDiff}%
        </div>
    )
}

export default GMVPercentChange