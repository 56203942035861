import React, { useEffect, useRef, useState } from "react";
import GeneralBox from "../general/GeneralBox";
import MerchantRow from "./MerchantRow";
import { NumericFormat } from "react-number-format";
import { Scrollbars } from "react-custom-scrollbars-2";
import { observer } from "mobx-react-lite";
import { state } from "../../state/stateManager";
import MerchantsHeader from "./MerchantsHeader";
import MerchantRowLazy from "./MerchantRowLazy";
import MerchantRowTotals from "./MerchantsRowTotals";
const MerchantsLazy = observer(({ parentHeight }) => {
  const itemsPerPage = 30;
  const [displayedItems, setDisplayedItems] = useState(state.dashboardData?.today?.merchant_sales.slice(0, itemsPerPage));
  const [itemCount, setItemCount] = useState(itemsPerPage);
  const [context, setContext] = useState(state.dashboardData?.today?.merchant_sales ?? []);
  const [currentChannel, setCurrentChannel] = useState("All");
  const [currentCountry, setCurrentCountry] = useState("All");
  const [totalsRow, setTotalsRow] = useState({ sum: 0, orders: 0, avg: 0 });
 
  const [isSearchResults, setIsSearchResults] = useState(false);
  const container = useRef();
  const boxRef = useRef();
 
  useEffect(() => {
    //OrderCounter
    /*if (!isSearchResults){
      //only refresh when not in search mode
     //onChannelFilter(currentChannel);
    }*/

    //filter merchant_sales to only items in displayedItems

   let itemsToShow =  state.dashboardData?.today?.merchant_sales.filter((item) => displayedItems.some(item2 => item2.id === item.id));
    //console.log(JSON.stringify(itemsToShow));
 
    setDisplayedItems(itemsToShow);
  },[state.dashboardData?.today?.merchant_sales]);

  useEffect(() => {
      //calculate totals for displayed items for sum, orders and average order values
      // well need to return items from state.dashboardData?.today?.merchant_sales where the id of the item exists in the context items
      
      //get only id's from the context
      let contextIds = context.map((item) => item.id);
      
      let items = state.dashboardData?.today?.merchant_sales.map((item) => { return  contextIds.includes(item.id) ? item : null; }).filter((item) => item !== null);
   
      //let items = context;
      //iterate through items and calculate totals
      let sum = 0;
      let orders = 0;
      let avg = 0;
      items.forEach((item) => {
        sum += item.amount;
        orders += item.count;        
      });
      avg = sum / orders;
      setTotalsRow({ sum: sum, orders: orders, avg: avg });
    
      //debugger;
      console.log("MerchantRowTotals totals: ", totalsRow);
  }, [displayedItems]);

  const onCountryFilter = (country) => {
    setCurrentCountry(country);
    let filtered = [];
    switch (country) {
      case "All":
       
       let items = state.dashboardData?.today?.merchant_sales;
        filtered = items.filter((item) => item.channel === currentChannel || currentChannel === "All");
     
        setDisplayedItems(filtered.slice(0, itemsPerPage));
        setIsSearchResults(false);
        setContext(filtered);
        break;
      default:
         filtered = state.dashboardData.today.merchant_sales.filter((item) => {
          return item.country_name === country && (item.channel === currentChannel || currentChannel === "All");
        });
        setContext(filtered);
        setDisplayedItems(filtered.slice(0, itemsPerPage));
        setIsSearchResults(true);
        break;
    }

  }

  const onChannelFilter = (channel) => {
    setCurrentChannel(channel);
    let filtered = [];
    switch (channel) {
      case "All" :
        let items = state.dashboardData?.today?.merchant_sales;
        filtered = items.filter((item) => item.country_name === currentCountry || currentCountry === "All");
        setDisplayedItems(filtered.slice(0, itemsPerPage));
        setIsSearchResults(false);
        setContext(filtered);
        break;
      default:
        filtered = state.dashboardData.today.merchant_sales.filter((item) => {
          return item.channel === channel && (item.country_name === currentCountry || currentCountry === "All");
        });
        setContext(filtered);
        setDisplayedItems(filtered.slice(0, itemsPerPage));
        setIsSearchResults(true);
        break;
    }
  }
  const onSearchMerchant = (phrase) => {
    //filter original list and set state
    if (phrase === "") {
      //reset
      setIsSearchResults(false);
      setDisplayedItems(state.dashboardData?.today?.merchant_sales.slice(0, itemsPerPage));
    } else {
      setIsSearchResults(true);
      const searchResult = state.dashboardData.today.merchant_sales.filter(
        (item) => {
          return (
            item.name !== undefined &&
            item.name.toLowerCase().indexOf(phrase.toLowerCase()) > -1
          );
        }
      );
      setDisplayedItems(searchResult);
    }
  };
  const onScroll = () => {
    // if (container.current) {
    //   setScrollTop(container.current.container.children[0].scrollTop);
    // }
    const target = container.current.container.children[0];
    const bottom = target.scrollHeight - target.scrollTop === target.clientHeight;
    const nearBottonm = target.scrollHeight - target.scrollTop - target.clientHeight < 100; // 100px threshold before reaching the bottom
    if (nearBottonm) {
      const nextItems = context.slice(0, itemCount + itemsPerPage);
      setDisplayedItems(nextItems);
      setItemCount(itemCount + itemsPerPage);
    }

    if (target.scrollTop === 0){
      const nextItems = context.slice(0, itemsPerPage);
      setDisplayedItems(nextItems);
      setItemCount(itemsPerPage);
    }
  };
  const getRow = (item, key) => {
   try
   {
    const hasChildren = item.children ? true : false;
    let childrenRows = "";
    if (hasChildren && item.children.length > 1) {
      childrenRows = (
        <div className="merchant-child-container">
          {item.children.map((item, idx) => (
            <MerchantRowLazy
              isSearchResults={isSearchResults}
              key={`chrk_${key}_${idx}`}
              item={item}
              elKey={`chr_${key}_${idx}`}
            />
          ))}
        </div>
      );
    }
    const mainRow = (
      <div key={"mrkd" + key}>
        <MerchantRowLazy
          isSearchResults={isSearchResults}
          key={"mrk" + key}
          elKey={"mr" + key}
          item={item}
        />
        {childrenRows}
      </div>
    );
    //let childrenRows = ""

    return mainRow;
   }
   catch(e)
   {
    debugger;
    return null;
   }
  };
 

 
  return (
    <GeneralBox
      dPadding="2"
      hasBorder={false}
      hasBackground={true}
      css={"h-100 dash-merchants-info mb-2 d-flex flex-column"}
    >
      {state.dashboardData && (
        <MerchantsHeader
         onChannelFilter={onChannelFilter}
          onCountryFilter={onCountryFilter}
          onSearch={onSearchMerchant}
          count={context.length}
        />
      )}
      <div ref={boxRef} className="dash-merchants-list d-flex flex-grow-1">
        <Scrollbars
          key={"merchants_scroller"}
          ref={container}
          onScroll={onScroll}
          className="test-scroll d-flex flex-grow-1"
          renderTrackHorizontal={(props) => (
            <div {...props} className="track-horizontal" />
          )}
          renderThumbVertical={(props) => (
            <div {...props} className="thumb-vertical" />
          )}
        >
          {displayedItems && displayedItems.map((item, key) => getRow(item, key))}
        </Scrollbars>
   
      </div>
      <MerchantRowTotals totals={totalsRow} />
    </GeneralBox>
  );
});

export default MerchantsLazy;
