import { observer } from "mobx-react-lite";
import { state as state } from "../state/stateManager";
import { useEffect, useState } from "react";

const AlertItem = ({ name, message, onIgnore }) => {
  return (
    <div className="alert-inner-container d-flex flex-column">
      <div className="alret-title d-flex justify-content-between">
        <div>
          <i className="bi bi-exclamation-triangle-fill"></i>
          ALERT - {name}
        </div>
        <div className="alert-close">
          <i className="bi bi-x-circle-fill" onClick={onIgnore}></i>
        </div>
      </div>
      <div className="alert-content">{message}</div>
    </div>
  );
};
const Alerts = observer(({ onAlert }) => {
  const [isAlertActive, setIsAlertActive] = useState(false);
  const [activeAlerts, setActiveAlerts] = useState([]); //{ name : "", message : "", key: ""}
  useEffect(() => {
    let alertsArr = [...activeAlerts];

    Object.keys(state.alerts).forEach((key) => {
      let alerts = state.alerts;
      //let localStateAlerts = activeAlerts.find((alert) => alert.key === key);

      //setIsAlertActive(true);

      let localStateAlerts = activeAlerts.find((alert) => alert.key === key);
      //if alert exists only update active status
      if (localStateAlerts) {
        localStateAlerts.active = state.alerts[key].active;
      } else {
        //add alert to activeAlerts state
        alertsArr.push({
          name: state.alerts[key].name,
          message: state.alerts[key].message,
          key: key,
          surpressed: false,
          active: state.alerts[key].active,
          popup: state.alerts[key].popup
        });
      }

      // alertsArr.push({
      //   name: state.alerts[key].name,
      //   message: state.alerts[key].message,
      //   key: key,
      //   surpressed: localStateAlerts ? localStateAlerts.surpressed : false,
      //   active: true,
      // });

      // onAlert && onAlert(true);
    });
    //update alert array
    setActiveAlerts(alertsArr);

    //check if there are active alerts

    let areThereActiveAlerts = alertsArr.find(
      (alert) => alert.active && !alert.surpressed && alert.popup
    );
    if (areThereActiveAlerts) {
      onAlert && onAlert(true);
      setIsAlertActive(true);
      //setActiveAlerts(alertsArr);
    } else {
      setIsAlertActive(false);
      onAlert && onAlert(false);
    }

    // if (!areThereActiveAlerts) {
    //   setIsAlertActive(false);
    //   onAlert && onAlert(false);
    // }
    // if (state.alerts[StateManager.Alerts.PACE].active) {
    //     alert("pace alert");
    // }
  }, [state.alerts]);
  const userIgnoredClick = (key) => {
    //surpress the alert for 5 minutes

    let active_alerts = [...activeAlerts];
    let alert = active_alerts.find((alert) => alert.key === key);
    alert.surpressed = true;
    setActiveAlerts(active_alerts);
    //need to update alerts state
    //state.updateAlerts(active_alerts);
    reEnableAlert(key);

    //check if all alerts are surpressed
    let areThereActiveAlerts = active_alerts.find((alert) => !alert.surpressed && alert.popup);
 
    if (!areThereActiveAlerts) {
      setIsAlertActive(false);
      onAlert && onAlert(false);
    } else {
      setIsAlertActive(true);
      onAlert && onAlert(true);
    }
  };

  //5 minutes surpression
  const reEnableAlert = (key) => {
    setTimeout(() => {
      let active_alerts = [...activeAlerts];
      let alert = active_alerts.find((alert) => alert.key === key);
      alert.surpressed = false;
      setActiveAlerts(active_alerts);
      //state.updateAlerts(active_alerts);
    }, 1000 * 20);
  };
  return isAlertActive ? (
    <div className="alerts-container">
      {activeAlerts
        .filter((item) => !item.surpressed && item.active && item.popup)
        .map((activeAlert, i) => (
          <AlertItem
            key={"ai" + i}
            name={activeAlert.name}
            message={activeAlert.message}
            onIgnore={() => userIgnoredClick(activeAlert.key)}
          />
        ))}
    </div>
  ) : (
    ""
  );
});

export default Alerts;
