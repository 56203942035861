class APIFetcher {
  constructor() {
    this.API_BASE = process.env.REACT_APP_IP;
  }

  async saveConfiguration(config) {
    return await new Promise((resolve) => {
      //let url =  "http://192.168.16.188:3001/api/save_config";
      let url = this.API_BASE + "api/save_config";

      fetch(`${url}`, {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(config),
      })
        .then((res) => res.json())
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          resolve({ error: true });
        });
    });
  }
  async getAdminStatus() {
    //verify_admin
    return await new Promise((resolve) => {
      //let url =  "http://192.168.16.188:3001/api/get_config";
      let url = this.API_BASE + "api/verify_admin";

      //get admin cookie

      fetch(`${url}`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          resolve({ error: true });
        });
    });
  }

  async getUsers() {
    return await new Promise((resolve) => {
      //let url =  "http://192.168.16.188:3001/api/get_config";
      //let url = this.API_BASE + "api/auth_admin?pass=" + pass;
      //192.168.16.188
      //let url = "https://192.168.50.245:3001/api/userstate";
      let url = this.API_BASE + "users/list";
      //get admin cookie
     
      fetch(`${url}`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          resolve({ error: true });
        });
    });
  }
  //createApiToken
  async createApiToken(id) {
    return await new Promise((resolve) => {
      //let url =  "http://192.168.16.188:3001/api/get_config";
      //let url = this.API_BASE + "api/auth_admin?pass=" + pass;
      //192.168.16.188
      //let url = "https://192.168.50.245:3001/api/userstate";
      let url = this.API_BASE + "users/createapikey";
      //get admin cookie
      let body = { id: id };
      fetch(`${url}`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((res) => res.json())
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          resolve({ error: true });
        });
    });
  }
  async createUser(user, display_name, pass, isAdmin = false) {
    return await new Promise((resolve) => {
      //let url =  "http://192.168.16.188:3001/api/get_config";
      //let url = this.API_BASE + "api/auth_admin?pass=" + pass;
      //192.168.16.188
      //let url = "https://192.168.50.245:3001/api/userstate";
      let url = this.API_BASE + "users/create";
      //get admin cookie
      let body = {
        user: user,
        pass: pass,
        isAdmin: isAdmin,
        display_name: display_name,
      };
      fetch(`${url}`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((res) => res.json())
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          resolve({ error: true });
        });
    });
  }
  async deleteApiToken(id) {
    return await new Promise((resolve) => {
      //let url =  "http://192.168.16.188:3001/api/get_config";
      //let url = this.API_BASE + "api/auth_admin?pass=" + pass;
      //192.168.16.188
      //let url = "https://192.168.50.245:3001/api/userstate";
      let url = this.API_BASE + "users/deleteapitoken";
      //get admin cookie
      let body = { id: id };
      fetch(`${url}`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((res) => res.json())
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          resolve({ error: true });
        });
    });
  }
  async resetPassword(id,newpass) {
    return await new Promise((resolve) => {
    let url = this.API_BASE + "users/resetpassword";
    let body = { id: id, newpass : newpass };
    fetch(`${url}`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        resolve({ error: true });
      });
    });
  }
  async deleteUser(id) {
    return await new Promise((resolve) => {
 
      let url = this.API_BASE + "users/delete";
      //get admin cookie
      let body = { id: id };
      fetch(`${url}`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((res) => res.json())
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          resolve({ error: true });
        });
    });
  }
  //createUser
  async userstate(user, pass, root = false) {
    return await new Promise((resolve) => {
      //let url =  "http://192.168.16.188:3001/api/get_config";
      //let url = this.API_BASE + "api/auth_admin?pass=" + pass;
      //192.168.16.188
      let url = this.API_BASE + "api/userstate";
      //let url = "https://192.168.16.188:3001/api/userstate";
      //get admin cookie

      fetch(`${url}`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          resolve({ error: true });
        });
    });
  }

  async login(user, pass, root = false) {
    return await new Promise((resolve) => {
      //let url =  "http://192.168.16.188:3001/api/get_config";
      //let url = this.API_BASE + "api/auth_admin?pass=" + pass;
      //192.168.16.188
      let url = this.API_BASE + "api/login";
      //let url = "https://192.168.16.188:3001/api/login";
      //get admin cookie
      const body = JSON.stringify({ user: user, pass: pass, root: root });
      fetch(`${url}`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: body,
      })
        .then((res) => res.json())
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          debugger;
          resolve({ error: true });
        });
    });
  }
  async saveMerchantCountries(data) {
    return await new Promise((resolve) => {
      //192.168.16.188
      let url = this.API_BASE + "api/save_merchant_countries";
      //let url = "https://192.168.16.188:3001/api/save_merchant_countries";
      //let url = this.API_BASE + "api/save_merchant_countries";

      fetch(`${url}`, {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          resolve({ error: true });
        });
    });
  }
  async getMerchantCountries() {
    return await new Promise((resolve) => {
      let url = this.API_BASE + "api/get_merchant_countries";
      //let url = this.API_BASE + "api/get_merchant_countries";

      //get admin cookie

      fetch(`${url}`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          resolve({ error: true });
        });
    });
  }
  async getConfiguration() {
    return await new Promise((resolve) => {
      //let url =  "http://192.168.16.188:3001/api/get_config";
      let url = this.API_BASE + "api/get_config";

      //get admin cookie

      fetch(`${url}`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          resolve({ error: true });
        });
    });
  }

  async getLoginConfiguration() {
    return await new Promise((resolve) => {
      //let url =  "http://192.168.16.188:3001/api/get_config";
      let url = this.API_BASE + "api/get_login_config";

      //get admin cookie

      fetch(`${url}`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          resolve({ error: true });
        });
    });
  }

  async getDashboardData(queryParams) {
    //MerchantYearCompareRow
    return await new Promise((resolve) => {
      let url = this.API_BASE + "api/getData" + queryParams;
      fetch(`${url}`, {
        method: "GET",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          resolve({ error: true });
        });
    });
  }

  async getDashboardDataWithProgress(progressCallback) {
    let url = this.API_BASE + "api/getData";
    return this.fetchWithProgress(url, progressCallback).then((response) =>
      response.json()
    );
  }
  async fetchWithProgress(url, progressCallback) {
    try {
      // Fetch the resource
      const response = await fetch(url);
      const contentLength = response.headers.get("Content-Length");
      progressCallback(0, contentLength);
      const total = contentLength ? parseInt(contentLength, 10) : 0;
      let loaded = 0;

      // Reader for the response stream
      const reader = response.body.getReader();
      const stream = new ReadableStream({
        async start(controller) {
          try {
            while (true) {
              const { done, value } = await reader.read();
              if (done) break;
              loaded += value.byteLength;
              //console.log(`Received ${loaded} of ${total} bytes`);
              controller.enqueue(value);
              progressCallback(loaded, total);
            }
            controller.close();
          } catch (err) {
            controller.error(err);
          }
        },
      });

      // Create a new response from the stream
      return new Response(stream);
    } catch (err) {
      throw err;
    }
  }
}

const fetcher = new APIFetcher();
export default fetcher;
