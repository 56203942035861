import React, { useEffect, useState } from "react";
import DBPie from "../common/DBPie";
import { observer } from "mobx-react-lite";
import { state ,StateManager} from "../../state/stateManager";
import DBPieTable from "../common/DBPieTable";
import ComponentError from "../ComponentError";
const PiesContainer = observer(() => {
  const [checkoutCountries, setCheckoutCountries] = useState(null);
  const [checkoutDevices, setCheckoutDevices] = useState(null);
  const [checkoutMerchants, setCheckoutMerchants] = useState(null);
  const [checkoutOS, setCheckoutOS] = useState(null);
  const [rotatePies, setRotatePies] = useState(false);

  const [isLoadSuccess, setIsLoadSuccess] = useState(false);
  useEffect(() => {
      const isSuccess =  state.isStreamSuccess(StateManager.Keys.SessionsStats)
      setIsLoadSuccess(isSuccess);
  }, [state.failedToLoad]);

  useEffect(() => {
    let isPiesRotatingStatus =  state.getMenuItemLogicalState(StateManager.MenuKeys.PIES_ANIMATION)
    let isPiesRotating = isPiesRotatingStatus == StateManager.MenuLogicalStates.ENABLED;
    setRotatePies(isPiesRotating);
   
  },[state.menuItems])

  useEffect(() => {
    if (state.statsData && state.statsData.co_countries) {
      //prepare pie charts data
      //build checkout countries
      let data = {
        title: "Checkout countries",
        labelText: "Percentage of Customers",
        checkout_customers: state.dashboardStats["checkout"].count,
      };

      data.labels = state.statsData.co_countries.map((item) => item.country);
      data.percentages = state.statsData.co_countries.map((item) =>
        parseInt(item.percentage)
      );
      setCheckoutCountries(data);

      //checkout devices
      data = {
        title: "Checkout devices",
        labelText: "Percentage of Customers",
        checkout_customers: state.dashboardStats["checkout"].count,
      };
      data.labels = state.statsData.co_device.map((item) => item.device);
      data.percentages = state.statsData.co_device.map((item) =>
        parseInt(item.percentage)
      );
      setCheckoutDevices(data);

      //checkout merchants
      data = {
        title: "Checkout merchants",
        labelText: "Percentage of Customers",
        checkout_customers: state.dashboardStats["checkout"].count,
      };

      data.labels = state.statsData.co_merchants.map((item) => item.merchant);
      data.percentages = state.statsData.co_merchants.map((item) =>
        parseInt(item.percentage)
      );
      setCheckoutMerchants(data);

      //checkout os
      data = {
        title: "Checkout operating system",
        labelText: "Percentage of Customers",
        checkout_customers: state.dashboardStats["checkout"].count,
      };
      data.labels = state.statsData.co_os.map((item) => item.os);
      data.percentages = state.statsData.co_os.map((item) =>
        parseInt(item.percentage)
      );
      setCheckoutOS(data);
    }
  }, [state.statsData]);

  useEffect(() => {}, [state.configManager]);
  return (
    isLoadSuccess?<div className="d-flex flex-wrap pies-container  flex-grow-1 justify-content-between">
      <div className="layout-pie d-flex db-pies-inner-container">
        <div className="pie-title d-flex">
          <i className="bi bi-globe-americas"></i>

          {checkoutCountries?.title}
        </div>
        <DBPie data={checkoutCountries} rotate={rotatePies} />

        <DBPieTable data={checkoutCountries} />
      </div>
      <div className="layout-pie  d-flex db-pies-inner-container">
        <div className="pie-title d-flex">
          <i className="bi bi-phone"></i>
          {checkoutDevices?.title}
        </div>

        <DBPie data={checkoutDevices} rotate={rotatePies} />

        <DBPieTable data={checkoutDevices} />
      </div>
      <div className="layout-pie d-flex db-pies-inner-container">
        <div className="pie-title d-flex">
          <i className="bi bi-shop-window"></i>
          {checkoutMerchants?.title}
        </div>
        <DBPie data={checkoutMerchants} rotate={rotatePies} />
        <DBPieTable data={checkoutMerchants} />
      </div>
      <div className="layout-pie d-flex db-pies-inner-container">
        <div className="pie-title d-flex">
          <i className="bi bi-window-stack"></i>
          {checkoutOS?.title}
        </div>
        <DBPie data={checkoutOS} rotate={rotatePies} />
        <DBPieTable data={checkoutOS} />
      </div>
    </div>:<ComponentError componentKey={"PiesContainer"} height="200px" />
  );
});

export default PiesContainer;
