import release_notes from "../assets/release_notes.json";
import { useEffect, useState, useRef } from "react";
const ReleaseNotesPage = ({ onClose, onLoad }) => {
  const [versionsInfo, setVersionsInfo] = useState([]);
  useEffect(() => {
    if (release_notes.currentVersion) {
      let versionArray = [];
      //get version
      const versions = Object.keys(release_notes.versions);
      for (var i = versions.length - 1; i >= 0; i--) {
        const versionDetails = release_notes.versions[versions[i]];
        let details = {
          version: versions[i],
          date: versionDetails.date,
          changes: versionDetails.changes,
          fixes: versionDetails.fixes ?? [],
          general: versionDetails.general ?? [],
          comingSoon: versionDetails.comingSoon ?? [],
        };

        versionArray.push(details);
      }

      setVersionsInfo(versionArray);
      onLoad();
    }
  }, []);
  return (
    <div className="d-flex dashboard-page flex-column">
      <div className="d-flex flex-wrap justify-content-between align-items-center">
        <h1 style={{ fontSize: "50px", color: "#9A8BAB" }}>Release Notes</h1>
        <button
          hidden={true}
          style={{ minWidth: "140px", height: "40px" }}
          className="btn btn-primary"
          onClick={() => onClose()}
        >
          CLOSE
        </button>
      </div>
      <div className="d-flex flex-column release-notes-page-container">
        {versionsInfo.map((version, index) => {
          return (
            <div key={index} className="release-notes-page-version">
              <h2 className="version-title">Version {version.version} {release_notes.currentVersion===version.version?"- Current version":""}</h2>
              <p>Release date : {version.date}</p>

              {version.changes.length > 0 ? (
                <>
                  <div
                    className="d-flex align-items-center release-note-page-item-header"
                    style={{ height: "50px" }}
                  >
                    <span className="material-symbols-outlined">
                      featured_play_list
                    </span>
                    <h3>Changes</h3>
                  </div>
                  <ul>
                    {version.changes.map((change, index) => {
                      return <li dangerouslySetInnerHTML={{__html : change}} key={index}></li>;
                    })}
                  </ul>
                </>
              ) : null}

              {version.fixes.length > 0 ? (
                <>
                  <div
                    className="d-flex align-items-center release-note-page-item-header"
                    style={{ height: "50px" }}
                  >
                    <span className="material-symbols-outlined">adb</span>
                    <h3>Fixes</h3>
                  </div>

                  <ul>
                    {version.fixes.map((fix, index) => {
                      return <li dangerouslySetInnerHTML={{__html : fix}} key={index}></li>;
                    })}
                  </ul>
                </>
              ) : null}
              {version.general.length > 0 ? (
                <>
                  <div
                    className="d-flex align-items-center release-note-page-item-header"
                    style={{ height: "50px" }}
                  >
                    <span className="material-symbols-outlined">info</span>
                    <h3>General</h3>
                  </div>
                  <ul>
                    {version.general.map((gen, index) => {
                      return <li key={index}>{gen}</li>;
                    })}
                  </ul>
                </>
              ) : null}

              {version.comingSoon.length > 0 ? (
                <>
                  <div
                    className="d-flex align-items-center release-note-page-item-header"
                    style={{ height: "50px" }}
                  >
                    <span className="material-symbols-outlined">more_time</span>
                    <h3>Coming Soon</h3>
                  </div>
                  <ul>
                    {version.comingSoon.map((gen, index) => {
                      return <li key={index}>{gen}</li>;
                    })}
                  </ul>
                </>
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default ReleaseNotesPage;
