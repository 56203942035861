import React, { useEffect, useState } from "react";
import GeneralBox from "../general/GeneralBox";
import { NumericFormat } from "react-number-format";
import GaugeComponent from "react-gauge-component";
import { observer } from "mobx-react-lite";
import { state } from "../../state/stateManager";
import moment from "moment";
//state.dashboardStats.checkout.count
const OrdersPaceInfo = observer(({ gaugeInfo,isAlertActive }) => {
  const [value, setValue] = useState(50);
  const [minuteMean, setMinuteMean] = useState(0);
  const [amountLastMinute, setAmountLastMinute] = useState(
    state?.ordersPace?.last_minute?.amountLastMinute
  );
  const arc = {
    colorArray: ["#00FF15", "#FF2121"],
    padding: 0.02,
    width: 0.2,
    subArcs: [{ limit: 40 }, { limit: 60 }, { limit: 70 }, {}, {}, {}, {}],
  };

  const labels = {
    tickLabels: {
      hideMinMax: true,
    },
  };

  useEffect(() => {
    if (gaugeInfo) {
      //calculate value percentage
      let percentage =
        (state?.ordersPace?.last_minute?.ordersLastMinute / gaugeInfo.max) *
        100;
      if (!isNaN(percentage)) {
        setValue(Math.trunc(percentage));
      }
    }
  }, [state?.ordersPace]);

  useEffect(() => {
    if (state?.ordersPace?.last_minute?.amountLastMinute) {
      setAmountLastMinute(
        state?.ordersPace?.last_minute?.amountLastMinute.toFixed(0)
      );
    }
  }, [state?.ordersPace?.last_minute?.amountLastMinute]);

  useEffect(() => {
     //get curret time using moment HH:mm
    let currentTime = moment().utc().format("HH:mm");
    let currentTimeInfo = state.opm_avg[currentTime];
    if (currentTimeInfo) {
      setMinuteMean(currentTimeInfo.mean.toFixed(2));
    }      
  },[state.opm_avg]);
  return (
    <GeneralBox
      dPadding="2"
      hasBorder={false}
      hasBackground={false}
      css={"order-pace-box"}
    >
      <div className="d-flex">
        <div className="dash-sessioninfo-icon-container ">
          <GaugeComponent
            value={value}
            className="rotated-gauge align-self-start"
            labels={labels}
            type="semicircle"
            style={{ height: "140px", width: "100px" }}
            arc={arc}
          />
        </div>
        <div className="dash-sessioninfo-content" style={{ paddingTop: "7px" }}>
          <span className="dash-sessioninfo-number d-flex" style={{alignItems:"flex-end", marginBottom:"10px"}}>
            <NumericFormat
              value={state?.ordersPace?.last_minute?.ordersLastMinute}
              displayType={"text"}
              className={"d-flex " + (isAlertActive ? "alert-active" : "")}
              style={{lineHeight:"100%"}}
              thousandSeparator={true}
              prefix={""}
            />
            <span  className={"orders-per-minute " + (isAlertActive?"alert-active":"")}>
              <div className="d-flex flex-column" style={{gap:"0"}}>
                <div data-hidden={!isAlertActive} style={{color:"yellow", marginBottom:"-4px"}}>Low order pace detected!</div>
                <div>orders per minute (avg. {minuteMean})</div>
                
              </div>
              
            </span>
           
          </span>
      
          <div className="order-pace-infos">
            Incoming order every{" "}
            <span className="highlight-amount">
              {state?.ordersPace?.last_minute?.orderEveryXSeconds}
            </span>{" "}
            seconds
          </div>
          <div className="order-pace-infos">
            Total value of{" "}
            <span className="highlight-amount">
              <NumericFormat
                value={amountLastMinute}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            </span>{" "}
            in the last minute
          </div>
        </div>
      </div>
    </GeneralBox>
  );
});

export default OrdersPaceInfo;
