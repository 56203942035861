import React from "react";
import GeneralBox from "../general/GeneralBox";
import { NumericFormat } from "react-number-format";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { state, StateManager } from "../../state/stateManager";
const MonthlySales = observer(
  ({
    text,
    amount,
    hour,
    count,
    css,
    dailyAverage,
    postFix = "",
    isShowFunc = () => {
      return { show: true };
    },
  }) => {
    const [showFunResult, setshowFunResult] = React.useState(isShowFunc());
 
    const [diffPercentMoney, setDiffPercentMoney] = React.useState(null);
    const [diffPercentCount, setDiffPercentCount] = React.useState(null);
 

    useEffect(() => {
      //amount = 102000000;
        //first we need to get this month sales , and dedect today sales from the number
        //amount of today is in state.dashboardStats.orders_info.amount
        //amount state holds monthly sales to date
        let amountFolCalc = postFix=="M" ? amount*1000000 : amount;
        let thisMonthAmountUntilYesterday = amountFolCalc - state.dashboardStats.orders_info.amount;
        let diff = 0;
        let percent = 0;
        if (state.atomicPreviousMonth.amount!==undefined && state.atomicPreviousMonth.amount!==0){
        //now we need to calculate percentage diff between thisMonthAmountUntilYesterday and state.atomicPreviousMonth.amount
        let test = state.atomicPreviousMonth.amount;
        diff = thisMonthAmountUntilYesterday - state.atomicPreviousMonth.amount;
      
        percent = (diff / state.atomicPreviousMonth.amount) * 100;
        setDiffPercentMoney(percent);
        }

        if (state.atomicPreviousMonth.count!==undefined && state.atomicPreviousMonth.count!==0){
        let thisMonthCountUntilYesterday = count - state.dashboardStats.orders_info.count;  
        diff = thisMonthCountUntilYesterday - state.atomicPreviousMonth.count;
        percent = (diff / state.atomicPreviousMonth.count) * 100;
        setDiffPercentCount(percent);
        }


    },[state.atomicPreviousMonth,state.dashboardStats.orders_info.amount]);
    return (
      <GeneralBox
        dPadding="2"
        hasBorder={true}
        hasBackground={true}
        css={"order-sales-info mb-2 yesterday-container " + css}
      >
        <div className="d-flex" style={{ height: "100%" }}>
          <div className="dash-yesterday-content">
            <div className="d-flex flex-column" style={{ height: "100%" }}>
              <div className="d-flex dash-side-header">
                <i className="bi bi-coin"></i>
                <div>
                  {text} {hour !== undefined ? `(${hour})` : ""}
                </div>
              </div>
              {!showFunResult.show ? (
                <div className="d-flex flex-wrap order-data-custom-text">
                  {showFunResult.text}
                </div>
              ) : (
                <>
                  <div className={"dash-yesterday-amount d-flex flex-wrap " + (amount>100000000?"amount-small":"")}>
                    <NumericFormat
                      value={amount}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                      suffix={postFix}
                    />

                    <div
                      data-hidden={!diffPercentMoney}
                      data-positive={diffPercentMoney >= 0}
                      className={"d-flex align-items-center yesterday-avg-diff " + (diffPercentMoney>=100?"diff-small":"")}
                    >
                      <i
                        style={{ marginLeft: "5px" }}
                        className={
                          "bi " +
                          (diffPercentMoney >= 0
                            ? "bi-caret-up-fill"
                            : "bi-caret-down-fill")
                        }
                      >
                        {" "}
                      </i>
                      {diffPercentMoney && diffPercentMoney.toFixed(2) + "%"}
                    </div>
                  </div>
                  <div className="dash-yesterday-count">
                    <NumericFormat
                      value={count}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={""}
                    />{" "}
                    <span className="ms-1"> orders</span>
                    <div
                      data-hidden={!diffPercentCount}
                      data-positive={diffPercentCount >= 0}
                      className="d-flex align-items-center yesterday-avg-diff"
                    >
                      <i
                        style={{ marginLeft: "5px" }}
                        className={
                          "bi " +
                          (diffPercentCount >= 0
                            ? "bi-caret-up-fill"
                            : "bi-caret-down-fill")
                        }
                      >
                        {" "}
                      </i>
                      {diffPercentCount && diffPercentCount.toFixed(2) + "%"}
                    </div>                    
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </GeneralBox>
    );
  }
);

export default MonthlySales;
