import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { state, StateManager } from "../state/stateManager";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle"; //rotate glob
import PublicOffIcon from "@mui/icons-material/PublicOff"; //disable globe
import PhonelinkSetupRoundedIcon from "@mui/icons-material/PhonelinkSetupRounded"; //config
import TrackChangesRoundedIcon from "@mui/icons-material/TrackChangesRounded"; //pie animation
import InsightsRoundedIcon from "@mui/icons-material/InsightsRounded"; //insights
import FullscreenRoundedIcon from "@mui/icons-material/FullscreenRounded"; //fullscreen
import ShowChartRoundedIcon from "@mui/icons-material/ShowChartRounded"; //stock
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { styled } from "@mui/material/styles";
const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: "absolute",
  zIndex: 80000000,
  "& .MuiFab-primary": {
    width: 40,
    height: 40,
    backgroundColor: "#000000dd",
    boxShadow: "none",
    "& .MuiSpeedDialIcon-root": {
      height: 32,
    },
    "& .MuiSpeedDialIcon-icon": {
      fontSize: 32,
    },
  },
  "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
  "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
    top: theme.spacing(3),
    left: theme.spacing(3),
  },
}));
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
const SideMenuItem = observer(({ item, onClick }) => {
  const [title, settitle] = useState("");
  const [Icon, seticon] = useState(null);
  const [ItemButton, setItemButton] = useState(null);
  const getIcon = (icon, size = "32") => {
    switch (icon) {
      case "ChangeCircleIcon":
        return <ChangeCircleIcon fontSize={size} />;
      case "PublicOffIcon":
        return <PublicOffIcon fontSize={size} />;
      case "PhonelinkSetupRoundedIcon":
        return <PhonelinkSetupRoundedIcon fontSize={size} />;
      case "TrackChangesRoundedIcon":
        return <TrackChangesRoundedIcon fontSize={size} />;
      case "InsightsRoundedIcon":
        return <InsightsRoundedIcon fontSize={size} />;
      case "FullscreenRoundedIcon":
        return <FullscreenRoundedIcon fontSize={size} />;
      case "ShowChartRoundedIcon":
        return <ShowChartRoundedIcon fontSize={size} />;
      case "DashboardRoundedIcon":
        return <DashboardRoundedIcon fontSize={size} />;
      case "SmartToyIcon":
        return <SmartToyIcon fontSize={size} />;
      default:
        return <ChangeCircleIcon fontSize={size} />;
    }
  };
  useEffect(() => {
    if (item.state == StateManager.MenuStates.DIAL) {
      setItemButton(getDialitemButton());
    } else {
      setItemButton(getRegularItemButton());
    }
  }, [item]);
  const onItemClick = () => {
    onClick(item); //move to parent
  };
  const getRegularItemButton = () => {
    let title = item.title;
    let icon = <span className="menu-icon-tv material-symbols-outlined">{item.icon}</span>;// getIcon(item.icon);
    if (item.state == StateManager.MenuStates.CLICKED) {
      if (item.altTitle) {
        title = item.altTitle;
      }
      if (item.altIcon && item.altIcon !== "") {
        icon = <span className="menu-icon-tv material-symbols-outlined">{item.altIcon}</span>;// getIcon(item.altIcon);
      } else {
        icon = <span className="menu-icon-tv material-symbols-outlined">{item.icon}</span>;// getIcon(item.icon);
      }
    }

    return (
      <BootstrapTooltip
        title={title}
        PopperProps={{ style: { zIndex: 80000000 } }}
        placement="right"
      >
        {icon !== null ? icon : <div></div>}
      </BootstrapTooltip>
    );
  };
  const getDialitemButton = () => {
    const icon = <span className="menu-icon-tv material-symbols-outlined">{item.icon}</span>;// getIcon(item.icon);
    return (
      <StyledSpeedDial
        ariaLabel="SpeedDial playground example"
        PopperProps={{ style: { zIndex: 80000000 } }}
        //hidden={hidden}
        icon={icon}
        direction={"Right"}
      >
        {item.subItems.map((item) => (
          <SpeedDialAction
            sx={{
              color: "#000",
              backgroundColor: "#ffffffbb",
              fontSize: "16px",
              "&:hover": {
                backgroundColor: "#ffffff",
              },
            }}
            key={item.key}
            icon={getIcon(item.icon, "12")}
            PopperProps={{ style: { zIndex: 80000000 } }}
            tooltipTitle={item.title}
          />
        ))}
      </StyledSpeedDial>
    );
  };
  return (
    <div
      onClick={onItemClick}
      className="d-flex dash-sidemenu-item"
      data-logicalstate={
        item.state !== StateManager.MenuStates.STATIC ? item.logicalState : ""
      }
    >
      {ItemButton}
      {/* <Tooltip
        title={title}
        PopperProps={{ style: { zIndex: 80000000 } }}
        placement="right"
      >
        {Icon !== null ? Icon : <div></div>}
      </Tooltip> */}
    </div>
  );
});
export default SideMenuItem;
