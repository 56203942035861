import * as React from 'react';
import { Dropdown } from '@mui/base/Dropdown';
import { Menu } from '@mui/base/Menu';
import PropTypes from 'prop-types';
import { MenuButton as BaseMenuButton } from '@mui/base/MenuButton';
import { MenuItem as BaseMenuItem, menuItemClasses } from '@mui/base/MenuItem';
import { Select as BaseSelect, selectClasses } from '@mui/base/Select';
import { Option as BaseOption, optionClasses } from '@mui/base/Option';
import UnfoldMoreRoundedIcon from '@mui/icons-material/UnfoldMoreRounded';
import { styled } from '@mui/system';
import { useEffect } from 'react';
import { Scrollbars } from "react-custom-scrollbars-2";

const Select = React.forwardRef(function Select(props, ref) {
  const slots = {
    root: CustomButton,
    listbox: Listbox,
    popup: Popup,
    ...props.slots,
  };

  return <BaseSelect {...props} ref={ref} slots={slots} />;
});

const MerchantsFilter = ({sales, onChannelFilter,onCountryFilter})=> {
    const [filterItems, setFilterItems] = React.useState([]);
    const [countryFilterItems, setCountryFilterItems] = React.useState([]);
    const [currentFilter, setCurrentFilter] = React.useState("All");
    const [currrentCountryFilter, setCurrentCountryFilter] = React.useState("All");

    useEffect(()=> {
        if (sales) {
         
            let uniqueChannels = sales.filter((v,i,a)=>a.findIndex(t=>(t.channel!==undefined && t.channel === v.channel))===i).map((item)=>item.channel);            
            uniqueChannels = ["All",...uniqueChannels];
            setFilterItems(uniqueChannels);
     
            let uniqueCountries = sales.filter((v,i,a)=>a.findIndex(t=>(t.country_name!==undefined && t.country_name === v.country_name))===i).map((item)=> ({code: item.country, country_name : item.country_name}));
            //sort unique countries by country_name
            uniqueCountries.sort((a,b)=> a.country_name.localeCompare(b.country_name));
    

            uniqueCountries = [{country_name : "All", code:"All"},...uniqueCountries];
          
            setCountryFilterItems(uniqueCountries);
        }
    },[sales])
    const getDisplayText = (item)=> {
        switch (item) {
            case "All":
                return "All Channels";
            case "globale":
                return "Enterprise";
            case "borderfree":
                return "Borderfree";
            case "flow":
                return "Markets Pro";
            default:
                return "Unknown";
        
        }
    }
    const getCountryDisplayText = (item)=> {
        switch (item.country_name) {
            case "All":
                return "All Countries";
            default:
                return item.country_name;
        
        }
    }
    const createHandleMenuClick = (menuItem) => {
        return () => {
            setCurrentFilter(menuItem);
            onChannelFilter(menuItem);     
        };
      };

      const createHandleCountryMenuClick = (e, menuItem) => {
    
        setCurrentCountryFilter(menuItem);
        onCountryFilter(menuItem);     
       
      }
      return (
        <div className='d-flex' style={{gap:"8px"}}>
        <Dropdown>
          <MenuButton className='filter-button'>
          <span className="material-symbols-outlined">
            filter_alt
            </span>
             {getDisplayText(currentFilter)}
          </MenuButton>
          <Menu slots={{ listbox: Listbox }} style={{zIndex:11111111111}}>
            {filterItems.map((item, idx) => ( 
                <MenuItem className='filter-menu-item'  data-selected={item===currentFilter} key={idx} onClick={createHandleMenuClick(item)}>
                    <span className="selected-icon material-symbols-outlined">radio_button_checked</span>
                    {getDisplayText(item)}
                </MenuItem>
            ))}
 
          </Menu>
        </Dropdown>

  
      <Select
        onChange={createHandleCountryMenuClick}
        labelid="country-select-label"
        id="country-select"
        defaultValue={currrentCountryFilter}
        label="Country"        
       style={{color:"#000"}}>
        <Scrollbars
                  key={"merchants_scroller"}
                  style={{minHeight:"200px",maxHeight:"200px" }}
                  className="test-scroll d-flex flex-grow-1"
                  renderTrackHorizontal={(props) => (
                    <div {...props} className="track-horizontal" />
                  )}
                  renderThumbVertical={(props) => (
                    <div {...props} className="thumb-vertical" />
                  )}
        >
        {countryFilterItems.map((item, idx) => (
          <Option key={idx} value={item.country_name}>
            <div className='d-flex flex-wrap f32 align-items-center' style={{gap:"8px"}}> 
                <div className={"small-flag flag " + item.code.toLowerCase()}></div>
                <span>{getCountryDisplayText(item)}</span>
            </div>
            
          </Option>
       
        ))}
           </Scrollbars>
      </Select>
 
        </div>
      );
}
const CustomButton = React.forwardRef(function CustomButton(props, ref) {
  const { ownerState, ...other } = props;
  return (
    <StyledButton type="button" {...other} ref={ref} style={{maxWidth:"180px", minWidth:"180px"}}>
      {other.children}
      <UnfoldMoreRoundedIcon />
    </StyledButton>
  );
});

CustomButton.propTypes = {
  children: PropTypes.node,
  ownerState: PropTypes.object.isRequired,
};
const blue = {
    50: '#F0F7FF',
    100: '#C2E0FF',
    200: '#99CCF3',
    300: '#66B2FF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E6',
    700: '#0059B3',
    800: '#004C99',
    900: '#003A75',
  };
  
  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };
  
  const Listbox = styled('ul')(
    ({ theme }) => `
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    box-sizing: border-box;
    padding: 6px;
    margin: 12px 0;
    min-width: 200px; 
    border-radius: 12px;
    max-height: 240px;
    overflow: auto;
    outline: 0px;
    opacity:1;
    background: ${grey[900]};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    color: ${grey[300]};
    box-shadow: 0px 4px 6px ${
      theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.50)' : 'rgba(0,0,0, 0.05)'
    };
    z-index: 1000000000;
    `,
  );
  
  const MenuItem = styled(BaseMenuItem)(
    ({ theme }) => `
    list-style: none;
    padding: 8px;
    border-radius: 8px;
    cursor: default;
    user-select: none;
    z-index: 1000000000;
    &:last-of-type {
      border-bottom: none;
    }
  
    &:focus {
      outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
      background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    }
  
    &.${menuItemClasses.disabled} {
      color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
    }
    `,
  );
  
  const MenuButton = styled(BaseMenuButton)(
    ({ theme }) => `
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5;
    padding: 6px 12px 6px 12px;
    border-radius: 8px;
    color: white;
    transition: all 150ms ease;
    display: flex;
    align-items: center;
    cursor: pointer;
    background: '#9F90B0';
    border: 0;
    color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  
    &:hover {
      background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
    }
  
    &:active {
      background: ${theme.palette.mode === 'dark' ? grey[700] : grey[100]};
    }
  
    &:focus-visible {
      box-shadow: 0 0 0 4px ${theme.palette.mode === 'dark' ? blue[300] : blue[200]};
      outline: none;
    }
    `,
  );

  const Option = styled(BaseOption)(
    ({ theme }) => `
    list-style: none;
    padding: 8px;
    border-radius: 8px;
    cursor: default;
  
    &:last-of-type {
      border-bottom: none;
    }
  
    &.${optionClasses.selected} {
      background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[100]};
      color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
    }
  
    &.${optionClasses.highlighted} {
      background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    }
  
    &:focus-visible {
      outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    }
  
    &.${optionClasses.highlighted}.${optionClasses.selected} {
      background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[100]};
      color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
    }
  
    &.${optionClasses.disabled} {
      color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
    }
  
    &:hover:not(.${optionClasses.disabled}) {
      background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    }
    `,
  );
  const StyledButton = styled('button', { shouldForwardProp: () => true })(
    ({ theme }) => `
    position: relative;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    box-sizing: border-box;
    min-width: 320px;
    padding: 8px 12px;
    border-radius: 8px;
    text-align: left;
    line-height: 1.5;
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    box-shadow: 0px 2px 4px ${
      theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.5)' : 'rgba(0,0,0, 0.05)'
    };
  
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 120ms;
  
    &:hover {
      background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
    }
  
    &.${selectClasses.focusVisible} {
      outline: 0;
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[700] : blue[200]};
    }
  
    & > svg {
      font-size: 1rem;
      position: absolute;
      height: 100%;
      top: 0;
      right: 10px;
    }
    `,
  );
  const Popup = styled('div')`
    z-index: 1;
  `;  
export default MerchantsFilter;