import * as React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels, Title);

const DBPie = (props) => {
  const options = {
    // layout: {
    //   padding: {
    //     left: 50,
    //     right: 50,
    //     top: 50,
    //     bottom: 50
    //   }
    // },
    plugins: {
      title: {
        display: false,
        text: null,
      },
      legend: {
        display: false,
      },
      datalabels: {
        backgroundColor: function (context) {
          return context.dataset.backgroundColor;
        },
        borderColor: "black",
        margin:70,
        borderRadius: 25,
        color: "white",
        borderWidth: 2,        
        anchor: "center",
        clip: "false",
        font: {
          size: "12px",
        },
        display: function (context) {
          var dataset = context.dataset;
          //var count = dataset.data.length;
          var value = dataset.data[context.dataIndex];

          return value >= 3;
        },
        formatter: function (value, context) {
          var key = context.chart.data.labels[context.dataIndex];
          return key;
        },
      },
    },
  };
  const bgColors = [
    "#2E5E87",
    "#0d6efd",
    "#0C67B7",
    "#2F6797",
    "#4AA1E7",
    "#94A3B1",
  ];
  const data = {
    labels: props.data?.labels,
    datasets: [
      {
        label: props.data?.labelText,
        data: props.data?.percentages,
        backgroundColor: bgColors,
        borderWidth: 2,
        borderColor: "black",
      },
    ],
  };

  return (
    <div
      className={"d-flex flex-column pie-box-container" + (props.rotate ? " dv2-pie" : "")}
      style={{ position: "relative" }}
    >
      <Doughnut data={data} options={options} width={270} height={270} />
    </div>
  );
};

export default DBPie;
