import React, { useEffect, useRef, useState, useMemo } from "react";
import GlobeInfo from "./boxes/GlobeInfo";
import Globe from "react-globe.gl";
import { observer } from "mobx-react-lite";
import { state, StateManager } from "../state/stateManager";
import * as THREE from "three";
import { isMobile } from "react-device-detect";
import gh from "../lib/globe-helper";
import { config } from "../state/config";
import { get, set } from "mobx";
import { debug } from "openai/core";
import { ReturnConsumedCapacity } from "@aws-sdk/client-dynamodb";
const GlobeX = observer(
  ({
    onMouseLeave,
    isFullScreen,
    onZoomIn,
    onZoomOut,
    show3DStars = false,
  }) => {
    const globeEl = useRef();

    const [globeEnabled, setGlobaEnabled] = useState(true);
    const globeConatiner = useRef();
    const viewedOrders = useRef([]);
    const [gWidth, setgWidth] = useState(0);
    const [gHeight, setgHeight] = useState(0);
    const [rippleRings, setRippleRings] = useState([]);
    const [autoRotate, setAutoRotate] = useState(true);
    const [fps, setFps] = useState(0);
    const [fpsEnabled, setFpsEnabled] = useState(false);
    const [fpsWarning, setFpsWarning] = useState("");
    const directLight = useRef(null);
    const originalLightPos = { x: 0, y: 0, z: 0 };
    const FPS_THRESHOLD = 45;
    const LOWER_FPS_COUNT_THRESHOLD = 10;
    const LOW_FPS_STORE = [];
    const HIGH_FPS_STORE = [];
    const MIN_ALT = 0.97;
    const MAX_ALT = 2.89;
    const [currentAltitude, setCurrentAltitude] = useState(1.7);
    const [globeParams, setGlobeParams] = useState({
      arcAltitude: 0.2,
      rings: 16,
      arcAltitudeAutoScale: 0.3,
      arcDashLength: 0.2,
      arcDashAnimateTime: 2000,
      arcStroke: 0.25,
    });
    const [highestAmount, setHighestAmount] = useState(0);
    const enableZoom = true;

    const getColor = (amount, maxOrderAmount) => {
      return gh.getWarmerColor(amount, maxOrderAmount);
    };

    const [geRoutes, setGeRoutes] = useState([]);
    const getContainerDimenstions = () => {
      let w = globeConatiner.current.parentElement.offsetWidth;
      let h = globeConatiner.current.parentElement.offsetHeight;

      setgHeight(h);
      if (!isFullScreen) {
        const shiftFactor = 0.4;
        const shiftAmmount = shiftFactor * w;
        if (!isMobile) {
          globeConatiner.current.style.marginLeft = `-${shiftAmmount}px`;

          setgWidth(w + shiftAmmount);
        } else {
          setgWidth(w);
        }
      } else {
        setgWidth(w);
      }
    };

    useEffect(() => {
      if (state.routesInfo && state.routesInfo.length > 0) {
        let maxValue = state.routesInfo.reduce((a, b) =>
          a.amount > b.amount ? a : b
        );
        setHighestAmount(maxValue.amount);
        setGeRoutes(state.routesInfo);
        let ri = state.routesInfo;

        setGlobeParams(getParamters(state.routesInfo, maxValue.amount));
        clearViewedOrders();
      }
 
    }, [state.routesInfo]);

    const clearViewedOrders = () => {
      const upViewedOrders = [];
      if (
        viewedOrders.current &&
        state.routesInfo &&
        state.routesInfo.length > 0
      ) {
        state.routesInfo.forEach((route) => {
          if (viewedOrders.current.indexOf(route.orderId) > -1) {
            //if this order was viewed but still exists in the route info, add it to the viewed orders updated list
            upViewedOrders.push(route.orderId);
          }
        });

        viewedOrders.current = upViewedOrders;
      }
    };

    const updateRippleRing = (routeData) => {
      const ripple = {
        lat: routeData.to_latitude,
        lng: routeData.to_longitude,
        maxR: 2.7,
        propagationSpeed: 0.5,
        repeatPeriod: 250,
        color: "yellow",
      };

      //update current ripple
      setRippleRings([ripple]);
    };

    const getRandomPoint = () => {
      const rndPoint =
        state.routesInfo[Math.floor(Math.random() * state.routesInfo.length)];
      let orderId = rndPoint.orderId;
      if (
        viewedOrders.current &&
        viewedOrders.current.indexOf(orderId) !== -1
      ) {
        return getRandomPoint();
      } else {
        viewedOrders.current.push(orderId);

        return rndPoint;
      }
    };
    const zoomInRandomly = () => {
      if (globeEl.current !== null && globeEnabled) {
        const rndPoint = getRandomPoint();
        onZoomIn(rndPoint);
        globeEl.current.controls().autoRotate = false;
        globeEl.current.pointOfView(
          {
            lat: rndPoint.to_latitude,
            lng: rndPoint.to_longitude,
            altitude: 0.8,
          },
          2000
        );
        updateRippleRing(rndPoint);
      }
      //get back to normal
      setTimeout(() => {
        zoomOut(globeEl.current);

        onZoomOut();
      }, 8000); //spend 8 seconds on the point
    };
    const checkFPS = (fps) => {
      if (fps < FPS_THRESHOLD) {
        LOW_FPS_STORE.push(fps);
        if (LOW_FPS_STORE.length > LOWER_FPS_COUNT_THRESHOLD) {
          LOW_FPS_STORE.splice(0, LOW_FPS_STORE.length);
          HIGH_FPS_STORE.splice(0, HIGH_FPS_STORE.length);
          //if the fps is low for a while, stop the auto rotate
          //setAutoRotate(false);
          //show fps warning
          setFpsWarning(
            "your system doesn't meet the minimum requirements for showing the 3D globe, please consider disabling the 3D globe feature in the settings. The 3D Globe will be disabled in few seconds."
          );
          state.simulateMenuClick(StateManager.MenuKeys.GLOBE_ACTIVATION);
        }
      } else {
        HIGH_FPS_STORE.push(fps);
        if (HIGH_FPS_STORE.length > LOWER_FPS_COUNT_THRESHOLD) {
          HIGH_FPS_STORE.splice(0, HIGH_FPS_STORE.length);
          LOW_FPS_STORE.splice(0, LOW_FPS_STORE.length);
          //if the fps is low for a while, stop the auto rotate
          //setAutoRotate(false);
          //show fps warning
          setFpsWarning("");
        }
      }
    };
    useEffect(() => {
      let frameCount = 0;
      let startTime = Date.now();
      let lastTime = startTime;

      const calculateFPS = () => {
        const now = Date.now();
        const deltaTime = now - lastTime;
        frameCount++;

        // Update FPS every second
        if (now - startTime > 1000) {
          if (fpsEnabled) setFps(frameCount);    
          checkFPS(frameCount);
          frameCount = 0;
          startTime = now;
        }

        lastTime = now;
        requestAnimationFrame(calculateFPS); // Recursively call to render next frame
      };

      calculateFPS();

      return () => {
        // Cleanup function if the component is unmounted
        cancelAnimationFrame(calculateFPS);
      };
    }, [fpsEnabled]);

    const zoomOut = (globeCurrent) => {
      setRippleRings([]);
      if (globeCurrent !== null && globeEnabled) {
        globeCurrent.controls().autoRotate = autoRotate;
        globeCurrent.pointOfView(
          { lat: 39.6, lng: -98.5, altitude: getAltitude() },
          2000
        );
      }
      setTimeout(() => {
        zoomInRandomly();
      }, 15000); //spend 15 seconds rotating
    };
    useEffect(() => {
      // aim at continental US centroid
      if (globeConatiner && globeEnabled) getContainerDimenstions();
    }, [globeConatiner]);

    useEffect(() => {
      let globeEnabledState = state.getMenuItemLogicalState(
        StateManager.MenuKeys.GLOBE_ACTIVATION
      );
      let isGlobeEnabled =
        globeEnabledState == StateManager.MenuLogicalStates.ENABLED;

      let fpsEnabledState = state.getMenuItemLogicalState(
        StateManager.MenuKeys.GLOBE_FPS
      );
      let isFPSEnabled =
        fpsEnabledState == StateManager.MenuLogicalStates.ENABLED;

      setFpsEnabled(isFPSEnabled);

      setGlobaEnabled(isGlobeEnabled);
    }, [state.menuItems]);
    const getAltitude = () => {
      if (isMobile) {
        return 1.5;
      } else if (!isFullScreen) {
        return 1.7;
      } else {
        return 2;
      }
    };

    const isInteractionEnabled = () => {
      return true;
    };

    useEffect(() => {
      if (globeEnabled) {
        let globeRotationState = state.getMenuItemLogicalState(
          StateManager.MenuKeys.GLOBE_ROTATION
        );
        let isRotatationEnabled =
          globeRotationState == StateManager.MenuLogicalStates.ENABLED;

        globeEl.current.controls().autoRotate = isRotatationEnabled;

        globeEl.current.pointOfView({
          lat: 39.6,
          lng: -98.5,
          altitude: getAltitude(),
        });
        globeEl.current.controls().enabled = isInteractionEnabled();

        getContainerDimenstions();
      }
      
    }, [globeEnabled, state.menuItems]);
    // Handle visibility change
    const handleVisibilityChange = () => {
      if (document.hidden) {
        setGlobaEnabled(false);
      } else {
        let globeEnabledState = state.getMenuItemLogicalState(
          StateManager.MenuKeys.GLOBE_ACTIVATION
        );
        let isGlobeEnabled =
          globeEnabledState == StateManager.MenuLogicalStates.ENABLED;
        setGlobaEnabled(isGlobeEnabled);
      }
    };
    useEffect(() => {
      // aim at continental US centroid

      if (globeEnabled) {
        globeEl.current.pointOfView({
          lat: 39.6,
          lng: -98.5,
          altitude: getAltitude(),
        });
        let globeRotationState = state.getMenuItemLogicalState(
          StateManager.MenuKeys.GLOBE_ROTATION
        );
        let isRotatationEnabled =
          globeRotationState == StateManager.MenuLogicalStates.ENABLED;
        globeEl.current.controls().enabled = isInteractionEnabled();
        globeEl.current.controls().autoRotate = isRotatationEnabled;
        if (!isFullScreen) {
          globeEl.current.controls().enableZoom = false;
        }
        getContainerDimenstions();
      }

      document.addEventListener("visibilitychange", handleVisibilityChange);
      if (enableZoom && isFullScreen && state.routesInfo && globeEnabled) {
        setTimeout(() => {
          zoomInRandomly();
        }, 3000);
      }

      return () => {
        document.removeEventListener(
          "visibilitychange",
          handleVisibilityChange
        );
      };
    }, [globeEnabled]);
    useEffect(() => {
      if (globeEl.current) {
        if (!isFullScreen) {
          globeEl.current.controls().enableZoom = false;
        }
      }

      setCurrentAltitude(getAltitude());
    }, []);
    const mouseLeave = () => {
      //if (onMouseLeave) onMouseLeave();
    };

    const getParamters = (routesInfo, maxOrderAmount) => {
      //filter
      routesInfo = routesInfo.filter((r) => r.from_latitude !== undefined);
      let config = {};
      if (isFullScreen) {
        config.arcAltitude = 0.2;
        config.rings = 2;
        config.arcsData = routesInfo;
        config.arcAltitudeAutoScale = 0.3;
        /*
        config.labelsData = gh.getLabelsData(routesInfo, maxOrderAmount);
        config.labelLat = (d) => d.lat;
        config.labelLng = (d) => d.lng;
        config.labelText = (d) => d.text;
        config.labelAltitude = (d) => d.altitude;
        config.labelSize = (d) => d.size;
        config.labelDotRadius = (d) => d.labelDotRadius;
        config.labelColor = (d) => d.color;
        config.labelResolution = 2;

        config.pointsData = gh.getPointsData(routesInfo, maxOrderAmount);
        config.pointAltitude = (r) =>
          gh.getPointAltitude(r.amount, maxOrderAmount);
        config.pointColor = (r) => gh.getPointColor(r.amount, maxOrderAmount);
        */
        config.arcDashLength = 1;
        config.arcDashAnimateTime = (d) => gh.getRndTime();
        config.arcStroke = 0.1;
        config.arcDashGap = 2;
        config.htmlElementsData = gh.getMarkersData(routesInfo);

        config.htmlElement = (d) => {
          const el = document.createElement("div");
          el.className = "globe-point";
          el.innerHTML = "<p></p>";
          return el;
        };

        if (show3DStars) {
          config.customThreeObject = (data) => {
            const { size, color } = data;
            return new THREE.Mesh(
              new THREE.SphereGeometry(size),
              new THREE.MeshBasicMaterial({ color })
            );
          };

          config.customLayerData = [...Array(500).keys()].map(() => ({
            lat: (Math.random() - 1) * 360,
            lng: (Math.random() - 1) * 360,
            altitude: Math.random() * 2,
            size: Math.random() * 0.4,
            color: "#fff",
          }));
          config.customThreeObjectUpdate = (obj, data) => {
            const { lat, lng, altitude } = data;
            return Object.assign(
              obj.position,
              globeEl.current?.getCoords(lat, lng, altitude)
            );
          };
        }

        config.arcColor = ["#ffffff00", "#faf7e6", "#ffffff00"];
        config.backgroundImageUrl = "stars.jpg";
      } else {
        config.arcsData = routesInfo.splice(-routesInfo.length / 3);

        config.arcAltitude = 0.2;
        config.rings = 16;
        config.arcColor = (d) => getColor(d.amount, maxOrderAmount);
        config.arcAltitudeAutoScale = 0.3;
        config.arcDashLength = 0.2;
        config.arcDashAnimateTime = 2000;
        config.backgroundImageUrl = "stars_low.jpg";
        config.arcStroke = 0.25;
        config.arcDashGap = 0.02;
        config.customLayerData = [];
        config.htmlElementsData = gh.getMarkersData(routesInfo);
        config.htmlElement = (d) => {
          const el = document.createElement("div");
          el.innerHTML = "<p></p>";
          el.className = "globe-point";
          // el.style.backgroundColor = "yellow";
          // el.style.borderRadius = "100%";
          // el.style.boxShadow =
          //   "0 0 3px 1px #FFFF00,0 0 3px 1px #fff,0 0 6px 1px #FFFF00";
          // el.style.width = `2px`;
          // el.style.height = `2px`;
          // el.style["pointer-events"] = "auto";
          // el.style.cursor = "pointer";
          return el;
        };
      }

      return config;
    };
    // Globe options, customize as needed
    const globeOptions = {
      // ... your globe options
      animateIn: false, // Control animation based on tab visibility
    };
    const manualZoom = (inOut) => {
      //get current pointOfView

      let pov = globeEl.current.pointOfView();
      //zoom in
      let newAltitude = inOut == "in" ? pov.altitude - 0.1 : pov.altitude + 0.1;
      setCurrentAltitude(newAltitude);
      if (newAltitude >= MIN_ALT && newAltitude <= MAX_ALT) {
        globeEl.current.pointOfView(
          { lat: pov.lat, lng: pov.lng, altitude: newAltitude },
          200
        );
      }
      //re-disable zoom
      //globeEl.current.controls().enableZoom = false;
    };
    return globeEnabled ? (
      <div ref={globeConatiner} onMouseUp={mouseLeave}>
        <div
          className="d-flex flex-column fps-container"
          data-visible={fpsEnabled || fpsWarning !== ""}
          data-background={fpsWarning !== ""}
        >
          <div
            data-warning={fps < FPS_THRESHOLD}
            className="fps-text"
            data-visible={fpsEnabled}
          >
            FPS:{fps}
          </div>
          <div className="fps-warning">{fpsWarning}</div>
        </div>
        <div className="globe-info d-none">
          <GlobeInfo />
        </div>
        <div
          data-visible={!isFullScreen}
          className="globe-zoom-container d-flex flex-column"
        >
          <span
            data-disabled={currentAltitude < MIN_ALT}
            onClick={() => manualZoom("in")}
            className="material-symbols-outlined zoom-icon"
          >
            add
          </span>
          <span
            data-disabled={currentAltitude > MAX_ALT}
            onClick={() => manualZoom("out")}
            className="material-symbols-outlined zoom-icon"
          >
            remove
          </span>
        </div>
        <Globe
          ref={globeEl}
          htmlElementsData={globeParams.htmlElementsData}
          htmlElement={globeParams.htmlElement}
          rendererConfig={{
            antialias: false,
            alpha: false,
            precision: "lowp",
            premultipliedAlpha: false,
          }}
          segments={32}
          options={globeOptions}
          rings={globeParams.rings}
          labelsData={globeParams.labelsData}
          labelLat={globeParams.labelLat}
          labelLabel={() => "<div>EDEN ZAHARONI</div>"}
          labelAltitude={globeParams.labelAltitude}
          labelLng={globeParams.labelLng}
          labelText={globeParams.labelText}
          labelSize={globeParams.labelSize}
          labelColor={globeParams.labelColor}
          labelResolution={globeParams.labelResolution}
          labelDotRadius={globeParams.labelDotRadius}
          pointsData={globeParams.pointsData}
          pointColor={globeParams.pointColor}
          pointAltitude={globeParams.pointAltitude}
          customLayerData={globeParams.customLayerData}
          ringsData={rippleRings}
          ringPropagationSpeed={(d) => d.propagationSpeed}
          ringRepeatPeriod={(d) => d.repeatPeriod}
          ringColor={() => (t) => `rgba(227,228,67,${1 - t})`}
          ringAltitude={0.001}
          ringMaxRadius={(d) => d.maxR}
          customThreeObject={globeParams.customThreeObject}
          customThreeObjectUpdate={globeParams.customThreeObjectUpdate}
          globeImageUrl="test.jpg"
          backgroundImageUrl={globeParams.backgroundImageUrl}
          width={gWidth}
          height={gHeight}
          arcAltitude={0.2}
          backgroundColor="rgba(0,0,0,0)"
          arcsData={globeParams.arcsData}
          arcAltitudeAutoScale={globeParams.arcAltitudeAutoScale}
          //arcLabel={d => `${d.from_name} --> ${d.to_name}`}
          arcStartLat={(d) => +d.from_latitude}
          arcStartLng={(d) => +d.from_longitude}
          arcEndLat={(d) => +d.to_latitude}
          arcEndLng={(d) => +d.to_longitude}
          arcDashLength={globeParams.arcDashLength}
          arcDashGap={globeParams.arcDashGap}
          arcDashAnimateTime={globeParams.arcDashAnimateTime}
          arcStroke={globeParams.arcStroke}
          arcColor={globeParams.arcColor}
          enablePointerInteraction={false}

          /*
      globeMaterial={
        new THREE.MeshPhongMaterial({
          color: '#1a2033',
          opacity: 0.95,
          transparent: true,
        })
      }
      polygonsData={topojson.feature(landTopology, landTopology.objects.land).features}
      polygonSideColor={() => '#94A3B1'}
      polygonCapMaterial={
        new THREE.MeshPhongMaterial({
          color: '#fff',
          side: THREE.DoubleSide,
          //map: new THREE.TextureLoader().load(texture),
        })
      }*/
        />
      </div>
    ) : (
      ""
    );
  }
);

export default GlobeX;
