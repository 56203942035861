import GeneralBox from "../general/GeneralBox";
import { observer } from "mobx-react-lite";
import { state } from "../../state/stateManager";
import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import {config} from "../../state/config";
const CannelBreakdown = observer(() => {
  const [channelList, setChannelList] = useState([]);
  useEffect(() => {
    if (state.channelInfo) {
      let keys = Object.keys(state.channelInfo);
      let list = [];
      //iterate keys
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        let value = state.channelInfo[key];
        if (value.count !== undefined) {
          let info = getChannelName(key);
          if (value.count !== null) {
            let amount = value.amount;
            //console.log(config.enableGMV);
            if (config.initiated && 
              config.enableGMV &&
              value.amountGMV !== null) {      
              amount = value.amountGMV;
            }
         
            list.push({
              channel: info.name,
              count: value.count,
              amount: amount,
              offline: false,
              title: info.title,
            });
          } else {
            list.push({
              channel: info.name,
              count: 0,
              amount: 0,
              offline: true,
              title: info.title,
            });
          }
        }
      }
      //sort channel list by amount
      list.sort((a, b) => {
        return b.amount - a.amount;
      });
      setChannelList(list);
    }
  }, [state.channelInfo]);

  const getChannelName = (channel) => {
    switch (channel) {
      case "borderfree":
        return { name: "BF", title: "Borderfree" };
      case "globale":
        return { name: "GE", title: "Global-e" };
      case "flow":
        return { name: "MP", title: "Market Pro" };
      default:
        return channel;
    }
  };
  return (
    <GeneralBox
      dPadding="2"
      hasBorder={true}
      hasBackground={true}
      css={"order-sales-info mb-2 stock-mini-container"}
    >
      <div className="d-flex" style={{ height: "100%" }}>
        <div className="dash-yesterday-content">
          <div className="d-flex flex-column" style={{ height: "100%" }}>
            <div className="d-flex dash-side-header">
              <i className="bi bi-list-ol"></i>
              <div>Channel's</div>
            </div>
            <div className="d-flex channel-breakdown flex-column">
              {channelList.map((channel, index) => {
                return (
                  <div
                    key={index}
                    className="channel-breakdown-item d-flex flex-wrap w-100 justify-content-between align-items-center flex-grow-1"
                  >
                    <div
                      className="channel-breakdown-name"
                      title={channel.title}
                    >
                      {channel.channel}
                    </div>
                    <div
                      data-visible={!channel.offline}
                      className="channel-breakdown-count"
                    >
                      {channel.count}
                    </div>
                    <div
                      className="channel-breakdown-amount"
                      data-offline={channel.offline}
                    >
                      {!channel.offline ? (
                        <NumericFormat
                          value={channel.amount.toFixed(0)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      ) : (
                        "Stream offline"
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </GeneralBox>
  );
});

export default CannelBreakdown;
