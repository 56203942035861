import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import DBLineChart from "../common/DBLIneChart";
import GeneralBox from "../general/GeneralBox";
import { NumericFormat } from "react-number-format";
import { state } from "../../state/stateManager";
import moment from "moment";
import ZoomSwitch from "../common/ZoomSwitch";
const RTUniqueSessions = observer(
  ({
    property = "cos",
    color = "rgba(0, 255, 21, 1)",
    icon,
    title,
    AxisYModulo = 10000,
    AxisYReduction = 1000,
    Suffix = "K",
    extendYLabel = false,
  }) => {
    const [lastNumber, setLastNumber] = useState(null);
    const [percentageDiff, setPercentageDiff] = useState("");
    const [zoomEnabled, setZoomEnabled] = useState(false);
    useEffect(() => {
      //get last hour numbers
      if (state.weeklyStatistics) {
        //current hour
        let hour = moment.utc().format("HH");
        let date = moment.utc().format("MM/DD/yyyy");
        //console.log(JSON.stringify(state.weeklyStatistics.chart_uniqie_ses));
        if (state.weeklyStatistics.chart_uniqie_ses.data[date]) {
          if (state.weeklyStatistics.chart_uniqie_ses.data[date][hour]) {
            setLastNumber(
              state.weeklyStatistics.chart_uniqie_ses.data[date][hour][property]
            );
          }
        }

        //get percentage diff
        if (state.weeklyStatistics.chart_uniqie_ses.percentageDiff) {
          if (
            state.weeklyStatistics.chart_uniqie_ses.percentageDiff[property]
          ) {
            setPercentageDiff(
              state.weeklyStatistics.chart_uniqie_ses.percentageDiff[property]
            );
          }
        }
      }
    }, [state.weeklyStatistics]);
    const handleChange = (event) => {
      setZoomEnabled(event.target.checked);
    };
    return (
      <GeneralBox
        dPadding="2"
        hasBorder={false}
        hasBackground={true}
        css="mb-2 minheight_200"
      >
        <div className="unique-sessions-title justify-content-between flex-wrap d-flex">
          <div className="d-flex unique-sessions-title-text">
            <i className={"rt-icon bi " + icon}></i> {title}{" "}
            {lastNumber != null ? (
              <span className="d-flex rt-info">
                (last count is{" "}
                <span className="highlight-amount">
                  <NumericFormat
                    value={lastNumber}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={""}
                  />
                </span>
                <span
                  className="rt-p-diff d-flex flex-wrap"
                  data-positive={percentageDiff >= 0}
                >
                  <i
                    className={
                      "bi bi-caret-" +
                      (percentageDiff >= 0 ? "up" : "down") +
                      "-fill"
                    }
                  ></i>
                  {percentageDiff < 0 ? "-" : ""}
                  {percentageDiff}%
                </span>{" "}
                sessions)
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="trends-switch-container">
            <ZoomSwitch label={"Zoom"} onChange={handleChange} />
          </div>
        </div>

        <DBLineChart
          height={300}
          startZoomed={true}
          enableZoom={zoomEnabled}
          startZoomedHourDelta={4}
          showBars={true}
          todayColor={color}
          pointRadius={0}
          property={property}
          extendYLabel={extendYLabel}
          AxisYModulo={AxisYModulo}
          AxisYReduction={AxisYReduction}
          width={"95%"}
          Suffix={Suffix}
          AxisXCaption="Hours"
          statistics={
            state.weeklyStatistics && state.weeklyStatistics.chart_uniqie_ses
          }
        />
      </GeneralBox>
    );
  }
);

export default RTUniqueSessions;
