import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import hammer from "hammerjs";
import { faker } from "@faker-js/faker";
import zoomPlugin from "chartjs-plugin-zoom";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  registerables as registerablesJS,
} from "chart.js";
import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(...registerablesJS);
ChartJS.register(zoomPlugin);
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const DBLineChart = ({
  pointRadius = 3,
  statistics,
  AxisYModulo,
  AxisYReduction,
  AxisYCaption,
  AxisXCaption,
  Suffix = "",
  width = "560px",
  multipleKeys,
  property,
  startZoomed = false,
  extendYLabel = false,
  todayColor = "#189ad3",
  showBars = false,
  enableZoom = false,
  startZoomedHourDelta = 2,
  interaction_mode = "nearest",
  height = 220,
}) => {
  const [data, setData] = useState({ labels: [], datasets: [] });
  const chartRef = useRef(null);
  const FIXED_BAR_COS = 20000;
  const FIXED_BAR_BOS = 2000000;
  const calculatePercentageDifference = (num1, num2) => {
    // Step 1: Calculate the absolute difference between the numbers
    const absoluteDifference = Math.abs(num1 - num2);
    
    // Step 2: Calculate the average of the two numbers
    const average = (num1 + num2) / 2;
    
    // Step 3: Calculate the percentage difference
    const percentageDifference = (absoluteDifference / average) * 100;
    
    return percentageDifference;
  }
  const getFixedBarHeight =()=> {
    return property==="cos"?FIXED_BAR_COS:FIXED_BAR_BOS;
  }
  useEffect(() => {
    if (statistics) {
      try {
        const numberOdDays = Object.keys(statistics.data).length;

        let dataSets = [];
        let si = 7 - numberOdDays;
        const now = moment.utc();
        const currentHour = now.hours();
        const datasetsDict = {};
        let dataArraysDict = {};
        const dateKey = now.format("L");
        let isToday = false;
        let i = 0;
        let previousDay = null;
        if (property!==undefined) {
         // debugger;
        }
        for (var key in statistics.data) {
          let current_day = statistics.data[key];
          //console.log(key + " : " +  JSON.stringify(current_day));
          isToday = key === dateKey;
       
          if (multipleKeys) {
            //create multiple datasets for same label
            for (var k in multipleKeys) {
              datasetsDict[multipleKeys[k].name] = {
                pointRadius: isToday ? pointRadius : 1,
                lineTension: 0.8,
                fill: false,
                borderWidth: isToday ? (i + 1 === numberOdDays ? 6 : 3) : 2,
                label: key,
                backgroundColor: isToday ? multipleKeys[k].color : "yellow",
                borderColor: isToday ? multipleKeys[k].color : "yellow",
              };
            }
          } else {
            //set border width
            let borderWidth = 1;
            if (!showBars) {
              if (isToday) {
                if (i + 1 === numberOdDays) {
                  borderWidth = 6;
                } else {
                  borderWidth = 3;
                }
              } else {
                borderWidth = 2;
              }
            }
            datasetsDict[0] = {
              pointRadius: isToday ? pointRadius : 1,
              lineTension: 0.8,
              fill: false,
              borderWidth: borderWidth,
              label: key,
              backgroundColor: isToday
                ? todayColor
                : faker.color.rgb({ format: "css", includeAlpha: !isToday }),
              borderColor: isToday ? todayColor : colors[i + si],
            };
            datasetsDict[1] = {
              type: "bar",
              pointRadius: 0,
              pointHitRadius: 0,
              label: key,
              borderWidth: 1,
              backgroundColor: isToday ? todayColor : "#C0C0C033",
              //borderColor: isToday?todayColor:"rgba(255, 0, 0, 0.4)"
            };
          }

          let dataArr = [];
          let barDatsArr = [];

          let previousKey = null;
          for (var keyValue in statistics.labels) {
            //key is correlated to the label

            if (
              current_day[statistics.labels[keyValue]] &&
              !(isToday && keyValue === currentHour)
            ) {
              //dataArr.push(current_day.data[item].a)
              if (!multipleKeys) {
                if (property) {
                  dataArr.push(current_day[keyValue][property]);
                  if (showBars && previousDay !== null) {
                    if (previousDay[keyValue] && previousDay[previousKey]) {
                      //calculate diff
                      //current hour
                      if (previousKey !== null) {
                        let currentDayHourlyDiff =
                          current_day[keyValue][property] -
                          current_day[previousKey][property];
                        //get max sessions count between today and previous day
                        let max = Math.max(current_day[keyValue][property], previousDay[keyValue][property]);
                        //get diff in percentage between today and previous day
                        let percentageDiff = calculatePercentageDifference(current_day[keyValue][property], previousDay[keyValue][property]);
                       
                        if (previousDay[keyValue][property]>current_day[keyValue][property]) {
                          //previous day is fixed 20000 and its higher than today
                          //we need to reduce the percentage diff from 20000 and set it as the value
                          barDatsArr.push(getFixedBarHeight()*(1-percentageDiff/100));
                        }
                        else{
                          //today is higher than previous day
                          //we need to add the percentage diff to the value
                          barDatsArr.push(getFixedBarHeight() + (1+percentageDiff/100));
                        }

                        //let yesterdayHourlyDiff = previousDay[keyValue][property] - previousDay[previousKey][property];
                  
                        //barDatsArr.push(Math.abs(currentDayHourlyDiff) * 20);
                        //barDatsArr.push(current_day[keyValue][property]);
                      }
                    }
                  } else { //first day, no previous day
                    if (
                      previousKey !== null &&
                      current_day[keyValue] &&
                      current_day[previousKey]
                    ) {
                      let currentDayHourlyDiff =
                        current_day[keyValue][property] -
                        current_day[previousKey][property];
                        let val = currentDayHourlyDiff * 20;
                   
                      //barDatsArr.push(val);
                      //barDatsArr.push(current_day[keyValue][property] * 20);
                      barDatsArr.push(getFixedBarHeight());
                    }
                  }
                } else {
                  dataArr.push(current_day[keyValue]);

                  if (showBars && previousDay !== null) {
                    if (previousDay[keyValue]) {
                      barDatsArr.push(
                        current_day[keyValue] - previousDay[keyValue]
                      );
                    }
                  }
                }
              } else {
                for (var k in multipleKeys) {
                  let prop = multipleKeys[k].name;
                  if (!dataArraysDict[prop]) dataArraysDict[prop] = [];
                  dataArraysDict[prop].push(current_day[keyValue][prop]);
                }
              }
            } else {
              //current hour not recorded in this day

              dataArr.push(null);
            }

            previousKey = keyValue;
          }

          if (!multipleKeys) {
            if (isToday) {
              //remove current hour as its not recorded yet
              dataArr[currentHour] = null;
            }
            datasetsDict[0].data = dataArr;

            dataSets.push(datasetsDict[0]);
            if (showBars) {
              datasetsDict[1].data = barDatsArr;
              dataSets.push(datasetsDict[1]);
            }
          } else {
            for (var k in multipleKeys) {
              let property = multipleKeys[k].name;
              datasetsDict[property].data = dataArraysDict[property];
              dataSets.push(datasetsDict[property]);
            }
          }

          i++;
          previousDay = current_day;
        }

        const data = {
          labels: statistics.labels,
          datasets: dataSets,
        };

        setData(data);
      } catch (err) {
        console.log(err);
      }
    }

    if (chartRef.current) {
      const chart = chartRef.current;
      if (chart) {
        // chart.zoomScale("y", { min: 100, max: 210 }, 'none')
        // chart.update();
      }
    }
  }, [statistics]);

  const options = {
    animation: {
      duration: 0,
    },
    maintainAspectRatio: false,
    responsive: true,
    interaction: {
      mode: interaction_mode,
      intersect: false,
    },
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: true,
        //text: 'Chart.js Line Chart',
      },
    },
    scales: {
      y: {
        display: true,
        grid: {
          display: false,
          color: "red",
        },
        title: {
          display: true,
          text: AxisYCaption,
          color: "#fff",
        },
        ticks: {
          callback: function (value, index, values) {
            // Display every 2nd label
            if (value === 0) return "";
            let v =
              value % AxisYModulo === 0
                ? `${
                    extendYLabel
                      ? (value / AxisYReduction).toFixed(1)
                      : value / AxisYReduction
                  }${Suffix}`
                : null;
            if (value / AxisYReduction === 1) {
              //debugger;
            }
            if (property !== undefined) 
              return value / 20;
            else
              return v;
          },
        },
      },
      x: {
        display: true,
        grid: {
          display: false,
          color: "red",
        },
      },
      yAxes: {
        display: false,
        grid: {
          display: false,
          color: "red",
        }
      },
      xAxes: {
        display: false,
        grid: {
          display: false,
          color: "red",
        },      
        title: {
          display: true,
          text: AxisXCaption,
          color: "#fff",
        },
        ticks: {
          callback: function (value, index, values) {
            // Display every 2nd label

            return index % 2 === 0 ? value : null;
          },
        },
      },
    },
  };
  if (startZoomed) {
    //get utc 2 hours before now and 2 hours after now, in HH
    const now = moment.utc();
    const currentHour = now.hours();
    const min = currentHour - startZoomedHourDelta;
    const max = currentHour + startZoomedHourDelta;

    options.scales.x.min = min;
    options.scales.x.max = max;
  }
  if (enableZoom) {
    options.plugins.zoom = {
      zoom: {
        wheel: {
          enabled: true, // SET SCROOL ZOOM TO TRUE
        },
        mode: "xy",
        sensitivity: 3.1,
        rangeMin: {
          x: 4,
        },
        rangeMax: {
          x: 8,
        },
      },
      pan: {
        enabled: true,
        mode: "xy",
        speed: 100,
      },
      // limits: {
      //   x: { min: 2, max: 4 },
      // },
    };
  }
 
  if (showBars) {
    options.plugins.tooltip = {
      filter: function (tooltipItem) {
        return [1, 3].includes(tooltipItem.datasetIndex);
      },
    };
  }

  if (property!==undefined) {
    options.plugins.tooltip = {
      callbacks : {
        label: function(context) {
          if (context.dataset.type !== "bar")
          {
          var label = context.dataset.label || '';
        
          if (label) {
              label += ': ';
          }

            if (context.parsed.y !== null) {
                label += context.parsed.y;
            }
        
    
      
          return label;
          }
          else{
            return null;
          }
        }
      
      }
    }
  }

  const colors = [
    "rgba(139, 148, 240, 0.20)",
    "rgba(148, 240, 139, 0.20)",
    "rgba(240, 239, 139, 0.20)",
    "rgba(237, 139, 240, 0.20)",
    "rgba(240, 155, 139, 0.20)",
    "rgba(240, 197, 139, 0.20)",
    "rgba(0, 255, 21, 1)",
  ];

  return (
    <div style={{ width: width, height: height }}>
      <Line ref={chartRef} height={height} options={options} data={data} />
    </div>
  );
};

export default DBLineChart;
