import React  from 'react';
import GeneralBox from '../general/GeneralBox';
import { NumericFormat } from 'react-number-format';
import { observer } from "mobx-react-lite"
const OrderCounter = observer(({ css, count,text }) => {
    return (
        <GeneralBox dPadding="2" hasBorder={true} hasBackground={true} css={"mb-2 yesterday-container " + css}>
             <div className='d-flex'>
                <div className='dash-yesterday-content'>
                    <div className="flex-column">
                        <div className='fg dash-side-header'>{text}</div>
                        <div className='fg dash-yesterday-amount'><NumericFormat value={count} displayType={'text'} thousandSeparator={true}  /></div>
                    </div>
                  
                   

                </div>
             </div>
        </GeneralBox>
      )
})

export default OrderCounter;