import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import ChannelIndicator from "./ChannelIndicator";

const MerchantRowLazy = ({ item, elKey, isSearchResults }) => {
  const hasChildren = item.children && item.children.length > 1 ? true : false;
  const [childrenVisible, setchildrenVisible] = useState(false);
  const [visible, setVisible] = useState(true);
  const itemHeight = 32;
  const onClick = () => {
    if (hasChildren) {
      setchildrenVisible(!childrenVisible);
    }
  };
  useEffect(() => {
    if (!item.country) {
      //console.log("no country", item.id);
    }
  }, [item]);
 

 

  return (
    <div
      data-issearchresult={isSearchResults}
      key={elKey}
      onClick={onClick} 
      data-ischild={item.position == null}
      className={"d-flex dash-merchant-row"}
      data-childrenvisible={childrenVisible}
      data-haschildren={hasChildren}
      title={item.id}
    >
      <div className="merchants-count-index">{item.position}</div>
      <div className={"f32 d-flex dash-width-20"} style={{ minWidth: "40px" }}>
        <div className={"flag " + item.country?.toLowerCase()}></div>
      </div>
      <div className="d-flex flex-grow-1 align-items-center">
        {item.name} <ChannelIndicator channel={item.channel} />
        {hasChildren ? (
          <i className="bi bi-card-list merged-merchant-icon"></i>
        ) : (
          ""
        )}
      </div>
      <div className="d-flex text-end justify-content-end  dash-amount-container">
        <NumericFormat
          value={item.amount.toFixed(0)}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$"}
        />
      </div>
      <div
        className="d-flex text-end justify-content-center  dash-ocount-container"
        style={{ color: "#5BA2E3" }}
      >
        <NumericFormat
          value={item.count}
          displayType={"text"}
          thousandSeparator={true}
          prefix={""}
        />
      </div>
      <div
        className="d-flex text-end justify-content-end pe-4 dash-aov-container"
        style={{ width: "60px" }}
      >
        <span className="dash-aov">
          ${(item.amount / item.count).toFixed(0)}
        </span>
      </div>
    </div>
  );
};

export default MerchantRowLazy;
