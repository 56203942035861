import { useEffect,useState } from "react";

const Logos = ({enabled}) => {
    const [opacity, setOpacity] = useState('0');
    useEffect(() => {
        if (enabled) {       
            setOpacity(1);
        }
        return () => {
            setOpacity(0);
        };
    }, [enabled]);
    return (
        <div className="d-flex logos-container" style={{opacity:opacity}}>
            <iframe src="https://connect.global-e.com/logos-carousel" />
        </div>
    )
}
export default Logos;