import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const ConfirmButton = ({btnText, title, message, onConfirm, color = "primary" }) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = (agree) => {
        setOpen(false);
        if (agree)
          onConfirm();
      };    
      return (
        <React.Fragment>
          <Button variant="contained" color={color} onClick={handleClickOpen}>
            {btnText}
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {title}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {message}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>handleClose(false)}>Cancel</Button>
              <Button onClick={()=>handleClose(true)} autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      );      
}

export default ConfirmButton;