import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import Login from "./Login";
import fetcher from "../../lib/api-fetcher";
import { config } from "../../state/config";
import { useNavigate } from "react-router-dom";
const LoginSplash = (props) => {
  const [loginNeeded, setLoginNeeded] = useState(false);
  const [loginMessage, setLoginMessage] = useState(""); // [1]
  const navigate = useNavigate();
  useEffect(() => {
    //alert("Cookie = " + Cookies.get('.ASPXAUTH'));
    if (props.authenticated !== undefined && props.authenticated === false) {
      //check if in native mode
      if (handleNativeLogin() === true) {
        return;
      }
      setLoginNeeded(true);
      setTimeout(() => {
        //window.location.href = `https://web.global-e.com/account/SSOLogin?returnUrl=${window.location.href}`
      }, 3000);
    }
  }, [props.authenticated]);

  const onLogin = (username, password) => {
    fetcher.login(username, password, false).then((data) => {
      if (data.success) {
        if (data.admin) {
          config.isAdmin = true;
        }

        if (data.bypass) {
          config.bypass = true;
        }

        navigate(0); //reload
      } else {
        setLoginMessage("Invalid username or password");
      }
    });
  };
  const handleNativeLogin = () => {
    if (window.webkit && window.webkit.messageHandlers.messageHandler) {
      window.webkit.messageHandlers.messageHandler.postMessage("require_login");
      return true;
    } else {
      return false;
    }
  };
  return (
    <div className="loadingSplash d-flex flex-column">
      <div className="loader" data-visible={!loginNeeded}>
        {" "}
      </div>
      <div
        style={{ display: loginNeeded === true ? "none" : "block" }}
        className="loader-text loadertxt"
      ></div>
      <div
        className="login-box d-flex flex-column"
        data-loginrequired={loginNeeded}
      >
        <div className="loader-text-login">
          <Login onLogin={onLogin} message={loginMessage} />
        </div>
      </div>
      <div
        className="error-message"
        dangerouslySetInnerHTML={{ __html: props.errorMessage }}
        data-iserror={props.isError}
      ></div>
    </div>
  );
};

window.nativeAuthentication = (data) => {
  Cookies.set(".ASPXAUTH", data, { path: "/" });
  // Reload the page
  window.location.reload();
};

export default LoginSplash;
