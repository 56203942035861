import React, { useEffect, useState, useRef } from "react";
import GmvBox from "./boxes/GmvBox";
import { isMobile } from "react-device-detect";
import SessionsInfo from "./boxes/SessionsInfo";
import OrdersData from "./boxes/YesterdayInfo";
import { useLocation } from "react-router-dom";
import TopHubs from "./boxes/TopHubs";
import GlobeX from "./Globe";
import GlobeInteractionEnabler from "./general/GlobeInteractionEnabler";
import Header from "./boxes/Header";
import PiesContainer from "./boxes/PiesContainer";
import TopPlatforms from "./boxes/TopPlatforms";
import { observer } from "mobx-react-lite";
import RTUniqueSessions from "./boxes/RealTimeUniqSessions";
import WeeklyAmounts from "./boxes/LineCharts/WeeklyAmounts";
import WeeklyCounts from "./boxes/LineCharts/WeeklyCounts";
import { state, StateManager } from "../state/stateManager";
import WorldMapVis from "./boxes/WorldMap";
import moment from "moment";
import StockChart from "./boxes/StockChart";
import StockMiniWidget from "./boxes/StockMini";
import CustomMessages from "./boxes/CustomMessages";
import ReleaseNotes from "./boxes/ReleaseNotes";
import ReleaseNotesPage from "./ReleaseNotesPage";
import ConfigEditor from "./boxes/ConfigEdiror";
import MerchantsLazy from "./boxes/MerchantsLazy";
import ChannelBreakdown from "./boxes/ChannelBreakdown";
import UserManagement from "./Admin/UserManagement";
import MonthlySales from "./boxes/MonthlySales";
import SalesLastXDays from "./boxes/SalesLastXDays";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const DashboardMode = observer(() => {
  const query = useQuery();
  const is_native = query.get("native");
  const leftContainerRef = useRef(null);
  const gmvBoxRef = useRef(null);
  const merchantContainerRef = useRef(null);
  const [globeInteractionStopped, setglobeInteractionStopped] = useState(false);

  const [conversionRate, setConversionRate] = useState(null);
  const [minHeight, setMinHeight] = useState("auto");
  const [activePage, setActivePage] = useState(null);
  const [ready, setReady] = useState(false);
  const [postFixAtomicMonthly, setPostFixAtomicMonthly] = useState("");
  const [stockInfoEnabled, setStockInfoEnabled] = useState(true);
  const [enableViewResetButton, setEnableViewResetButton] = useState(false);
  const [dailyAverage, setDailyAverage] = useState(0);
  const [atomicMonthly, setAtomicMonthly] = useState(0);
  const [containerHeight, setContainerHeight] = useState("985px");
  const [yesterdayAmount, setYesterdayAmount] = useState(0);
  useEffect(() => {
    if (activePage == null) {
      calculateBodyHeight();
    }
  }, [activePage]);

  const calculateBodyHeight = (element = "body") => {
    const body = document.querySelector(element);
    var initialHeight = body.offsetHeight + 80;
    //verify if initial height is lower then viewport height, if so, set viewport height
    if (initialHeight < window.innerHeight) {
      initialHeight = window.innerHeight;
    }
    setMinHeight(initialHeight);
    document.body.style.overflow = "auto";
  };
  const onPageLoad = () => {
    calculateBodyHeight(".dashboard-page");
  };
  const onBoxExpended = (status) => {};
  const onGlobeMouseLeave = () => {
    setglobeInteractionStopped(true);
  };
  const onGlobeInteractionStarts = () => {
    setglobeInteractionStopped(false);
  };
  const onHeaderClick = () => {};
  useEffect(() => {
    //state.dashboardData?.yesterday?.amount
    if (state.dashboardData?.yesterday?.amountGMV) {
      setYesterdayAmount(state.dashboardData?.yesterday?.amountGMV);
    }
    else if (state.dashboardData?.yesterday?.amount) {
      setYesterdayAmount(state.dashboardData?.yesterday?.amount);
    }
  },[state.dashboardData]);
  useEffect(() => {
    if (state.dashboardStats) {
      let perc =
        (state.dashboardStats.checkout.count /
          state.dashboardStats.browsing.count) *
        100;
      setConversionRate(perc.toFixed(2));
    }
  }, [state.dashboardStats]);

  const showMonthlySales = () => {
    const nextMonth = moment("2024-02-01");
    const today = moment();
    return {
      show: today.isSameOrAfter(nextMonth),
      text: "Data will be available on 1st of next month",
    };
  };
  useEffect(() => {
    //get amount, if amountGMV is available, use it
    let amount = 0;
    if (state.atomicMonthly?.amountGMV) {
      amount = state.atomicMonthly?.amountGMV;
    }
    else if (state.atomicMonthly?.amount) {
      amount = state.atomicMonthly?.amount;
    }

    if (amount !==0) {
  
      if (amount > 100000000) {
        setAtomicMonthly((amount / 1000000).toFixed(2));
        setPostFixAtomicMonthly("M");
      } else {
        setAtomicMonthly(amount.toFixed(0));
        setPostFixAtomicMonthly("");
      }
    }
  }, [state.atomicMonthly]);
  useEffect(() => {
    let stockEnabled = state.getMenuItemLogicalState(
      StateManager.MenuKeys.STOCK_INFO
    );
    let isStockEnabled = stockEnabled == StateManager.MenuLogicalStates.ENABLED;

    if (!isStockEnabled) {
      setStockInfoEnabled(false);
    } else {
      setStockInfoEnabled(true);
    }

    const dailyEstimationEnabled =
      state.getMenuItemLogicalState(StateManager.MenuKeys.DAILY_ESTIMATION) ==
      StateManager.MenuLogicalStates.ENABLED;
    if (!isMobile) {
      if (dailyEstimationEnabled) {
        //alert("daily estimation");
        setContainerHeight("1060px");
      } else {
        //alert("no daily estimation");
        setContainerHeight("1017px");
      }
    }
  }, [state.menuItems]);

  useEffect(() => {
    //check if active page exists in local storage
    const activePage = localStorage.getItem("activePage");

    if (activePage) {
      onPageNavigation(activePage);
    } else {
      setActivePage(null);
    }

    setReady(true);
  }, []);
  const onPageClose = () => {
    setActivePage(null);
    localStorage.removeItem("activePage");
    setEnableViewResetButton(false);
  };

  const onPageNavigation = (page) => {
    localStorage.setItem("activePage", page);

    switch (page) {
      case "/releasenotes":
        setActivePage(
          <ReleaseNotesPage onLoad={onPageLoad} onClose={onPageClose} />
        );
        break;
      case "/config":
        setActivePage(
          <ConfigEditor onLoad={onPageLoad} onClose={onPageClose} />
        );
        break;
      case "/users":
        setActivePage(<UserManagement />);
        break;
    }
 
    setEnableViewResetButton(true);
  };

  const onAverageCalculated = (average) => {
    setDailyAverage(average);
  };
  return (
    <div
      data-visible={ready}
      data-native={is_native}
      className="layout-dashboard-container p-5"
      style={{ minHeight: minHeight }}
    >
      <ReleaseNotes />
      <CustomMessages />
      {/* <SideMenu toggleMenu={toggleMenu} /> */}
      <div className="d-flex dv2-header-container">
        <Header
          onResetView={onPageClose}
          enableViewResetButton={enableViewResetButton}
          onPageNavigation={onPageNavigation}
          onHeaderClick={onHeaderClick}
        />
      </div>

      {activePage !== null ? (
        activePage
      ) : (
        <div className="dash-main-container d-flex  align-items-stretch">
          <div
            className="d-flex dv2-leftcolumn flex-wrap"
            style={{ position: "relative" }}
          >
            <div
              className="globe-gl-container d-flex flex-wrap w-100"
              style={{ height: containerHeight }}
              data-enabled={
                state.getMenuItemLogicalState(
                  StateManager.MenuKeys.GLOBE_ACTIVATION
                ) == StateManager.MenuLogicalStates.ENABLED
              }
            >
              <img
                alt="bg"
                className="bg-image"
                src="dash_back_low.png"
                width="100%"
                height="100%"
              />
              <GlobeX isFullScreen={false} onMouseLeave={onGlobeMouseLeave} />
            </div>
            <GlobeInteractionEnabler
              onGlobeInteractionStarts={onGlobeInteractionStarts}
              onGlobeInteractionStopped={globeInteractionStopped}
              container={leftContainerRef}
            />

            {/*<GlobeX />*/}
            <div
              ref={leftContainerRef}
              className="dv2-lefttop-container flex-row-reverse d-flex flex-wrap"
            >
              <div
                ref={gmvBoxRef}
                className="flex-grow-1 align-self-start gmvbox-container"
              >
                <GmvBox onAverageCalculated={onAverageCalculated} />
              </div>

              <div className="d-flex  flex-grow-1 flex-row-reverse justify-content-between orders-sessions-container">
                <div className="d-flex flex-wrap order-data-container justify-content-between orders-data-container">
                  <OrdersData
                    text="Yesterday Sales"
                    amount={yesterdayAmount}                    
                    count={state.dashboardData?.yesterday?.count}
                    dailyAverage={dailyAverage}
                  />
                  <MonthlySales
                    css="monthly-box"
                    text="Monthly Sales"
                    amount={atomicMonthly}
                    count={state.atomicMonthly?.count}
                    hour={state.atomicMonthly?.update_hour}
                    postFix={postFixAtomicMonthly}
                  />
                  <StockMiniWidget />
                  <ChannelBreakdown />
                </div>
                <div className="d-flex sessions-data-container align-items-end">
                  <SessionsInfo
                    percent={conversionRate}
                    iconsCss={"bi bi-cart-check"}
                    gaugeInfo={{ min: 0, max: 4000 }}
                    property="checkout"
                    iconBG={"#9C1BFA"}
                    icon={"bi-cart-plus"}
                    title={"Realtime Checkout Customers "}
                  />
                  <SessionsInfo
                    iconsCss={"bi bi-person-fill"}
                    gaugeInfo={{ min: 0, max: 150000 }}
                    property="browsing"
                    iconBG={"#4FC2F4"}
                    icon={"bi-browser-chrome"}
                    title={"Realtime Browsing Customers "}
                  />
                </div>
              </div>
              <div
                ref={merchantContainerRef}
                className="dv2-merchant-container  d-flex w-100 align-self-start"
              >
                <div className="d-flex w-50 flex-grow-1">
                  <MerchantsLazy />
                </div>
                <div
                  className="d-flex  w-50 flex-grow-1 flex-column"
                  style={{ gap: "8px" }}
                >
                  <WeeklyAmounts />
                  <WeeklyCounts />
                </div>
              </div>
            </div>
            <div className="d-flex lastXdays-sales" style={{maxWidth:"100%", minWidth:"100%"}}>
                <SalesLastXDays />
            </div>
            <div
              style={{ maxWidth: "100%" }}
              className="d-flex weekly-trends-container flex-shrink-1"
            >
              <div
                className="flex-grow-0 weekly-trend-container"
                style={{ flex: "0 2 50%" }}
              >
                <RTUniqueSessions
                  title="Unique Checkout Sessions"
                  property="cos"
                  icon="bi-cart"
                  color="#0d6efd"
                />
              </div>
              <div
                className="flex-grow-0 weekly-trend-container"
                style={{ flex: "0 2 50%" }}
              >
                <RTUniqueSessions
                  title="Unique Browsing Sessions"
                  icon="bi-browser-chrome"
                  extendYLabel={true}
                  AxisYModulo={100000}
                  AxisYReduction={1000000}
                  property="bos"
                  color="#0d6efd"
                  Suffix="M"
                />
              </div>
            </div>

            <PiesContainer />
            <div className="d-flex flex-wrap dash-row map-row">
              <WorldMapVis fullWidth={!stockInfoEnabled} />
              <StockChart visible={stockInfoEnabled} title="GLBE Daily Stats" />
            </div>

            <div
              style={{ maxWidth: "100%" }}
              className="d-flex weekly-trends-container flex-shrink-1"
            >
              <div
                className="flex-grow-1 weekly-trend-container"
                style={{ flex: "0 2 50%" }}
              >
                <TopHubs onExpand={onBoxExpended} />
              </div>
              <div className="flex-grow-1" style={{ flex: "0 2 50%" }}>
                <TopPlatforms onExpand={onBoxExpended} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

export default DashboardMode;
