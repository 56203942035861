import React, { useState, useEffect, useRef } from "react";
import { state, StateManager } from "../state/stateManager";
import { observer } from "mobx-react-lite";
import { isMobile } from "react-device-detect";
import { config } from "../state/config";
import Cookies from "js-cookie";
import {
  Menu,
  MenuItem,
  MenuButton,
  SubMenu,
  ControlledMenu,
  useClick,
} from "@szhsin/react-menu";
//import '@szhsin/react-menu/dist/index.css';
//import '@szhsin/react-menu/dist/transitions/slide.css';
import { useNavigate } from "react-router-dom";
const TopMenu = observer(
  ({ children, onPageNavigation, isFullScreen = false }) => {
    //const [items, setItems] = useState({});
    const menuItemClassName = ({ hover }) =>
      hover ? "dash-topmenu-menuitem-hover" : "dash-topmenu-menuitem";
    const [menuItem, setMenuItem] = useState([]);
    const items = useRef({});
    const [ready, setReady] = useState(false);
    const ref = useRef(null);
    const [isOpen, setOpen] = useState(false);
    const anchorProps = useClick(isOpen, setOpen);

    const navigate = useNavigate();
    const getTitleAndIcon = (item) => {
      let title = item.title;
      let icon = item.icon;
      if (item.state == StateManager.MenuStates.CLICKED) {
        if (item.altTitle) {
          title = item.altTitle;
        }
        if (item.altIcon && item.altIcon !== "") {
          icon = item.altIcon;
        } else {
          icon = item.icon;
        }
      }

      return { title, icon };
    };
    useEffect(() => {
      let mItems = {};
      let menuItems = [];
      if (state.menuItems) {
        for (var i = 0; i < state.menuItems.length; i++) {
          let item = state.menuItems[i];
          //check if viewable in full screen mode
          if (isFullScreen && !item.enabledInFullScreen) {
            continue;
          }
          if (item.showOnMobile!==undefined && !item.showOnMobile && isMobile) {
              continue;
          }
          let menuItem = "";
          //check if sub items exists
          if (item.subItems) {
            menuItem = (
              <SubMenu    
                key={item.key}
                label={
                  <>
                    <span className="menu-icon material-symbols-outlined">
                      {item.icon}
                    </span>{" "}
                    {item.title}
                  </>
                }
              >
                {item.subItems.map((subItem, subIndex) => {
                  let { title, icon } = getTitleAndIcon(subItem);
                  mItems[subItem.key] = subItem;
                  let disabled = subItem.admin && !config.isAdmin;
                  let disabledInBypass =
                    subItem.enabledInBypass !== undefined
                      ? !subItem.enabledInBypass && config.bypass
                      : false;
                  return (
                    (disabled || disabledInBypass)?<div></div>:<MenuItem   
                      data-key={subItem.key}   
                      disabled={disabled || disabledInBypass}
                      value={subItem.key}
                      onClick={onItemClick}
                      className={menuItemClassName}
                      key={subItem.key}
                    >
                      <span className="menu-icon material-symbols-outlined">
                        {icon}
                      </span>{" "}
                      {title}
                    </MenuItem>
                  );
                })}
              </SubMenu>
            );

            // for (var j = 0; j < item.subItems.length; j++) {
            //     let subItem = item.subItems[j];
            //     subItems[subItem.key] = subItem;
            // }
            //item.subItems = subItems;

            menuItems.push(menuItem);
          } else {
            mItems[item.key] = item;
            let { title, icon } = getTitleAndIcon(item);
            let disabled = item.admin && !config.isAdmin;

            let disabledInBypass =
              item.enabledInBypass !== undefined
                ? !item.enabledInBypass && config.bypass
                : false;
            menuItem = (
              (disabled || disabledInBypass)?<div></div>:<MenuItem
                data-key={item.key}   
                disabled={disabled || disabledInBypass}
                value={item.key}
                onClick={onItemClick}
                className={menuItemClassName}
                key={item.key}
              >
                <span className="material-symbols-outlined menu-icon">
                  {icon}
                </span>{" "}
                {title}
              </MenuItem>
            );

            menuItems.push(menuItem);
          }
        }
        //console.log(JSON.stringify(mItems));
        setMenuItem(menuItems);
        //setItems(mItems);
        items.current = mItems;
      }
    }, [state.menuItems]);

    const onItemClick = (menuItem) => {
      const item = items.current[menuItem.value];
      state.onMenuItemClicked(item);
      if (item.state === StateManager.MenuStates.STATIC) {
        //navigate(item.actionUrl);
        if (item.actionUrl) onPageNavigation(item.actionUrl);
        else if (item.function) {
          eval(item.function)();
        }
      }
    };

    const logout = () => {
      //delete cookie
      Cookies.remove("DASH_TOKEN");
      Cookies.remove("DASH_USER");
      Cookies.remove("sessionToken");
      //reload
      navigate(0);
    };

    useEffect(() => {
      //HANDLE bug with menu styles
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
        setReady(true);
      }, 300);
    }, []);
    return (
      <div>
        <div ref={ref} {...anchorProps}>
          {children}
        </div>
        <div data-visible={ready}>
          {menuItem.length > 0 ? (
            <ControlledMenu
            menuStyle={{marginTop:"10px"}}
              onClose={() => setOpen(false)}
              state={isOpen ? "open" : "closed"}
              anchorRef={ref}
              menuClassName="dash-top-menu"
              transition
            >
              {menuItem}
            </ControlledMenu>
          ) : null}
        </div>
      </div>
    );
  }
);
export default TopMenu;
