import AdminLogin from "./boxes/AdminLogin"
import { useNavigate } from "react-router-dom";
const Admin = () => {
    const navigate = useNavigate();
    const onAuthenticate = () => {
        //back to root
        navigate("/");
    }
    const onCancel = () => {
        navigate("/");
    }
    return (
        <div>
             <AdminLogin onAuthenticate={onAuthenticate} onCancel={onCancel} />
        </div>
    )

}
export default Admin;