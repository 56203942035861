
import GeneralBox from '../general/GeneralBox';
import { NumericFormat } from 'react-number-format';
import React, { useState,useEffect } from 'react';
export default function TopCountries(props) {
    const [topCountriesBarInfo, setTopCountriesBarInfo] = useState([]);
 
  useEffect(() => {        
        const list = [];
        var topXpercent = 0;
 
        if (props.topCountries){
         
            const bgList = ["#2E5E87","#0d6efd","#0C67B7","#2F6797","#4AA1E7","#94A3B1"];
            let top = Math.min(5, props.topCountries.data.length);
            for (var i=0;i<top;i++){
                const p = props.topCountries.data[i].amount / props.topCountries.total_amount*100;
                list.push({p : Math.floor(p), color:bgList[i]})
                topXpercent+=Math.floor(p);
            }
  
            list.push({p: 100-Math.floor(topXpercent), color : bgList[5]});        
              
        }
     
        setTopCountriesBarInfo(list);

  }, [props.topCountries]);

  return (
    <GeneralBox dPadding="2" hasBorder={false} hasBackground={false}>
        <div className="topXbar mb-3">
            {topCountriesBarInfo.map((item, key)=> <div key={key} className={""} style={{backgroundColor:item.color , width : item.p.toFixed(0) + "%"}}></div>)}
        </div>
        <div className="dash-top-merchants-list container-fluid">
              
               {props.topCountries!==undefined?props.topCountries.data.slice(0,5).map((item, key)=> 
                   <div className="row" key={key}>
                        <div className="col dash-width-20">  <i  style={{color:topCountriesBarInfo[key] && topCountriesBarInfo[key].color}} className={"bi bi-circle-fill"}></i> </div>
                        <div className="col dash-width-200">{item.country}</div>   
                        <div className="col dash-width-250 tm-amounts d-flex">
                            <div className='d-flex'><NumericFormat value={item.amount.toFixed(0)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> </div>
                            <div className='d-flex topcountry-sep'>
                                <i className="bi bi-star-fill"></i>

                            </div>
                            <div className='d-flex'>
                                {item.count}
                                <i className="bi bi-box-seam" style={{marginLeft:"8px", fontSize:"18px", color:"yellow"}}></i>
                            </div>
                                                      
                        </div>
               
                        <div className="col text-end tm-percent">
                            <NumericFormat value={(item.amount / props.topCountries.total_amount*100).toFixed(0) } displayType={'text'} thousandSeparator={false} suffix={'%'} />   
                        </div>
                    </div> 
               ):<div></div>}
 
        </div>
    </GeneralBox>
  )
}
