import React, { useEffect, useRef ,useState} from "react";
import { config } from "../../state/config";
const Login = ({ onLogin, onCancel, message }) => {
  const userName = useRef(null);
  const password = useRef(null);
  const [loading, setLoading] = useState(false);
  const [oktaEnabled, setOktaEnabled] = useState(false);
  useEffect(() => {
    config.loadLoginConfig((data) => {
      setOktaEnabled(data.enableOkta);
    });
  },[]);


  useEffect(() => {
    if (message) {
      setLoading(false);
    }
  },[message]);
  const onLoginClick = () => {
    setLoading(true);
    onLogin(userName.current.value, password.current.value);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      //check if user name and password are not empty
      if (userName.current.value && password.current.value) {
        onLoginClick();
      }
    }
  };
  const onSubmit = (e) => {
    return false;
  }


  const loginWithOkta = () => {
    setLoading(true);
    const oktaDomain = 'https://global-e.okta.com';
    const clientId = '0oac0sc19tFaPfj7i697';
    const responseType = 'code';
    const scope = 'openid profile email';
    const redirectUri = encodeURIComponent(process.env.REACT_APP_FULL_ADDRESS  + 'api/sso');
    const state = 'randomString'; // Generate a random string for CSRF protection
    
    const oktaAuthUrl = `${oktaDomain}/oauth2/default/v1/authorize?client_id=${clientId}&response_type=${responseType}&scope=${encodeURIComponent(scope)}&redirect_uri=${redirectUri}&state=${state}`;
    window.location.href = oktaAuthUrl;
  }

 
  return (
    <div className="d-flex login-com-container" onKeyDown={handleKeyPress}>
      {!loading?<form onSubmit={onSubmit} method="post">
      <div className="login">
        <h1>Login</h1>

        <input
          type="text"
          name="u"
          autoComplete="username"
          placeholder="Username"
          required="required"
          ref={userName}
        />
        <input
          type="password"
          autoComplete="current-password"
          name="p"
          placeholder="Password"
          required="required"
          ref={password}
        />
        <div className="d-flex justify-content-center" style={{ gap: "5px" }}>
          <button
            onClick={onLoginClick}
            type="button"
            className="btn1 btn-primary btn-block btn-large"
          >
            Let me in.
          </button>

          {onCancel && (
            <button
              onClick={onCancel}
              className="btn btn-dark btn-block btn-large btn-cancel-login"
            >
              Cancel
            </button>
          )}
        </div>

        <div className="login-message">{message}</div>
        <div className="login-general-message">
          In order to access the dashboard outside the office, you'll need
          dashboard credentials, please contact{" "}
          <a href="mailto:innovation@global-e.com">innovation@global-e.com</a>{" "}
          to get them.
        </div>
        <div className="d-flex" style={{marginTop:"10px"}} data-hidden={!oktaEnabled}>
          <button onClick={loginWithOkta} type="button" className="btn-okta  btn-block btn-large">
              Login with Okta
            </button>
        </div>        
      </div>
      </form>:<div className="login-loader">Loading...Please wait</div>}

    </div>
  );
};
export default Login;
