import { useEffect, useRef, useState } from "react";
import AdminContainer from "../boxes/AdminContainer";
import { users } from "../../lib/user-crud";
import { useNavigate } from "react-router-dom";
import ConfirmButton from "../common/ConfirmDialog";
import Checkbox from "@mui/material/Checkbox";
import { set } from "mobx";
const UserManagement = () => {
  const [usersList, setUsersList] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const navigate = useNavigate();
  const userName = useRef(null);
  const displayName = useRef(null);
  const password = useRef(null);
  const isAdmin = useRef(null);
  const apiToken = useRef([]);
  const resetPasswordRef = useRef([]);
  const newPassTxtRef = useRef([]);
  const ITEMS_PER_PAGE = 5;
  const [curretPage, setCurrentPage] = useState(1);
  const [isShowAll, setIsShowAll] = useState(false);
  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = () => {
    users.getUsers((data) => {
      if (data.authenticated) {
        //console.log(data);
        setUsersList(data.list);
      } else {
        //redirect to admin page
        navigate("/admin");
      }
    });
  };
  const createUser = () => {
    if (userName.current) {
      const user = userName.current.value;
      const pass = password.current.value;
      const isadmin = isAdmin.current.children[0].checked;
      const display_name = displayName.current.value;

      users.createUser(user, display_name, pass, isadmin, (data) => {
        console.log(data);
        getUsers();

        //add new user to search results
        //{"user":"test1","password":"...","id":"...","dashboard_admin":"false","display_name":"test test1","api_token":null}'
        let newUserObj = {
          user: user,
          password: pass,
          id: data.userid,
          dashboard_admin: isadmin,
          display_name: display_name,
          api_token: null,
        };
        let results = searchResults;
        results.push(newUserObj);
        setSearchResults(results);

        //clear inputs
        userName.current.value = "";
        password.current.value = "";
        displayName.current.value = "";
        isAdmin.current.children[0].checked = false;
      });
    }
  };

  const deleteUser = (id, index) => {
    showHideApiToken(index, true);
    users.deleteUser(id, (data) => {
      //alert("delete user " + id);
      //remove user from search results
      let results = searchResults.filter((user) => {
        return user.id !== id;
      });
      setSearchResults(results);
      getUsers();
    });
  };

  const createApiToken = (id) => {
    users.createApiToken(id, (data) => {
      //alert("delete user " + id);
      getUsers();
    });
  };
  const showHideResetPass = (index, forceHide = false) => {
    if (resetPasswordRef.current[index].style.display == "none" && !forceHide) {
      resetPasswordRef.current[index].style.display = "block";
    } else {
      resetPasswordRef.current[index].style.display = "none";
    }
  };
  const showHideApiToken = (index, forceHide = false) => {
    if (apiToken.current[index].style.display == "none" && !forceHide) {
      apiToken.current[index].style.display = "block";
    } else {
      apiToken.current[index].style.display = "none";
    }
  };
  const resetPassword = (id, index) => {
    showHideResetPass(index, true);
    let newpass = newPassTxtRef[index].value;
    if (newpass.length < 6) {
      alert("password must be at least 6 characters");
    } else {
      users.resetPassword(id, newpass, (data) => {
        //alert("delete user " + id);
        getUsers();
      });
    }
  };
  const onSearch = (e) => {
    setIsShowAll(false);
    setCurrentPage(1);
    let search = e.target.value;
    if (search.length > 2) {
      let results = usersList.filter((user) => {
        return (
          user.user.toLowerCase().includes(search.toLowerCase()) ||
          user.display_name.toLowerCase().includes(search.toLowerCase())
        );
      });
      setSearchResults(results);
    } else {
      setSearchResults([]);
      //setSearchResults(usersList);
    }
  };
  const deleteApiToken = (id, index) => {
    showHideApiToken(index, true);
    users.deleteApiToken(id, (data) => {
      //alert("delete user " + id);
      getUsers();
    });
  };
  const clearSearchResults = () => {
    setSearchResults([]);
  };
  const showAllUsersWithPaging = () => {
    setIsShowAll(true);
    setCurrentPage(1);
    //show only ITEMS_PER_PAGE users per page
    let results = usersList.slice(0, ITEMS_PER_PAGE);
    setSearchResults(results);
  };
  const showUsersPage = (page) => {
    setCurrentPage(page);
    let results = usersList.slice(
      (page - 1) * ITEMS_PER_PAGE,
      (page - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
    setSearchResults(results);
  };
  const renderPagingComponent = () => {
    if (!isShowAll) {
      return;
    }
    let pages = Math.ceil(usersList.length / ITEMS_PER_PAGE);
    let paging = [];
    for (let i = 1; i <= pages; i++) {
      paging.push(
        <button
          key={i}
          onClick={() => showUsersPage(i)}
          className={
            "btn btn-primary user-page-button" +
            (curretPage == i ? " active" : "")
          }
        >
          {i}
        </button>
      );
    }
    return paging;
  };
  return (
    <AdminContainer>
      <div>
        <h1>User / API Access Management</h1>
        <div>Search {usersList.length} users</div>
        <div className="d-flex flex-wrap" style={{ gap: "10px" }}>
          <input
            type="text"
            className="form-control"
            placeholder="search"
            style={{ maxWidth: "250px" }}
            onChange={onSearch}
          />
          <button onClick={clearSearchResults} className="btn btn-primary">
            Clear
          </button>
          <button onClick={showAllUsersWithPaging} className="btn btn-primary">
            Show All
          </button>
        </div>
        <div>
          <div data-hidden={searchResults.length == 0}>
            <div>
              <h2
                style={{ fontSize: "28px", marginTop: "26px", color: "orange" }}
              >
                Users ({!isShowAll ? searchResults.length : usersList.length})
                <span>{!isShowAll ? " - Search Results" : ""}</span>
              </h2>
            </div>
            <div
              className="d-flex flex-column user-managemenet"
              style={{ gap: "15px" }}
            >
              <div
                className="users-row flex-grow-1 d-flex"
                style={{
                  gap: "20px",
                  borderBottom: "1px solid #fff",
                  marginBottom: "5px",
                  color: "orange",
                  fontWeight: "bold",
                }}
              >
                <div className="user-id-col">user id</div>
                <div className="user-name-col">user name</div>
                <div className="user-name-col">display name</div>
                <div className="user-admin-col">is dashboard admin</div>
                <div className="user-actions-col"> </div>
              </div>
              {searchResults.map((user, i) => (
                <div key={i}>
                  <div
                    className="users-row flex-grow-1 d-flex"
                    style={{ gap: "20px" }}
                  >
                    <div className="user-id-col">{user.id}</div>
                    <div className="user-name-col">{user.user}</div>
                    <div className="user-name-col">{user.display_name}</div>
                    <div className="user-admin-col">
                      <Checkbox
                        disabled
                        checked={user.dashboard_admin === "true"}
                      />
                    </div>
                    <div
                      className="user-actions-col d-flex flex-wrap"
                      style={{ gap: "10px" }}
                    >
                      <button
                        onClick={() => showHideResetPass(i)}
                        className="btn btn-primary"
                      >
                        Reset Pass
                      </button>
                      <ConfirmButton
                        color="error"
                        btnText="Delete"
                        onConfirm={() => deleteUser(user.id, i)}
                        title="Delete User"
                        message="Are you sure you want to delete this user?"
                      />

                      {user.api_token != undefined && user.api_token != null ? (
                        <>
                          <button
                            className="btn btn-success"
                            onClick={() => showHideApiToken(i)}
                          >
                            Show API Token
                          </button>
                        </>
                      ) : (
                        <button
                          onClick={() => createApiToken(user.id)}
                          className="btn btn-primary"
                        >
                          Create API Token
                        </button>
                      )}
                    </div>
                  </div>
                  <div
                    ref={(el) => (resetPasswordRef.current[i] = el)}
                    className="user_reset_password"
                    style={{ display: "none" }}
                  >
                    <div
                      className="d-flex flex-wrap align-items-center"
                      style={{ gap: "20px" }}
                    >
                      <span> Choose a new password</span>
                      <input
                        ref={(el) => (newPassTxtRef[i] = el)}
                        type="text"
                        className="form-control"
                        style={{ maxWidth: "200px" }}
                      />
                      <ConfirmButton
                        color="error"
                        btnText="Reset"
                        onConfirm={() => resetPassword(user.id, i)}
                        title="Reset user password"
                        message="Are you sure you want to reset this user's password?"
                      />
                    </div>
                  </div>
                  <div
                    ref={(el) => (apiToken.current[i] = el)}
                    className="user_api_token"
                    style={{ display: "none" }}
                  >
                    <div
                      className="d-flex flex-column"
                      style={{ gap: "20px", maxWidth: "1200px" }}
                    >
                      <ConfirmButton
                        color="error"
                        btnText="Delete API Token"
                        onConfirm={() => deleteApiToken(user.id, i)}
                        title="Delete API Key"
                        message="Are you sure you want to delete this user's API key?"
                      />

                      <textarea
                        readOnly
                        value={user.api_token !== null ? user.api_token : ""}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="d-flex justify-content-center users-paging">
              {renderPagingComponent()}
            </div>
          </div>
          <div className="users-adduser-container">
            <div>Create new user</div>
            <div
              className="d-flex flex-wrap align-items-center"
              style={{ gap: "10px" }}
            >
              <input
                ref={userName}
                type="text"
                className="user-input form-control"
                placeholder="user name"
              />
              <input
                ref={displayName}
                type="text"
                className="user-input form-control"
                placeholder="display name"
              />
              <input
                ref={password}
                type="text"
                className="user-input form-control"
                placeholder="password"
              />
              Is Admin <Checkbox ref={isAdmin} />
              <button onClick={createUser} className="btn btn-primary">
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
    </AdminContainer>
  );
};
export default UserManagement;
