import React, { useState } from "react";
//import Button from "@mui/material/Button";
import { Menu, MenuItem, MenuButton,SubMenu } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import TopMenu from "./TopMenu";
const Tests = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const menuItemClassName = ({ hover }) =>
  hover ? 'dash-topmenu-menuitem-hover' : 'dash-topmenu-menuitem';

  const handleClick = (e) => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleChange = (event) => {
    //alert(event.target.checked);
  };
  return (
    <div>
      <TopMenu />
  {/* <Menu menuClassName="dash-top-menu"  menuButton={<MenuButton>Menu</MenuButton>} transition>
      <MenuItem className={menuItemClassName}><i className="bi bi-globe-asia-australia menu-icon"></i>Cut</MenuItem>
      <MenuItem className={menuItemClassName}>Copy</MenuItem>
      <MenuItem className={menuItemClassName}>Paste</MenuItem>
      <SubMenu  label="Edit">
        <MenuItem className={menuItemClassName}>Find...</MenuItem>
        <MenuItem className={menuItemClassName}>Find Next</MenuItem>
        <MenuItem className={menuItemClassName}>Find Previous</MenuItem>
      </SubMenu>
    </Menu> */}
    </div>
  );
};
export default Tests;
