import { observer } from "mobx-react-lite";
import { state, StateManager } from "../state/stateManager";
import SideMenuItem from "./SideMenuItem";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { config } from "../state/config";

import ChangeCircleIcon from "@mui/icons-material/ChangeCircle"; //rotate glob
import PublicOffIcon from "@mui/icons-material/PublicOff"; //disable globe
import PhonelinkSetupRoundedIcon from "@mui/icons-material/PhonelinkSetupRounded"; //config
import TrackChangesRoundedIcon from "@mui/icons-material/TrackChangesRounded"; //pie animation
import InsightsRoundedIcon from "@mui/icons-material/InsightsRounded"; //insights
import FullscreenRoundedIcon from "@mui/icons-material/FullscreenRounded"; //fullscreen
import ShowChartRoundedIcon from "@mui/icons-material/ShowChartRounded"; //stock
const SideMenu = observer(({ toggleMenu, visible }) => {
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (toggleMenu !== undefined &&  menuRef.current !== null) {
      if (toggleMenu) {
        //open menu
        menuRef.current.classList.add("open");
      } else {
        //close menu
        menuRef.current.classList.remove("open");
      }
    }
  }, [toggleMenu, visible]);
  useEffect(() => {
    let mItems = [];

    if (state.menuItems) {
      for (var i = 0; i < state.menuItems.length; i++) {
        let item = state.menuItems[i];
        if (item.sideMenu == undefined || item.sideMenu === false) continue;
        if (item.admin === true) {
          if (config.isAdmin || true) {
            mItems.push(item);
          }
        } else {
          mItems.push(item);
        }
      }

      setItems(mItems);
    }
  }, [state.menuItems]);
  const onItemClick = (item) => {
    state.onMenuItemClicked(item);

    //handle static menu items
    if (item.state === StateManager.MenuStates.STATIC && item.actionUrl) {
      navigate(item.actionUrl);
    }
  };
  return (visible?
    <div ref={menuRef} className="dash-side-menu d-flex">
      <div className="dash-side-menu-logo d-flex">
        <div id="shared-loader">
          <div className="cube">
            <div className="cube-face cube-face-front"></div>
            <div className="cube-face cube-face-back"></div>
            <div className="cube-face cube-face-right"></div>
            <div className="cube-face cube-face-left"></div>
            <div className="cube-face cube-face-top"></div>
            <div className="cube-face cube-face-bottom"></div>
          </div>
        </div>
      </div>
      <div className="dash-side-menu-items">
        {items.map((item, index) => {
          return <SideMenuItem key={index} onClick={onItemClick} item={item} />;
        })}
      </div>
    </div>
  :"");
});
export default SideMenu;
