import fetcher from "./api-fetcher";
class UsersCurd {

    getUsers(onLoad) {
        fetcher.getUsers().then((data) => {
            onLoad(data);
        });
    }

    createUser(userName,display_name, password, isAdmin, onLoad) {
        fetcher.createUser(userName,display_name, password, isAdmin).then((data) => {
            onLoad(data);
        });
    }
    deleteUser(id, onLoad) {
        fetcher.deleteUser(id).then((data) => {
            onLoad(data);
        });
    }    
    deleteApiToken(id, onLoad) {
        fetcher.deleteApiToken(id).then((data) => {
            onLoad(data);
        });
    }      
    createApiToken(id, onLoad) {
        fetcher.createApiToken(id).then((data) => {
            onLoad(data);
        });
    }      
    resetPassword(id,newpass, onLoad) {
        fetcher.resetPassword(id,newpass).then((data) => {
            onLoad(data);
        });
    }
}

export const users = new UsersCurd();
 