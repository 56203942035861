import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  ZoomableGroup,
} from "react-simple-maps";
import features from "../../assets/mapfeatures.json";
import { observer } from "mobx-react-lite";
import { Tooltip } from 'react-svg-tooltip';

import { state } from "../../state/stateManager";
import { useEffect, useState,createRef } from "react";
const WorldMapVis = observer(({ fullWidth }) => {
  const [dataMarkers, setDataMarkers] = useState([]);
  const [centerCoords, setCenterCoords] = useState([0, 0]);

  const [hitMap, setHitMap] = useState({});
  const circleRef =  createRef();
  const bgColors = [
    "#2E5E87",
    "#0d6efd",
    "#0C67B7",
    "#2F6797",
    "#4AA1E7",
    "#94A3B1",
  ];
  let x = false;
  useEffect(() => {
    if (
      state.dashboardData &&
      state.dashboardData?.top_countries?.total_amount
    ) {
      let countriesSales = state.dashboardData?.top_countries?.data;
      let overallAmount = state.dashboardData?.top_countries?.total_amount;
      let markers = [];
      let maxCountryAmount = 0;
      let maxCountryCoords = "";
      let hitMapData = {};
      if (!countriesSales) {
        countriesSales = [];
      }
      //countriesSales == [{country, amount, count}...]
      for (let i = 0; i < countriesSales.length; i++) {
        let countryCode = countriesSales[i].country_code;
        let countryname = countriesSales[i].country;
        let amount = countriesSales[i].amount;
        let count = countriesSales[i].count;
        let radius = Math.sqrt(amount / overallAmount) * 20;
        let geo = countriesSales[i].geo;

        if (
          amount > 10000 &&
          countryCode !== null &&
          countryCode !== undefined &&
          countryCode !== "undefined"
        ) {
          markers.push({
            markerOffset: -15,
            name: countryCode ? countryCode : "", //country,
            coordinates: geo ? [geo.lng, geo.lat] : [],
            radius: radius,
            amount :  amount.toFixed(0),
            count : count
          });
        }

        if (amount > maxCountryAmount) {
          maxCountryAmount = amount;
          maxCountryCoords = geo ? [geo.lng, geo.lat - 10] : [];
        }
        if (countryname)
          hitMapData[countryname.toLowerCase()] = getBlueShade(
            amount,
            overallAmount
          );
      } //for
      setHitMap(hitMapData);
      setDataMarkers(markers);
      setCenterCoords(maxCountryCoords);
    } //if
  }, [state.dashboardData]);
  // useEffect(() => {
  //   if (fullWidth) {
  //     setMapWidth("100%");
  //   } else {
  //     setMapWidth("900px");
  //   }
  // },[fullWidth])
  const getBlueShade = (number, maxNumber) => {
    if (maxNumber === null || maxNumber === undefined) {
      return "#ffffff";
    }
    if (number > maxNumber) {
      throw new Error("Number should not exceed maxNumber");
    }

    // Lightness is fixed to represent the middle intensity.
    const lightness = 50;

    // Saturation starts at 0% for grey and increases to 100% for blue.
    const saturation = (number / maxNumber) * 100;

    // Hue is fixed at 240 degrees for blue.
    const hue = 240;

    // Return the HSL color.
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  };
  const getColor = (country) => {
    if (country) {
      if (hitMap[country.toLowerCase()] !== undefined)
        return hitMap[country.toLowerCase()];
      else return "#ffffff22";
    } else {
      return "#ffffff22";
    }
  };
 const toCurrencyFormat=(num)=> {
    num  = parseInt(num);
    return num.toLocaleString('en-US');
  }
  return (
    <div
      className="worldmap-container d-flex dash-box"
      data-fullwidth={fullWidth}
      style={{ position: "relative" }}
    >
      <div className="worldmap-title">
        <i className="bi bi-crosshair2"></i> Top selling countries visualization
      </div>
      <ComposableMap
        style={{ width: "100%" }}
        height={500}
        projectionConfig={{ center: [10, 10] }}
      >
        <ZoomableGroup center={centerCoords} zoom={3}>
          <Geographies geography={features} stroke="#000000" strokeWidth={0.1}>
            {({ geographies }) =>
              geographies.map((geo) => (
                <Geography
                  fill={getColor(geo.properties.name)}
                  key={geo.rsmKey}
                  geography={geo}
                  style={{
                    default: {
                      outline: "none",
                    },
                    hover: {
                      outline: "none",
                    },
                    pressed: {
                      outline: "none",
                    },
                  }}
                />
              ))
            }
          </Geographies>
          {dataMarkers.map(({ name, coordinates, markerOffset, radius, amount, count }) => (
            <Marker key={name} coordinates={coordinates}>
              <circle
                ref={circleRef}
                data-key={name}
                r={radius}
                fill="#cbcb41"
                stroke="#fff"
                strokeWidth={0.5}
                cursor={"pointer"}
              />
              <Tooltip id="test" triggerRef={circleRef}>
                <rect stroke="yellow" strokeWidth={2} x={2} y={2} width={250} height={120} rx={15} fill='black'/>
                <text x={15} y={40} fontSize={22} fill='yellow' style={{fontSize:30}}>
                {name}               
                </text>                
                <text x={15} y={70} fontSize={22} fill='yellow'>
                Amount :  {"$" + toCurrencyFormat(amount)} <br/>               
                </text>
                <text x={15} y={100} fontSize={22} fill='yellow'>
                  Count : {toCurrencyFormat(count)}
                </text>


              </Tooltip>
              {
                <text           
                  cursor={"pointer"}
                  className="svg-small"                  
                  textAnchor="middle"
                  x="0"
                  y="0"
                  dy=".3em"
                  style={{ fontFamily: "system-ui", fill: "#000", pointerEvents:"none" }}
                >
                  {name}
                </text>
                
              }
            </Marker>
          ))}
        </ZoomableGroup>
      </ComposableMap>
    </div>
  );
});
export default WorldMapVis;
