import fetcher from "../lib/api-fetcher";
import { makeAutoObservable, runInAction } from "mobx";
class Config {
  constructor() {
    this.initiated = false;
    this.data = null;
    this.isAdmin = false;
    this.bypass = false;
    // Config.PACE_ALERT_THRESHOLD = 10;
    // Config.CHECKOUT_SESSIONS_THRESHOLD = 100;
    // Config.BROWSING_SESSIONS_THRESHOLD = 10000;

    const configCookie = localStorage.getItem("dash-config");
    if (configCookie) {
      this.data = JSON.parse(configCookie);
    } else {
      this.data = {
        menuItems: {},
      };
    }

    makeAutoObservable(this);
  }

  saveConfig(config, onComplete) {
    runInAction(() => {
      fetcher.saveConfiguration(config).then((data) => {
        this.setConfig(config);
        onComplete && onComplete(data);
      });
    });
  }
  saveMerchantCountries(config, onComplete) {
    runInAction(() => {
      fetcher.saveMerchantCountries(config).then((data) => {
        onComplete && onComplete(data);
      });
    });
  }
  update(config) {
    this.setConfig(config);
  }

  loadLoginConfig(onLoad) {
    fetcher.getLoginConfiguration().then((data) => {
      if (data.error) {
        //error
        return;
      }  
      onLoad && onLoad(data);       
    });
  }

  loadConfig(onLoad) {
 
    fetcher.getConfiguration().then((data) => {
      if (data.error) {
        //error
        return;
      }
      if (!data.authenticated) {
        onLoad && onLoad({ authenticated: false });
      } else {
   
        this.setConfig(data);
        this.initiated = true;
        onLoad && onLoad(data);
      }
    });
  }

  loadMerchantCountries(onLoad) {
    fetcher.getMerchantCountries().then((data) => {
      if (data.error) {
        //error
        return;
      }
      if (!data.authenticated) {
        onLoad && onLoad({ authenticated: false });
      } else {
        onLoad && onLoad(data);
      }
    });
  }

  setConfig(data) {
    if (
      data.custom_messages === undefined ||
      data.custom_messages === null ||
      data.custom_messages.length === 0
    ) {
      data.custom_messages = [
        {
          message:
            "Dashboard service will be down for maintanace until thursday 10:00 AM",
          type: "warning",
          to_date: "2022-02-01",
        },
      ];
    }
    // if (data.general === undefined) {
    //     data.general = {
    //         SHOW_STOCK_INFO: false,
    //     }
    // }
    this.data = JSON.parse(JSON.stringify(data));

    this.PACE_ALERT_THRESHOLD = data.alerts_threshold.PACE_ALERT_THRESHOLD;
    this.CHECKOUT_SESSIONS_THRESHOLD =
      data.alerts_threshold.CHECKOUT_SESSIONS_THRESHOLD;
    this.BROWSING_SESSIONS_THRESHOLD =
      data.alerts_threshold.BROWSING_SESSIONS_THRESHOLD;
    //this.SHOW_STOCK_INFO = data.general.SHOW_STOCK_INFO;
    this.paceMeanThreshold = data.paceMeanThreshold;
    this.enableGMV = data.enableGMV;
    //get USER_AA cookie

    // const aa = Cookies.get("USER_AA");
    // if (aa === "true") {
    //     this.isAdmin = true;
    // }
  }

  persist() {
    if (this.data) {
      localStorage.setItem("dash-config", JSON.stringify(this.data));
    }
  }
}
const config = new Config();
export { config };
//export {Config};
//export default Config
