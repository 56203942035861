import React, { useEffect, useState, useRef } from "react";
import Header from "./boxes/Header";
import GlobeX from "./Globe";
import ReactTimeAgo from "react-time-ago";
import en from "javascript-time-ago/locale/en";
import moment from "moment";
import SideMenu from "./SideMenu";
import TimeAgo from "javascript-time-ago";
import SessionsInfo from "./boxes/SessionsInfo";
import OrderCounter from "./boxes/OrderCounter";
import { state } from "../state/stateManager";
import TopCountries from "./boxesComponents/TopCountries";
import useMetaRefresh from "./common/useMetaRefresh";
import Logos from "./Logos";
TimeAgo.addLocale(en);
const FullGlobeMode = () => {
  useMetaRefresh(60); //reload every 60 minutes
  const [curTextInfo, setCurTextInfo] = useState({});
  const [logosEnabled, setLogosEnabled] = useState(false);
  const logosActive = useRef(false);
  const animateRef = useRef(null);
  const [tvModeClicked, setTvModeClicked] = useState(false);
  const tvModeInerval = useRef(null);
  const logoDurationMS = 1000 * 60 * 2; //2 minutes
  const onHeaderClick = () => {
    //setToggleMenu(!toggleMenu);
  };

  const [toggleMenu, setToggleMenu] = useState(true);
  const [sideMeneVisible, setSideMenuVisible] = useState(true);
 const [animateLeft, setAnimateLeft] = useState("190px");
  useEffect(() => {
    document.body.style.overflow = "hidden";
    // document.addEventListener("dblclick", () => {
    //   setSideMenuVisible(!sideMeneVisible);
    // });
   
  }, []);

  useEffect(() => {
    if (sideMeneVisible) {
      setAnimateLeft("190px");
    } else {
      setAnimateLeft("70px");
    }   
  },[sideMeneVisible]);
  const onDocDoubleClick = () => {
    setSideMenuVisible(!sideMeneVisible);
  }
  const animateIn = () => {
    if (animateRef.current) {
      animateRef.current.style.opacity = "1";
      const left = animateRef.current.getAttribute("data-animateleft");
      animateRef.current.style.left = left;  
    }
  };

  const animateOut = () => {
    if (animateRef.current) animateRef.current.style.opacity = "0";
    setTimeout(() => {
      if (animateRef.current) {
        animateRef.current.style.left = "-900px";
      }
    }, 2000);
  };

  const onZoomIn = (data) => {
    //const text = `Someone in <span>${curTextInfo.to_name} </span> bought something @${curTextInfo.mname} at ${curTextInfo.minute}`
    setCurTextInfo(data);
    animateIn();
  };

  const onZoomOut = () => {
    animateOut();
  };

  const toggleTVmode = () => {
    clearInterval(tvModeInerval.current);
    if (!tvModeClicked) {
      setTvModeClicked(true);
      tvModeInerval.current = setInterval(() => {
        //setLogosEnabled(!logosEnabled);
        logosActive.current = !logosActive.current;
        setLogosEnabled(logosActive.current);
      }, logoDurationMS);
    } else {
      setTvModeClicked(false);
      clearInterval(tvModeInerval.current);
      setLogosEnabled(false);
    }
  };
  const getTime = (orderTime) => {
    if (orderTime === undefined) return new Date();
    let timeParts = orderTime.split(":");
    var today = moment();
    today.set("hour", timeParts[0], "minute", timeParts[1]);
    return today.toDate();
  };
  return (
    <div
      onDoubleClick={onDocDoubleClick}
      className="dash-fullscreen d-flex  flex-grow-1 align-items-left"
      data-menuopened={toggleMenu && sideMeneVisible}
    >
      <Logos enabled={logosEnabled} />
      <div
        className="tv-mode-toggle"
        onClick={toggleTVmode}
        data-enabled={tvModeClicked}
      >
        <i className="bi bi-tv"></i>
      </div>
      <SideMenu visible={sideMeneVisible} toggleMenu={toggleMenu} />
      <div className="d-flex fullscreen-main-container">
        <div className="d-flex dv2-header-container">
          <Header isFullScreen={true} onHeaderClick={onHeaderClick} />
        </div>
        <div
          ref={animateRef}
          data-animateleft={animateLeft}
          className={"text-animate flex-column "}
          style={{ display: "none" }}
        >
          <div
            className={
              "flag flag-icon flag-icon-" +
              (curTextInfo.toCountry && curTextInfo.toCountry.toLowerCase())
            }
          ></div>
          <div className="roller-main-text">
            A shopper in{" "}
            <span
              data-visible={
                curTextInfo.toCity !== undefined && curTextInfo.toCity !== null
              }
              className="roller-city"
            >
              {curTextInfo.toCity},
            </span>{" "}
            <span className="roller-country">{curTextInfo.cname}</span>{" "}
            purchased an item{" "}
            <span className="merchant">@{curTextInfo.mname}</span>
          </div>

          <div className="amount roller-amount">${curTextInfo.amount}</div>
          <div className="roller-time">
            <ReactTimeAgo date={getTime(curTextInfo.minute)} locale="en-US" />
          </div>
        </div>

        <div className="dash-globe-fullsceen-container">
          <GlobeX
            onZoomIn={onZoomIn}
            onZoomOut={onZoomOut}
            isFullScreen={true}
            show3DStars={true}
          />
        </div>
        <div className="full-globe-session-info-container">
          <div className="d-flex">
            <TopCountries
              totalOrdersAmount={
                state.dashboardData !== undefined
                  ? state.dashboardData?.today?.amount
                  : 0
              }
              topCountries={state?.dashboardData?.top_countries}
            />
          </div>

          <OrderCounter
            text="Order Count"
            count={
              state.dashboardStats !== undefined
                ? state.dashboardStats.orders_info.count
                : 0
            }
          />
          <SessionsInfo
            isFullScreen={true}
            iconsCss={"bi bi-cart-check"}
            gaugeInfo={{ min: 0, max: 4000 }}
            property="checkout"
            iconBG={"#9C1BFA"}
            icon={"bi-cart-plus"}
            title={"Realtime checkout customers "}
          />
          <SessionsInfo
            isFullScreen={true}
            iconsCss={"bi bi-person-fill"}
            gaugeInfo={{ min: 0, max: 150000 }}
            property="browsing"
            iconBG={"#4FC2F4"}
            icon={"bi-browser-chrome"}
            title={"Realtime browsing customers "}
          />
        </div>

        <div className="full-globe-order-counter-container"></div>
      </div>
    </div>
  );
};

export default FullGlobeMode;
