import React, { useEffect, useRef, useState } from "react";
import InsightsViewer from "../boxes/InsightsViewer";
import { state, StateManager } from "../../state/stateManager";
import { observer } from "mobx-react-lite";
const GlobeInteractionEnabler = observer(
  ({ container, onGlobeInteractionStopped, onGlobeInteractionStarts }) => {
    const interactor = useRef();
    const checkMovement = useRef();
    const [globeEnabled, setGlobeEnabled] = React.useState(true);
    const [insightsEnabled, setInsightsEnabled] = useState(true);
    useEffect(() => {
      const handleMouseMoveEvent = (e) => {
        if (checkMovement.current || true) {
          // Event handling logic here
          const rect = interactor.current.getBoundingClientRect();

          const isInteracting = isInGlobeRect(rect, e.pageX, e.pageY);
          if (isInteracting) {
            container.current.classList.add("no_pointer_events");
            interactor.current.classList.add("no_pointer_events");
          } else {
            container.current.classList.remove("no_pointer_events");
            interactor.current.classList.remove("no_pointer_events");
          }
        }
      };

      // const handlemouseup = (e) => {
      //   checkMovement.current = false;
      //   const rect = interactor.current.getBoundingClientRect();
      //   const isInteracting = isInGlobeRect(rect, e.pageX, e.pageY);
      //   if (isInteracting) {
      //     container.current.classList.remove("no_pointer_events");
      //     interactor.current.classList.remove("no_pointer_events");
      //   }
      // };

      const handleMouseDownEvent = (e) => {
        checkMovement.current = true;
      };
      //window.addEventListener("mousedown", handleMouseDownEvent);
      window.addEventListener("mousemove", handleMouseMoveEvent);
      //window.addEventListener("mouseup", handlemouseup);
      // Cleanup function to remove the event listener
      return () => {
        window.removeEventListener("mousemove", handleMouseMoveEvent);
        //window.removeEventListener("mouseup", handlemouseup);
        //window.removeEventListener("mousedown", handleMouseDownEvent);
      };
    }, []);

    const isInGlobeRect = (rect, x, y) => {
      return (
        x >= rect.left &&
        x <= rect.right - 830 &&
        y >= rect.top &&
        y <= rect.bottom
      );
    };

    useEffect(() => {
      let globeEnabledState = state.getMenuItemLogicalState(
        StateManager.MenuKeys.GLOBE_ACTIVATION
      );
      let isGlobeEnabled =
        globeEnabledState == StateManager.MenuLogicalStates.ENABLED;

      let insightsEnabledState = state.getMenuItemLogicalState(
        StateManager.MenuKeys.INSIGHTS_ENABLE
      );
      let insightEnabled =
        insightsEnabledState == StateManager.MenuLogicalStates.ENABLED;

      setGlobeEnabled(isGlobeEnabled);
      setInsightsEnabled(insightEnabled);
    }, [state.menuItems]);

    return (
      <div
        ref={interactor}
        className="globe-gl-interaction w-100"
        data-enabled={globeEnabled}
        data-insightsenabled={insightsEnabled}
      >
        <img
          alt="bg"
          className="bg-image"
          src="dash_back_low.png"
          width="100%"
          height="100%"
        />
        <InsightsViewer />
      </div>
    );
  }
);

export default GlobeInteractionEnabler;
