import { useEffect, useState } from "react";

const ChannelIndicator = ({ channel }) => {
  const [channelIndicator, setChannelIndicator] = useState("");
  useEffect(() => {
    switch (channel) {
      case "borderfree":
        setChannelIndicator("BF");
        break;
      case "globale":
        setChannelIndicator("");
        break;
      case "flow":
        setChannelIndicator("MP");
        break;
      default:
        setChannelIndicator("");
        break;
    }
  }, [channel]);

  return (
    <div
      data-hidden={channelIndicator === ""}
      data-channel={channelIndicator}
      className="channel-indicator d-flex align-items-center justify-content-center"
    >
      {channelIndicator}
    </div>
  );
};

export default ChannelIndicator;
