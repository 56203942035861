import React, { useEffect,useState,useRef  }  from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { observer } from "mobx-react-lite"
import {state} from "../../state/stateManager" 



const GlobeInfo = observer(()=> {
    const scrollerRef = useRef(null);
    const [isExpended , setIsExpended] = useState(false);
 
    useEffect(()=> {
      
        setInterval(() => {
            scrollerRef?.current?.scrollToBottom()
        }, 100);
    },[])
 
    return (      
            <div className=' d-flex flex-grow-1 flex-column w-100' style={{position:"absolute"}}>
 
                
                <div className="d-flex pt-1 flex-grow-1 w-100" style={{fontSize : "13px", display :isExpended?"block":"none"}}>                     
                    <Scrollbars key={"globe_scroller"} ref={scrollerRef}
                        renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}      
                        renderThumbVertical={props => <div />}     
                        style={{height: "234px"}}>
                        
                        {state.logs.map((item,i)=> 
                            <div key={i}>[{item.hour}] <span style={{color:item.color}}>{item.log}</span> </div>
                        )}
            
                    </Scrollbars>

                </div>     
                <div className='w-100' style={{height:"40px", backgroundColor:"red", position:"absolute", bottom:"0px", display:"none"}}>

                </div>
            </div>      
      )
})

export default GlobeInfo