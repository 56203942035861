import { useEffect,useRef,useState } from "react";
import {config} from "../../state/config";
import moment from "moment";
const CustomMessages = () => {
    const [messages, setMessages] = useState([]);
    const [visibilityToggle, setVisibilityToggle] = useState(true);
    const isVisible = useRef(true);
    useEffect(() => {

        if (config.data && config.data.custom_messages.length>0) {
            let custom_messages = [];          
            for (let i = 0; i < config.data.custom_messages.length; i++) {
                let message = config.data.custom_messages[i];
                if (message.to_date && moment(message.to_date).isBefore(moment())) {
                    continue;
                }
                switch (message.type){
                    case "warning":
                        message.icon = "bi-exclamation-circle-fill";break;
                    case "info":
                        message.icon = "bi-info-circle-fill";break;
                }
                custom_messages.push(message);
            }

            setMessages(custom_messages);
            const interval = setInterval(() => {
                // Your repeated logic here        
                isVisible.current = !isVisible.current;
              }, 30000); // 1000 ms delay
          
            return () => clearInterval(interval);
        }
    }, [config.data]);
    return (
        <div className="custom-messages" data-visible={messages.length>0} style={{opacity:(isVisible.current?1:0)}}>
            <div className="custom-messages__message">
                {messages.map((message, index) => {
                    return <div key={index} data-type={message.type} className={`custom-messages__message__item custom-messages__message__item--${message.type}`}>
                            <i className={"bi " + message.icon}></i>
                            <div>
                            {message.message}
                            </div>
                        </div>
                })}
            </div>
        </div>
    )
}

export default CustomMessages;