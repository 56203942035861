import React, { useEffect, useRef, useState } from "react";
import GeneralBox from "../general/GeneralBox";
import MerchantRow from "./MerchantRow";
import { NumericFormat } from "react-number-format";
import { Scrollbars } from "react-custom-scrollbars-2";
import { observer } from "mobx-react-lite";
import { state } from "../../state/stateManager";
import MerchantsHeader from "./MerchantsHeader";
const Merchants = observer(({ parentHeight }) => {
  const [sales, setSales] = useState(
    state.dashboardData?.today?.merchant_sales
  );
  const [boundingRect, setBoundingRect] = useState(null);
  const [scrollTop, setScrollTop] = useState(0);
  const [isSearchResults, setIsSearchResults] = useState(false);
  const container = useRef();
  const boxRef = useRef();
  useEffect(() => {
    //OrderCounter
    if (boxRef.current) {
      setBoundingRect(boxRef.current.getBoundingClientRect()); 
    }
  }, [boxRef]);

  useEffect(() => {
 
    if (state.dashboardData) {
      setSales(state.dashboardData.today.merchant_sales);
      debugger;
    }
  }, [state.dashboardData?.today?.merchant_sales]);
  const onChannelFilter = (channel) => {
    switch (channel) {
      case "All" :
        setSales(state.dashboardData.today.merchant_sales);
        setIsSearchResults(false);
        break;
      default:
        const filtered = state.dashboardData.today.merchant_sales.filter((item) => {
          return item.channel === channel;
        });
        setSales(filtered);
        setIsSearchResults(true);
        break;
    }
  }
  const onSearchMerchant = (phrase) => {
    //filter original list and set state
    if (phrase === "") {
      //reset
      setIsSearchResults(false);
      setSales(state.dashboardData.today.merchant_sales);
    } else {
      setIsSearchResults(true);
      const searchResult = state.dashboardData.today.merchant_sales.filter(
        (item) => {
          return (
            item.name !== undefined &&
            item.name.toLowerCase().indexOf(phrase.toLowerCase()) > -1
          );
        }
      );
      setSales(searchResult);
    }
  };
  const onScroll = () => {
    if (container.current) {
      setScrollTop(container.current.container.children[0].scrollTop);
    }
  };
  const getRow = (item, key) => {
    const hasChildren = item.children ? true : false;
    let childrenRows = "";
    if (hasChildren && item.children.length > 1) {
      childrenRows = (
        <div className="merchant-child-container">
          {item.children.map((item, idx) => (
            <MerchantRow
              isSearchResults={isSearchResults}
              scrollTop={scrollTop}
              key={`chrk_${key}_${idx}`}
              rect={boundingRect}
              item={item}
              elKey={`chr_${key}_${idx}`}
            />
          ))}
        </div>
      );
    }
    const mainRow = (
      <div key={"mrkd" + key}>
        <MerchantRow
          isSearchResults={isSearchResults}
          scrollTop={scrollTop}
          key={"mrk" + key}
          rect={boundingRect}
          elKey={"mr" + key}
          item={item}
        />
        {childrenRows}
      </div>
    );
    //let childrenRows = ""

    return mainRow;
  };
  // useEffect(() => {
  //   if (sales){
  //     //take top 20 from sales
  //     let top20 = sales.slice(0, 20);
  //     setSales(top20);

  //   }
  // }, [sales]);
  return (
    <GeneralBox
      dPadding="2"
      hasBorder={false}
      hasBackground={true}
      css={"h-100 dash-merchants-info mb-2 d-flex flex-column"}
    >
      {state.dashboardData && (
        <MerchantsHeader
         onChannelFilter={onChannelFilter}
          onSearch={onSearchMerchant}
          count={sales && sales.length}
        />
      )}
      <div ref={boxRef} className="dash-merchants-list d-flex flex-grow-1">
        <Scrollbars
          key={"merchants_scroller"}
          ref={container}
          onScroll={onScroll}
          className="test-scroll d-flex flex-grow-1"
          renderTrackHorizontal={(props) => (
            <div {...props} className="track-horizontal" />
          )}
          renderThumbVertical={(props) => (
            <div {...props} className="thumb-vertical" />
          )}
        >
          {sales && sales.map((item, key) => getRow(item, key))}
        </Scrollbars>
      </div>
    </GeneralBox>
  );
});

export default Merchants;
