import React, { useEffect, useState } from "react";
import TopCountries from "../boxesComponents/TopCountries";
import GeneralBox from "../general/GeneralBox";
import { NumericFormat } from "react-number-format";
import { observer } from "mobx-react-lite";
import { state, StateManager } from "../../state/stateManager";
import moment from "moment";
import OrdersPaceInfo from "./OrdersPaceInfo";
import GMVPercentChange from "./GmvPercentIncrease";
 import {config} from "../../state/config";
 
const GmvBox = observer(({ onAverageCalculated }) => {
  const [checkoutConversion, setCheckoutConversion] = React.useState(null);
  const [currentAmount, setCurrentAmount] = React.useState(0);
  const [TOVAmount, setTOVAmount] = React.useState(0);
  const [showTOVWidget, setShowTOVWidget] = React.useState(false);
  const [paceAlertActive, setPaceAlertActive] = React.useState(false);
  useEffect(() => {
    //OrderCounter
    if (state.weeklyStatistics && state.channelInfo) {
      let x = state.weeklyStatistics.total_cos;
      //remove other channels from the count
      if (state.channelInfo && state.channelInfo.globale) {
        let count =
          state.channelInfo.globale.count -
          state.channelInfo.borderfree.count -
          state.channelInfo.flow.count;

        let conversion = (count / x) * 100;
        setCheckoutConversion(conversion.toFixed(2) + "%");
      }
    }
  }, [state.weeklyStatistics, state.channelInfo]);

  useEffect(() => {
    let paceAlert = state.alerts[StateManager.Alerts.PACE];
    if (paceAlert && paceAlert.active) {
          setPaceAlertActive(true);
    }
    else {
      setPaceAlertActive(false);
    }
  },[state.alerts]);
  useEffect(() => {
    if (config.initiated) {
    
      if (config.enableGMV) {
      
         if (TOVAmount!==currentAmount){
             setShowTOVWidget(true);
         }
      }
    }

  },[config.initiated,TOVAmount,currentAmount]);
  const [dailyAverage, setDailyAverage] = useState(0);
  useEffect(() => {
   
    //get number of days until yesterday from the begining of the month
   
    //alert(state.atomicMonthly?.amountGMV);
    //gmvAmount
    //alert(state.dashboardStats.orders_info.gmvAmount)
    let amount = state.atomicMonthly?.amountGMV;
    let todayAmount = state.dashboardStats.orders_info.gmvAmount;

    if (amount && todayAmount) {
      let net_amount = amount - todayAmount;
      const startOfMonth = moment().startOf("month");
      const today = moment();
      const daysUntilToday = today.diff(startOfMonth, "days");
      let dailyAverage = net_amount / daysUntilToday;
    
      if (isFinite(dailyAverage)) {
      setDailyAverage(dailyAverage);
      }
      else {       
        setDailyAverage(0);
      }
      onAverageCalculated(dailyAverage);
    }

    if(config.enableGMV) {
        if (state.dashboardStats?.orders_info?.gmvAmount!==undefined && 
           state.dashboardStats?.orders_info?.gmvAmount!==0 &&
           state.channelInfo?.borderfree?.amount!==undefined){
            setCurrentAmount(state.dashboardStats?.orders_info?.gmvAmount + state.channelInfo?.borderfree?.amount);
            setTOVAmount(state.dashboardStats?.orders_info?.amount + state.channelInfo?.borderfree?.amount);
        }
        else{
            setCurrentAmount(state.dashboardStats?.orders_info?.amount);    
            setTOVAmount(state.dashboardStats?.orders_info?.amount);    
        }
    }
    else{
        setCurrentAmount(state.dashboardStats?.orders_info?.amount);
        setTOVAmount(state.dashboardStats?.orders_info?.amount);
    }
  }, [state.atomicMonthly?.amount, state.dashboardStats?.orders_info?.amount]);

  const isEstimationEnable = () => {
    let estimationEnabledState = state.getMenuItemLogicalState(
      StateManager.MenuKeys.DAILY_ESTIMATION
    );
    let isEstimationEnabled =
      estimationEnabledState == StateManager.MenuLogicalStates.ENABLED;
    return isEstimationEnabled;
  };
  useEffect(() => {
    let estimationEnabledState = state.getMenuItemLogicalState(
      StateManager.MenuKeys.DAILY_ESTIMATION
    );
    let isEstimationEnabled =
      estimationEnabledState == StateManager.MenuLogicalStates.ENABLED;
  }, [state.menuItems]);
  return (
    <GeneralBox
      dPadding="2"
      hasBorder={false}
      hasBackground={false}
      css={"gmv-inner-container"}
    >
      <div className="vstack gap-3">
        <div className="h1 dash-gmv-amount d-flex  align-items-end">
          <NumericFormat
            value={(currentAmount).toFixed(0)}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
          />
          <GMVPercentChange
            percentDiff={state.weeklyStatistics?.last_hour_comp}
          />
        </div>
        <div className="h3 dash-gmv-count ps-2 d-flex">
          <div className="d-flex">
            <NumericFormat
              value={(state.dashboardStats !== undefined
                ? state.dashboardStats.orders_info.count
                : 0
              ).toFixed(0)}
              displayType={"text"}
              thousandSeparator={true}
              prefix={""}
            />
            &nbsp;orders
          </div>
          <div
            className="d-flex checkout-net-conversion"
            data-visible={checkoutConversion !== null}
          >
            {checkoutConversion} CHECKOUT CVR
          </div>
          <div style={{ fontSize: "20px", marginLeft: "8px", display: "none" }}>
            <i className="bi bi-person"></i>
            <i className="bi bi-arrow-right"></i>
            <i className="bi bi-currency-dollar"></i>
          </div>
        </div>
        <div className="dv2-time">
          <i className="bi bi-clock"></i> {state.currentTime}
        </div>
        <div className="top-order-pace-container">
          <OrdersPaceInfo
            gaugeInfo={{ min: 0, max: 1000 }}
            isAlertActive={paceAlertActive}
            iconBG={"#FF9020"}
            icon={"bi-speedometer"}
          />
        </div>
        <div className="ps-2 d-flex flex-column estimation-container">
          <div
            data-hidden={!isEstimationEnable()}
            data-visible={isEstimationEnable()}
            className="d-flex justify-content-between align-items-center"
          >
            <div>
              {state.dailyEstimations?.estimation !== undefined ? (
                <div className=" d-flex align-items-center">
                  <span className="material-symbols-outlined estimation-icon">
                    monitoring
                  </span>
                  End of day estimation
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="dashed-line-container">
              <div className="dashed-line"></div>
            </div>
            <span className="estimation-number">
              {" "}
              ~{" "}
              <NumericFormat
                value={(state.dailyEstimations?.estimation!==undefined 
                  ? state.dailyEstimations.estimation
                  : 0
                ).toFixed(0)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            </span>
          </div>
          <div className="d-flex align-items-center justify-content-between" data-hidden={dailyAverage===0}>
            <div className="d-flex align-items-center">
              <span className="material-symbols-outlined estimation-icon">
                avg_pace
              </span>
              Daily average
            </div>
              <div  className="dashed-line-container">
              <div className="dashed-line"></div>
              </div>
            <span className="highlight-amount" style={{ marginLeft: "8px" }}>
              {" "}
              <NumericFormat
                value={dailyAverage.toFixed(0)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            </span>
          </div>
          <div data-hidden={!showTOVWidget} className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <span className="material-symbols-outlined estimation-icon">
                paid
              </span>
              Transactions Amount
            </div>
            <div  className="dashed-line-container">
              <div className="dashed-line"></div>
              </div>
            <span className="highlight-amount" style={{ marginLeft: "8px" }}>
              {" "}
              <NumericFormat
                value={TOVAmount.toFixed(0)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            </span>
          </div>          
        </div>
        <div className="ps-2">
          <TopCountries
            totalOrdersAmount={
              state.dashboardData !== undefined
                ? state.dashboardData?.today?.amount
                : 0
            }
            topCountries={state?.dashboardData?.top_countries}
          />
        </div>
      </div>
    </GeneralBox>
  );
});

export default GmvBox;
