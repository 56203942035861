import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import ChannelIndicator from "./ChannelIndicator";
import moment from "moment";
const MerchantRowTotals = ({ totals }) => {
 
 const [mountTime, setMountTime] = useState(moment().format("HH:mm:ss"));
  const [visible, setVisible] = useState(false);
  const itemHeight = 32;
 
  useEffect(() => {
    //get current time in HH:MM using moment
    const currentTime = moment().format("HH:mm:ss");
    //console.log("MerchantRowTotals mounted at " + currentTime);
    setMountTime(currentTime);
    console.log("MerchantRowTotals totals: ", totals);

  }, [totals]);
 

 

  return (
    <div
      className={"d-flex dash-merchant-row-total"}
    > 
      <div className="d-flex flex-grow-1 align-items-center" style={{paddingLeft:"15px", fontWeight:"bold", color:"white"}}>
        Total (<span className="mrt-mountime">as of {mountTime}</span>)
      </div>
      <div className="d-flex text-end justify-content-end  dash-amount-container">
        <NumericFormat
          value={totals.sum.toFixed(0)}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$"}
        />
      </div>
      <div
        className="d-flex text-end justify-content-center  dash-ocount-container"
        style={{ color: "#5BA2E3" }}
      >
        <NumericFormat
          value={totals.orders}
          displayType={"text"}
          thousandSeparator={true}
          prefix={""}
        />
      </div>
      <div
        className="d-flex text-end justify-content-end pe-4 dash-aov-container"
        style={{ width: "60px" }}
      >
        <span className="dash-aov">
          ${(totals.avg).toFixed(0)}
        </span>
      </div>
    </div>
  );
};

export default MerchantRowTotals;
