const ConnectionError = () => {
  return (
    <div className="error-container">
      <div>
        <span className="error-loader"></span>
      </div>
      <div className="error-title">WAITING FOR CONNECTION</div>
      <div className="error-reconnect">
        <div className="error-reconnect-text">
        The dashboard will be loaded once the connection to the service is
        restored
        </div>
      </div>
    </div>
  );
};
export default ConnectionError;
