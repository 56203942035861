import { observer } from "mobx-react-lite";
import { state, StateManager } from "../../state/stateManager";
import { useEffect, useRef, useState } from "react";
import parse from "html-react-parser";
import {config} from "../../state/config";

const InsightsViewer = observer(({ insights }) => {
  const [list, setList] = useState([]);
  // State to track the current message index
  const [currentIndex, setCurrentIndex] = useState(-1);
  const interval = 15000; //6000
  const divRef = useRef();
  // State to store the current message to display
  const [currentMessage, setCurrentMessage] = useState({ text: "", icon: "" });
  const [listInitialized, setListInitialized] = useState(false);
  const [insightsEnabled, setInsightsEnabled] = useState(true);
  const [messageType, setMessageType] = useState("text");
  // Ref to store the interval ID
  const intervalRef = useRef(null);

  // Effect for setting up the interval
  useEffect(() => {
    if (listInitialized) {
      setCurrentIndex(0);
      intervalRef.current = setInterval(() => {
        if (list.length > 0) {
          setCurrentIndex((prevIndex) => {
            const nextIndex = (prevIndex + 1) % list.length;
            return nextIndex;
          });
        }
      }, interval);

      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      };
    }
  }, [listInitialized]);

  // Effect for updating the current message when messages or currentIndex changes
  useEffect(() => {
    if (list.length > 0) {
      if (divRef.current) {
        divRef.current.style.opacity = 0;
      }
      setTimeout(() => {
        if (divRef.current) {
          divRef.current.style.opacity = 1;
        }
        setCurrentMessage(list[currentIndex]);
      }, Math.min(interval / 3, 2000));
    }
  }, [currentIndex]);

  useEffect(() => {
    if (state.insightsData) {
      let arr = Object.values(state.insightsData);
      setListInitialized(true);
      setList(arr);
    }
  }, [state.insightsData]);

  useEffect(() => {
    let insightsStyleState = state.getMenuItemLogicalState(
      StateManager.MenuKeys.INSIGHTS_STYLE
    );
    let insightsStyleStateEnabled =
      insightsStyleState == StateManager.MenuLogicalStates.ENABLED;
    if (insightsStyleStateEnabled) {
      setMessageType("text");
    } else {
      setMessageType("system_text");
    }
  }, [state.menuItems]);
  const systemMessagesClicked = () => {
    
  };
  useEffect(() => {
    let insightsState = state.getMenuItemLogicalState(
      StateManager.MenuKeys.INSIGHTS_ENABLE
    );
    let isInsightsEnabled =
      insightsState == StateManager.MenuLogicalStates.ENABLED;
    setInsightsEnabled(isInsightsEnabled);
  }, [state.menuItems]);


  const getInsightsBottom = () => {
      if (config.initiated && config.enableGMV) {
        return "30px";
      }
      else
      {
           if (state.getMenuItemLogicalState(
            StateManager.MenuKeys.DAILY_ESTIMATION
          ) == StateManager.MenuLogicalStates.ENABLED) {
            return "85px";
          } else {
            return "125px";
          }
      }
  }
  return (
    <div
      ref={divRef}
      data-enabled={insightsEnabled}
      style={{bottom:getInsightsBottom()}}
      className="d-flex dv2-insight-container flex-wrap"
    >
      <div
        className="flex-grow-1"
        style={{ minWidth: "75px", display: "none" }}
      >
        {currentMessage.icon ? (
          <img alt="alt" width={64} src={`/icons/${currentMessage.icon}`} />
        ) : (
          ""
        )}
      </div>
      <div className="flex-grow-1">
        {" "}
        {currentMessage &&
          currentMessage[messageType] &&
          parse(currentMessage[messageType])}
      </div>
      <div className="flex-grow-1 ai-warning">
        <i className="bi bi-asterisk"></i>&nbsp; some insights are AI generated,
        please report any wierdness.
      </div>
    </div>
  );
});

export default InsightsViewer;
