import React, {useEffect, useState}  from 'react';
import GeneralBox from '../general/GeneralBox';
import { NumericFormat } from 'react-number-format';
import GaugeComponent from 'react-gauge-component'
import { observer } from "mobx-react-lite"
import {state} from "../../state/stateManager"
//state.dashboardStats.checkout.count
const SessionsInfo = observer(({gaugeInfo,title, property, iconsCss = null, isFullScreen = false, percent})=> { 
    const [value, setValue] = useState();
    const [percDiff, setPercDiff] = useState(null);
    const arc = {        
        colorArray:["#00FF15", "#FF2121"],
        padding : 0.02,
        width : 0.2,
        subArcs : [
            {limit : 40},
            {limit : 60},
            {limit : 70},{},{},{},{}
        ]
    }
 
    const labels = {
        tickLabels : {
            hideMinMax : true
        }
    } 

    const wh = "100px";
    useEffect(()=> {
 
        if (percent) {  
            setPercDiff(percent + "%");
        }
    },[percent]);
    useEffect(()=> {
        if (gaugeInfo) {           
           let percentage = state.dashboardStats[property].count/gaugeInfo.max*100;
           setValue(Math.trunc(percentage));          
        }
    },[state?.dashboardStats[property]?.count])
    return (
        <GeneralBox dPadding="2" hasBorder={false} hasBackground={false} css={"max-400 gauges-mobile-container gauges-container"}>
             <div className='dash-sessioninfo-root d-flex justify-content-start'>
                <div>
                <GaugeComponent value={value}  className='rotated-gauge align-self-start' labels={labels} type='semicircle' style={{ height :wh, width:wh}} arc={arc} /> 
                </div>
                <div className='dash-sessioninfo-content'>
                 {iconsCss?<i className={iconsCss}></i>:""} {title}<br />
                    <span className='dash-sessioninfo-number d-flex'>
                      <NumericFormat value={state.dashboardStats[property].count} displayType={'text'} thousandSeparator={true} prefix={''} />
                      <div className='d-flex si-perc-diff' data-visible={percDiff!=null} data-positive={percent>1.9}>
                        {percDiff} BROWSING CVR
                      </div>
                    </span>
                </div>
             </div>
        </GeneralBox>
      )
})

export default SessionsInfo