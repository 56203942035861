import * as React from "react";

const LayoutTests = (props) => {
  return (
    <div className="test-dashboard-container" style={{ padding: "20px" }}>
      <div className="d-flex flex-wrap justify-content-between" id="row1">
        <div
          className="mobile-box flex-grow-1  align-items-end flex-column"
          id="column-left"
        >
          <div className="d-flex test-box-big  mobile-box justify-content-center">
            <img alt="test" src="/stub/orders.jpg" />
          </div>
          <div className="test-globe-container d-flex justify-content-center">
            <div className="test-glob align-self-center"></div>
          </div>
          <div className="test-pie-container  d-flex justify-content-start flex-wrap">
            <div className="test-pie d-flex">
              <img alt="test" src="/stub/donut-chart.png" />
            </div>

            <div className="test-pie  d-flex">
              <img alt="test" src="/stub/donut-chart.png" />
            </div>

            <div className="test-pie d-flex ">
              <img alt="test" src="/stub/donut-chart.png" />
            </div>
          </div>

          <div className="d-flex flex-wrap">
            <div className="test-box-big test-box-bigger mobile-box">
              &nbsp;
            </div>
            <div
              className="d-flex mobile-box align-items-end flex-column test-graphs-container"
              id="graphsContainer"
            >
              <div className="test-graph">&nbsp;</div>
              <div className="test-graph">&nbsp;</div>
              <div className="test-graph">&nbsp;</div>
            </div>
          </div>
        </div>

        <div
          className="mobile-box d-flex align-items-end flex-column  "
          id="column-right"
        >
          <div className="test-right-box"></div>
          <div className="test-right-box"></div>
          <div className="test-right-box"></div>
          <div className="test-right-bigbox"></div>
          <div className="test-right-box"></div>
          <div className="test-right-box"></div>
          <div className="test-right-box"></div>
        </div>
      </div>
    </div>
  );
};

export default LayoutTests;
