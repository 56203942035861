import React, { useEffect,useState }  from 'react';
import GeneralBox from '../general/GeneralBox';
import { NumericFormat } from 'react-number-format';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { observer } from "mobx-react-lite"
import {state} from "../../state/stateManager"

//state.dashboardData ? state.dashboardData.today.platforms : []
const TopPlatforms = observer(({onExpand})=> {
    const [isExpended , setIsExpended] = useState(true);
    useEffect(()=> {
 
    },[])
    const onToggle=()=>
    {
        const newStatus = !isExpended;       
        setIsExpended(newStatus);     
        setTimeout(()=> {
            onExpand(newStatus);
        },100);
    }
    return (
        <GeneralBox dPadding="2" hasBorder={false} hasBackground={true} css="mb-2">
             <div className='d-flex'>
                <div className='dash-tophubs-content p-4 flex-grow-1'>
                <div className='d-flex justify-content-between'>
                        <div className='dash-side-header'>TOP PLATFORMS</div> 
                        <div onClick={onToggle}  className='align-self-center top-expend'>{isExpended?"collapse":"expend"} <i className="bi bi-chevron-right"></i></div>
                    </div>
                  
                    <div className="flex-column hubs-list gap-1 h-100 pt-1  p-4" style={{fontSize : "13px", display :isExpended?"block":"none"}}>                     
                        <Scrollbars key={"platforms_scroller"}
                            renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}      
                            renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}     
                            style={{height: "260px"}}>
             
                            {state.dashboardData && state.dashboardData?.today?.platforms.map((item, key)=>
                                             <div key={key} className='dash-merchant-row justify-content-between'>                 
                                                    <div className=''>
                                                     <div title={item.platform} style={{textOverflow: "ellipsis", overflow:"hidden", whiteSpace :"nowrap"}}> {item.platform}</div>
                                                                                                   
                                                    </div>
                                                <div className='text-end pe-4'>
                                                    <NumericFormat value={item.count} displayType={'text'} thousandSeparator={true} prefix={''} /> 
                                                </div>
                                                
                                                </div>
                            
                            )}   
    
               
             
                </Scrollbars>

                    </div>
                  
                   

                </div>
             </div>
        </GeneralBox>
      )
})

export default TopPlatforms