import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import SimpleLIneChart from "../common/SimpleLIneChart";
import { NumericFormat } from "react-number-format";
import { state, StateManager } from "../../state/stateManager";
import moment from "moment";
import ComponentError from "../ComponentError";

const momenttz = require("moment-timezone");
const StockChart = observer(
  ({
    color = "rgba(0, 255, 21, 1)",
    icon,
    title,
    AxisYModulo = 10000,
    AxisYReduction = 1000,
    Suffix = "K",
    extendYLabel = false,
    visible = true,
  }) => {
    const [lastNumber, setLastNumber] = useState(null);

    const [percentageDiff, setPercentageDiff] = useState("");
    const [prepostData, setPrepostData] = useState({
      time: new Date().toDateString(),
      price: 0,
      change: 0,
      default: true,
      mode: "default",
    });
    const [isLoadSuccess, setIsLoadSuccess] = useState(false);
    useEffect(() => {
      const isSuccess = state.isStreamSuccess(
        StateManager.Keys.StockGraph,
        StateManager.Keys.StockInfo
      );

      setIsLoadSuccess(isSuccess);
    }, [state.failedToLoad]);

    useEffect(() => {
      //get last hour numbers
      if (state.stockInfo) {
        if (state.stockInfo.postMarketPrice) {
          //let time = moment(state.stockInfo.postMarketTime).tz("America/New_York")

          let data = {
            time: moment(state.stockInfo.postMarketTime).format(
              "DD/MM/YYYY HH:MM"
            ),
            price: state.stockInfo.postMarketPrice.toFixed(2),
            change: state.stockInfo.postMarketChangePercent,
            default: false,
            mode: "post",
          };
          setPrepostData(data);
          //set post info
        } else if (state.stockInfo.preMarketPrice) {
          //set regular info
          let data = {
            time: moment(state.stockInfo.preMarketTime).format(
              "DD/MM/YYYY HH:MM"
            ),
            price: state.stockInfo.preMarketPrice.toFixed(2),
            change: state.stockInfo.preMarketChangePercent,
            default: false,
            mode: "pre",
          };
          setPrepostData(data);
        }
      }
    }, [state.stockInfo]);
    const getValue = (val, defaultValue = 0) => {
      if (!val) return defaultValue;
      return val;
    };
    return (
      <div
        data-visible={visible}
        className="box-template stock-widget-box d-flex dash-box flex-grow-1"
        style={{ minWidth: "300", fontSize: "20px", position: "relative" }}
      >
        {isLoadSuccess ? (
          <div className="stock-widget-inner d-flex">
            <div className="stock-title flex-wrap d-flex  flex-grow-0">
              <i className={"rt-icon bi bi-bar-chart-fill"}></i>{" "}
              <span style={{ marginRight: "10px" }}>{title}</span> (
              <span className="highlight-amount">
                {" "}
                {state.stockGraph && state.stockGraph.snapshotTime}
              </span>
              )
            </div>
            <div className="d-flex stock-details flex-wrap">
              <div className="d-flex stock-info-left" style={{ width: "50%" }}>
                <div className="stock-details-row d-flex flex-wrap">
                  <div className="d-flex stock-details-label">Open</div>
                  <div className="d-flex stock-details-value">
                    {state.stockInfo &&
                      state.stockInfo?.regularMarketOpen?.toFixed(2)}
                  </div>
                </div>
                <div className="stock-details-row d-flex flex-wrap">
                  <div className="d-flex stock-details-label">
                    Previous Close
                  </div>
                  <div className="d-flex stock-details-value">
                    {state.stockInfo &&
                      state.stockInfo?.regularMarketPreviousClose?.toFixed(2)}
                  </div>
                </div>
                <div className="stock-details-row d-flex flex-wrap">
                  <div className="d-flex stock-details-label">Volume</div>
                  <div className="d-flex stock-details-value">
                    <NumericFormat
                      value={
                        state.stockInfo &&
                        state.stockInfo?.regularMarketVolume?.toFixed(0)
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </div>
                </div>
                <div className="stock-details-row d-flex flex-wrap">
                  <div className="d-flex stock-details-label">Market Cap</div>
                  <div className="d-flex stock-details-value">
                    <NumericFormat
                      value={(
                        (state.stockInfo && state.stockInfo?.marketCap) ??
                        0 / 1000000000
                      ).toFixed(2)}
                      prefix={"$"}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                    B
                  </div>
                </div>
              </div>

              <div
                className="d-flex stock-info-right"
                style={{ width: "50%" }}
                data-visible={prepostData.default === false}
              >
                <div className="stock-details-row d-flex flex-wrap">
                  <div className="d-flex stock-details-label">Time</div>
                  <div className="d-flex stock-details-value">
                    {prepostData.time}
                  </div>
                </div>
                <div className="stock-details-row d-flex flex-wrap">
                  <div className="d-flex stock-details-label">
                    {prepostData.mode === "post" ? "After Hours" : "Pre Market"}
                  </div>
                  <div className="d-flex stock-details-value">
                    <span className="prepost-price"> {prepostData.price}</span>
                    <span
                      className="prepost-change d-flex"
                      data-positive={prepostData.price > 0}
                    >
                      <i
                        className={
                          "bi " +
                          (prepostData.change >= 0
                            ? "bi-caret-up-fill"
                            : "bi-caret-down-fill")
                        }
                      ></i>
                      {prepostData.change.toFixed(2)}%
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column flex-grow-1 w-100">
              <div className="d-flex  flex-grow-1 stock-graph-container">
                <SimpleLIneChart
                  stockinfo={state.stockInfo}
                  showBars={true}
                  todayColor={color}
                  pointRadius={0}
                  extendYLabel={extendYLabel}
                  AxisYModulo={AxisYModulo}
                  AxisYReduction={AxisYReduction}
                  width={"95%"}
                  Suffix={Suffix}
                  AxisXCaption="Hours"
                  data={state.stockGraph}
                />
              </div>

              <div className="stock-price d-flex  align-items-end">
                <div
                  className="stock-price-perc-diff"
                  data-positive={
                    state.stockInfo?.regularMarketChangePercent >= 0
                  }
                >
                  <i
                    className={
                      "bi " +
                      (state.stockInfo?.regularMarketChangePercent >= 0
                        ? "bi-caret-up-fill"
                        : "bi-caret-down-fill")
                    }
                  ></i>
                  {state.stockInfo &&
                    state.stockInfo?.regularMarketChangePercent?.toFixed(2)}
                  %
                </div>
                <div
                  className="stock-price-num h1"
                  style={{ lineHeight: "100%" }}
                >
                  {state.stockInfo && state.stockInfo?.price?.toFixed(2)}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <ComponentError componentKey={"Stock Chart"} />
        )}
      </div>
    );
  }
);

export default StockChart;
