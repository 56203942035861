import React from 'react';
import GlobeX from './components/Globe';

const ChartsPG = (props)=> {
        
    return (<div className='d-flex flex-wrap w-100' style={{ maxWidth: "1500px", border:"1px solid #fff", height: "700px", overflow:"hidden" }}>
                <GlobeX />
        </div>

)
}

export default ChartsPG