import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  registerables as registerablesJS,
} from "chart.js";
import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(...registerablesJS);
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const SimpleLIneChart = ({
  stockinfo,
  pointRadius = 3,
  data,
  AxisYModulo,
  AxisYReduction,
  AxisYCaption,
  AxisXCaption,
  Suffix = "",
  width = "560px",
  multipleKeys,
  property,
  extendYLabel = false,
  todayColor = "#189ad3",
  showBars = false,
}) => {
  const [datas, setDatas] = useState({ labels: [], datasets: [] });
  useEffect(() => {
    if (data) {
      try {
        if (data && stockinfo) {
          //[{hour: '16:30', quote: '39.00'}]
          //const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
          const labels = data.result.map((item) => item.hour.toString());
          const dataSource = {
            labels,
            datasets: getDataSets(data, stockinfo.regularMarketOpen),
          };

          setDatas(dataSource);
        }
      } catch (err) {
        console.log(err);
      }
    }
  }, [data, stockinfo]);

  const getDataset = (data, color) => {
    let dataset = {
      pointRadius: 0,
      label: "Dataset 1",
      data: data,
      fill: true,
      borderColor: color,
      backgroundColor:
        color == "red" ? "rgba(255, 0, 0,0.2)" : "rgba(0, 255, 21, 0.2)",
      borderWidth: 3,
    };
    return dataset;
  };

  const getDataSets = (data, marketOpen) => {
    let result = [];
    let startQuote = marketOpen;
    let swithDirection = false;
    let dataRates = [];
    let positiveArray = [];
    let negativeArray = [];
    let rangeStartIndex = 0;
    let rangeEndIndex = 0;
    for (var i = 0; i < data.result.length; i++) {
      let item = data.result[i];
      let label = item.hour;
      let quote = item.quote;
      if (quote >= startQuote) {
        //positive
        if (negativeArray.length > 0) {
          //switch, create a dataset for the negative array
          let dataset = getDataset(
            getArrayFromIndex(
              negativeArray,
              rangeStartIndex,
              i,
              data.result.length,
              data
            ),
            "red"
          );
          result.push(dataset);
          negativeArray = [];
          rangeStartIndex = i;
        }

        positiveArray.push(quote);
      } else {
        //negative
        if (positiveArray.length > 0) {
          //switch, create a dataset for the positive array
          let dataset = getDataset(
            getArrayFromIndex(
              positiveArray,
              rangeStartIndex,
              i,
              data.result.length,
              data
            ),
            "green"
          );
          result.push(dataset);
          positiveArray = [];
          rangeStartIndex = i;
        }

        negativeArray.push(quote);
      }
    }

    //handle leftovers
    if (positiveArray.length > 0) {
      let dataset = getDataset(
        getArrayFromIndex(
          positiveArray,
          rangeStartIndex,
          data.result.length - 1,
          data.result.length,
          data
        ),
        "green"
      );
      result.push(dataset);
    }

    if (negativeArray.length > 0) {
      let dataset = getDataset(
        getArrayFromIndex(
          negativeArray,
          rangeStartIndex,
          data.result.length - 1,
          data.result.length,
          data
        ),
        "red"
      );
      result.push(dataset);
    }

    return result;
  };

  const getArrayFromIndex = (
    arr,
    rangeStartIndex,
    rangeEndIndex,
    fullLength,
    data
  ) => {
    //debugger;
    let result = [];
    for (var i = 0; i < fullLength; i++) {
      if (i !== 0 && i === rangeStartIndex - 1) {
        result.push(data.result[rangeStartIndex - 1].quote);
      } else if (i >= rangeStartIndex && i <= rangeEndIndex) {
        result.push(arr[i - rangeStartIndex]);
      } else {
        result.push(null);
      }
    }

    return result;
  };

  const options = {
    animation: {
      duration: 0,
    },
    maintainAspectRatio: false,
    responsive: true,
    interaction: {
      mode: "nearest",
      intersect: false,
    },
    plugins: {
      tooltip: {
        titleColor: "#fff",
        callbacks: {
          label: function (tooltipItem, data) {
            // Return the value only, without the dataset name
            return tooltipItem.formattedValue;
          },
        },
      },

      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: true,
        //text: 'Chart.js Line Chart',
      },
    },
    scales: {
      y: {
        display: true,
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: AxisYCaption,
          color: "#fff",
        },
        ticks: {
          callback: function (value, index, values) {
            // Display every 2nd label
            if (value) {
              return value.toFixed(2);
            }
          },
        },
      },
      x: {
        type: "category",
        display: false,
        grid: {
          display: false,
        },
      },
      yAxes: {
        display: false,
        grid: {
          display: false,
        },
      },
      xAxes: {
        display: true,
        grid: {
          display: false,
        },
        type: "category",
        title: {
          display: false,
          text: AxisXCaption,
          color: "#fff",
        },
        ticks: {
          callback: function (value, index, values) {
            // Display every 2nd label
            let hour = this.getLabelForValue(value);
            if (hour != null && hour != undefined)
              return hour.endsWith(":00") ? hour : null;
          },
        },
      },
    },
  };

  const colors = [
    "rgba(139, 148, 240, 0.20)",
    "rgba(148, 240, 139, 0.20)",
    "rgba(240, 239, 139, 0.20)",
    "rgba(237, 139, 240, 0.20)",
    "rgba(240, 155, 139, 0.20)",
    "rgba(240, 197, 139, 0.20)",
    "rgba(0, 255, 21, 1)",
  ];

  return (
    <div style={{ width: width }}>
      <Line height={220} options={options} data={datas} />
    </div>
  );
};

export default SimpleLIneChart;
