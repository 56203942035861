import * as React from "react";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
const inactiveColor = encodeURIComponent("#01F715ff");
const activeColor = encodeURIComponent("#8796A5");
const path =
  "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2m5 11h-4v4h-2v-4H7v-2h4V7h2v4h4z";
const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 54,
  height: 30,
  padding: 6,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 2,
    transform: "translateX(1px)",

    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="32" width="32" viewBox="0 0 24 24"><path fill="${inactiveColor}" d="${path}"/></svg>')`,
      },
      "& + .MuiSwitch-thumb": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#01F71500" : "#01F71500",
    width: 24,
    boxShadow: "none",
    height: 24,
    "&::before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="32" width="32" viewBox="0 0 24 24"><path fill="${activeColor}" d="${path}"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#68686F" : "#68686F",
    borderRadius: 20 / 2,
  },
}));

const ZoomSwitch = ({ onChange, label, icon = "", checked = false }) => {
  return (
    <Tooltip title="Enable/Disable zoom">
      <MaterialUISwitch onChange={onChange} defaultChecked={checked} />
    </Tooltip>
  );
};
export default ZoomSwitch;
