import React, { useEffect, useState } from 'react';
export default function GeneralBox(props) {
  const [css, setCss] = useState("general-box pb-2 pt-2");
 
  useEffect(() => {        
      let customCss = "general-box pb-2 pt-2";
      if (props.hasBorder) {
        customCss += " border "
      }

      if (props.hasBackground) {
        customCss += " general-box-color "
      }

      if (props.dPadding) {
        customCss+=" p-" + props.dPadding;
      }
      if (props.css) {  
        customCss+= " " + props.css;
      }
      
      setCss(customCss);
  
  }, [props]);
  return (
    <div className={css} style={{minHeight : props.minHeight!==undefined?props.minHeight:100}}>
        {props.children}
    </div>
  )
}
