import { useEffect, useState, useRef } from "react";
import release_notes from "../../assets/release_notes.json";
import { Scrollbars } from "react-custom-scrollbars-2";
import moment from "moment";
const ReleaseNotes = () => {
  const [version, setVersion] = useState(null);
  const [versionDate, setVersionDate] = useState(null); //release_notes.versions[release_notes.currentVersion].date
  const [changes, setChanges] = useState([]);
  const [fixes, setFixes] = useState([]);
  const [generals, setGenerals] = useState([]);
  const [comingSoon, setComingSoon] = useState([]); //release_notes.versions[release_notes.currentVersion].comingSoon
  const [enabled, setEnabled] = useState(false);
  const [dismissIn, setDismissIn] = useState(60); //seconds
  const timerRef = useRef(null);
  const dismiss_timeout = 60; //seconds
  useEffect(() => {
    if (release_notes.currentVersion) {
      //first if release notes were already displayed, check the sessionStorage
      let stored_release_notes = localStorage.getItem("dv3_release_notes");
      if (stored_release_notes) {
        //check if the version is the same
        if (release_notes.currentVersion == stored_release_notes) return;
      }
 
      if (release_notes.versions[release_notes.currentVersion]) {
        //check release_notes.versions[release_notes.currentVersion].date if it's older then 2 days, don't show
        let date = moment(
          release_notes.versions[release_notes.currentVersion].date
        , "DD-MM-YYYY");
        let now = moment();
        let diff = now.diff(date, "days");
       
        if (diff > 2)  {
          localStorage.setItem("dv3_release_notes", release_notes.currentVersion);
          return;
        }

        setVersion(release_notes.currentVersion);
        setVersionDate(
          release_notes.versions[release_notes.currentVersion].date
        );
        setChanges(
          release_notes.versions[release_notes.currentVersion].changes ?? []
        );

        setFixes(
          release_notes.versions[release_notes.currentVersion].fixes ?? []
        );
        setGenerals(
          release_notes.versions[release_notes.currentVersion].general ?? []
        );

        setComingSoon(
          release_notes.versions[release_notes.currentVersion].comingSoon ?? []
        );
      }
      setEnabled(true);
      autoDismissIn(dismiss_timeout);
      // const timeout = setTimeout(() => {
      //    // onDismiss();
      // },0);
      return () => {
        if (timerRef.current) clearTimeout(timerRef.current);
      };
    }
  }, []);

  const autoDismissIn = (numSeconds) => {
    setDismissIn(numSeconds);
    timerRef.current = setTimeout(() => {
      if (numSeconds > 0) {
        autoDismissIn(numSeconds - 1);
      } else {
        onDismiss();
      }
    }, 1000);
  };

  const onDismiss = () => {
    //store current version in session storage
    localStorage.setItem("dv3_release_notes", release_notes.currentVersion);
    setEnabled(false);
  };
  return enabled ? (
    <div className="d-flex flex-column release-notes-container">
      <div className="d-flex flex-column release-notes-box">
        <div className="close-release-notes" onClick={onDismiss}>
          <i className="bi bi-x-circle-fill"></i>
        </div>
        <div className="release-notes-title">
          {" "}
          V{version} Release notes -- {versionDate}{" "}
        </div>

        <Scrollbars
          key={"merchants_scroller"}
          className="release-notes-scroll d-flex flex-grow-1"
          renderTrackHorizontal={(props) => (
            <div {...props} className="track-horizontal" />
          )}
          renderThumbVertical={(props) => (
            <div {...props} className="thumb-vertical" />
          )}
        >
          <div className="d-flex flex-column release-notes-changes">
            {generals.length > 0 ? (
              <>
                <div className="release-note-header">general:</div>
                {generals.map((change, index) => {
                  return (
                    <div key={index} className="d-flex flex-wrap release-note">
                      <div className="d-flex">
                        {" "}
                        <i  className="bi bi-info-circle-fill change-icon"></i> 
                        <div dangerouslySetInnerHTML={{__html : change}}></div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : null}

            {changes.length > 0 ? (
              <>
                <div className="release-note-header">changes:</div>
                {changes.map((change, index) => {
                  return (
                    <div key={index} className="d-flex flex-wrap release-note">
                      <div className="d-flex">
                        {" "}
                        <i className="bi bi-plus-circle-fill change-icon"></i>
                        <div dangerouslySetInnerHTML={{__html : change}} ></div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : null}

            {fixes.length > 0 ? (
              <>
                <div className="release-note-header">bug fixes:</div>
                {fixes.map((change, index) => {
                  return (
                    <div key={index} className="d-flex flex-wrap release-note">
                      <div className="d-flex">
                        {" "}
                        <i className="bi bi-bug-fill change-icon"></i> 
                        <div dangerouslySetInnerHTML={{__html : change}} ></div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : null}

            {comingSoon.length > 0 ? (
              <>
                <div className="release-note-header">coming soon:</div>
                {comingSoon.map((change, index) => {
                  return (
                    <div key={index} className="d-flex flex-wrap release-note">
                      <div className="d-flex">
                        {" "}
                        <span className="change-icon material-symbols-outlined">
                          more_time
                        </span>{" "}
                        {change}{" "}
                      </div>
                    </div>
                  );
                })}
              </>
            ) : null}
          </div>
        </Scrollbars>
        <div className="release-note-dismiss">
          This windows will automatically close in {dismissIn} seconds
        </div>
      </div>
    </div>
  ) : null;
};
export default ReleaseNotes;
