import { useEffect, useState } from "react";
import fetcher from "../../lib/api-fetcher";
import { useNavigate } from "react-router-dom";
const AdminContainer = ({ children }) => {
  const navigate = useNavigate();
  const [authenticated, setAuthenticated] = useState(false);
  const [ready, setReady] = useState(false);
  useEffect(() => {
    //verify admin logged in
    fetcher.userstate().then((data) => {
      setAuthenticated(data.admin);
      setReady(true);
    });
  }, []);
  const goBack = () => {
    //onClose();
    //window.history.back();
  };
  const onAuthenticate = () => {
    navigate(0); //reload
  };

  return (
    <div className="login-container">
      {!authenticated ? (
        <div className="login-required-message">
          {ready ? "Admin permissions required to view this page" : ""}
        </div>
      ) : (
        children
      )}
    </div>
  );
};

export default AdminContainer;
