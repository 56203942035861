import "./App.css";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ChartsPG from "./ChartsPlayground";
import LayoutTests from "./LayoutTests";
import DashboardRootV3 from "./components/DashboardRootV3";
import ConfigEditor from "./components/boxes/ConfigEdiror";
import ReleaseNotesPage from "./components/ReleaseNotesPage";
import Tests from "./components/Tests";
import Admin from "./components/Admin";
import MerchantsFrame from "./components/boxes/MerchantsFrame";
import UserManagement from "./components/Admin/UserManagement";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route>
          <Route index element={<DashboardRootV3 />} />
          <Route path="charts" element={<ChartsPG />} />
          <Route path="layout" element={<LayoutTests />} />
          <Route path="config" element={<ConfigEditor />} />
          <Route path="merchants" element={<MerchantsFrame />} />
          <Route path="tests" element={<Tests />} />
          <Route path="admin" element={<Admin />} />
          <Route path="users" element={<UserManagement />} />
          <Route path="releasenotes" element={<ReleaseNotesPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
