//import { JsonEditor as Editor } from "jsoneditor-react";
//import "jsoneditor-react/es/editor.min.css";
import { useEffect, useState, useRef } from "react";
import { observer } from "mobx-react-lite";
import { config } from "../../state/config";
import { useNavigate } from "react-router-dom";
import AdminContainer from "./AdminContainer";
import {
  JSONEditor,
  renderValue,
  renderJSONSchemaEnum,
} from "vanilla-jsoneditor/standalone.js";
import schema from "../../assets/config_schema.json";
import AdminLogin from "./AdminLogin";
const ConfigEditor = observer(({ onClose, onLoad }) => {
  const [editor, setEditor] = useState(null);
  const navigate = useNavigate();
  const editorRef = useRef(null);
  const [jsonData, setJsonData] = useState(null);
  const [editMode, setEditMode] = useState("config");
  const updatedJson = useRef(null);
  const [showSaved, setShowSaved] = useState(false);
  const [ready, setReady] = useState(false);
  const [clientIp, setClientIp] = useState(null);
  const disabled = true;
  const [authenticated, setAuthenticated] = useState(false);
  useEffect(() => {
    loadConfig();
  }, [config.initiated]);

  const loadConfig = () => {
    config.loadConfig((data) => {
      if (data.authenticated) {
        setReady(true);
        setAuthenticated(true);

        setJsonData(data);
        //editor.set(json);
        setClientIp(data.clientIp);
        //setEditor(editor);

        if (editorRef.current) {
          // editorRef.current.expandAll();
        }
        setEditMode("config");
        onLoad();
      }
    });
  };
  useEffect(() => {
    if (authenticated && jsonData) {
      let editor = renderEditor(jsonData);
    }
  }, [authenticated, jsonData]);
  useEffect(() => {
    if (editorRef.current) {
      //editorRef.current.expandAll();
    }
  }, [editor]);
  const onAuthenticate = () => {
    navigate(0); //reload
  };

  const handleChange = (json) => {
    updatedJson.current = json;
  };

  const renderEditor = (data) => {
    //debugger;
    //return <Editor ref={editorRef} value={data} onChange={handleChange} />;
    let container = document.getElementById("editor-container");
    //console.log("container", container);
    const options = {
      mode: "tree", // Set the editor mode: 'tree', 'view', 'form', 'code', 'text'
      // Add more options as needed
    };
    //return new JSONEditor(container, options, data);
    document.getElementById("editor-container").innerHTML = "";
    editorRef.current = new JSONEditor({
      target: document.getElementById("editor-container"),
      props: {
        content: {
          json: data,
          text: undefined,
        },
        onClassName: (path) => {
          if (path) {
            const key = path[path.length - 1];
            if (
              key === "Initiated" ||
              key === "clientIp" ||
              key === "authenticated"
            ) {
              return "json-readonly";
            }
          }
        },
        onChange: (
          updatedContent,
          previousContent,
          { contentErrors, patchResult }
        ) => {
          let json = updatedContent.json;
          if (!json) {
            json = JSON.parse(updatedContent.text);
          }
          updatedJson.current = json;
        },
        onRenderValue: (props) => {
          return renderJSONSchemaEnum(props, schema, {}) || renderValue(props);
        },
      },
    });
  };

  const onSave = () => {
    let jsonToSave = updatedJson.current;

    if (!jsonToSave) {
      jsonToSave = editorRef.current.get().json;
    }

    if (editMode === "merchant_countries") {
      config.saveMerchantCountries(jsonToSave, () => {
        setShowSaved(true);
        setTimeout(() => {
          setShowSaved(false);
        }, 2000);
      });
    } else {
      config.saveConfig(jsonToSave, () => {
        //console.log("config saved");
        setShowSaved(true);
        setTimeout(() => {
          setShowSaved(false);
        }, 2000);
      });
    }
  };

  const goBack = () => {
    onClose();
    //window.history.back();
  };

  const editJson = () => {
    if (editMode === "config") {
      config.loadMerchantCountries((data) => {
        if (data.authenticated) {
          // setReady(true);
          // setAuthenticated(true);

          setJsonData(data);
          setEditMode("merchant_countries");
          // //editor.set(json);
          // setClientIp(data.clientIp);
          // //setEditor(editor);

          // if (editorRef.current) {
          //   // editorRef.current.expandAll();
          // }

          //onLoad();
        }
      });
    } else {
      loadConfig();
    }
  };
  return (
    <div className="d-flex flex-column dashboard-page">
      <br />
      <AdminContainer>
        {ready ? (
          <div>
            <button
              hidden={true}
              className="btn btn-primary"
              onClick={goBack}
              style={{ width: "200px", height: "60px", marginTop: "20px" }}
            >
              Close
            </button>
            <div style={{ marginTop: "-120px" }}>
              <div
                className="d-flex flex-wrap align-items-center"
                style={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  gap: "20px",
                  fontSize: "25px",
                  color: "lightgreen",
                }}
              >
                <button
                  onClick={onSave}
                  style={{ minWidth: "200px", height: "60px" }}
                  className="btn btn-success"
                >
                  Save - {editMode}
                </button>
                <button
                  onClick={editJson}
                  style={{ width: "200px", height: "60px" }}
                  className="btn btn-primary"
                >
                  {editMode === "config"
                    ? "Edit merchant countries"
                    : "Edit config"}
                </button>
                <div data-hidden={!showSaved}>SAVED!</div>
              </div>

              <div>client ip: {clientIp}</div>
              <div id="editor-container"></div>

              <div
                className="d-flex flex-wrap align-items-center"
                style={{
                  marginTop: "20px",
                  gap: "20px",
                  fontSize: "25px",
                  color: "lightgreen",
                }}
              >
                <button
                  onClick={onSave}
                  style={{ width: "200px", height: "60px" }}
                  className="btn btn-success"
                >
                  Save
                </button>
                <div data-hidden={!showSaved}>SAVED!</div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </AdminContainer>
    </div>
  );
});

export default ConfigEditor;
