import GeneralBox from "../general/GeneralBox";
import { NumericFormat } from "react-number-format";
import { observer } from "mobx-react-lite";
import { state, StateManager } from "../../state/stateManager";
import moment from "moment";
import { useEffect, useState } from "react";
import ComponentError from "../ComponentError";
import { config } from "../../state/config";
import { set } from "mobx";
const StockMiniWidget = observer(({ stock }) => {
  const [prepostData, setPrepostData] = useState({
    time: new Date().toDateString(),
    price: 0,
    change: 0,
    default: true,
    mode: "default",
  });
  const [isLoadSuccess, setIsLoadSuccess] = useState(false);
  const [customCss, setCustomCss] = useState("zero-opacity");
  useEffect(() => {
    const isSuccess = state.isStreamSuccess(StateManager.Keys.Stock);
    setIsLoadSuccess(isSuccess);
  }, [state.failedToLoad]);
  useEffect(() => {
    //get last hour numbers
    if (state.stockInfo) {
      if (!state.stockInfo.postMarketPrice && !state.stockInfo.preMarketPrice) {
        setPrepostData({
          time: new Date().toDateString(),
          price: 0,
          change: 0,
          default: true,
          mode: "default",
        });
        return;
      }
      if (state.stockInfo.postMarketPrice) {
        //let time = moment(state.stockInfo.postMarketTime).tz("America/New_York")

        let data = {
          time: moment(state.stockInfo.postMarketTime).format(
            "DD/MM/YYYY HH:MM"
          ),
          price: state.stockInfo.postMarketPrice,
          change: state.stockInfo.postMarketChangePercent,
          default: false,
          mode: "post",
        };
        setPrepostData(data);

        //set post info
      } else if (state.stockInfo.preMarketPrice) {
        //set regular info
        let data = {
          time: moment(state.stockInfo.preMarketTime).format(
            "DD/MM/YYYY HH:MM"
          ),
          price: state.stockInfo.preMarketPrice,
          change: state.stockInfo.preMarketChangePercent,
          default: false,
          mode: "pre",
        };
        setPrepostData(data);
      }
    }

    // console.log(state.stockInfo?.regularMarketChange);
  }, [state.stockInfo]);

  useEffect(() => {
    let stockEnabled = state.getMenuItemLogicalState(
      StateManager.MenuKeys.STOCK_INFO
    );
    let isStockEnabled = stockEnabled == StateManager.MenuLogicalStates.ENABLED;

    if (!isStockEnabled) {
      setCustomCss("zero-opacity");
    } else {
      setCustomCss("");
    }
  }, [state.menuItems]);
  return (
    <GeneralBox
      dPadding="2"
      hasBorder={true}
      hasBackground={true}
      css={"order-sales-info mb-2 stock-mini-container " + customCss}
    >
      {isLoadSuccess ? (
        <div className="d-flex" style={{ height: "100%" }}>
          <div className="dash-yesterday-content">
            <div className="d-flex flex-column" style={{ height: "100%" }}>
              <div className="d-flex dash-side-header">
                <i className="bi bi-cash-coin"></i>
                <div>GLBE</div>
              </div>

              <div className="stock-mini-price d-flex">
                <NumericFormat
                  value={state.stockInfo?.price?.toFixed(2)}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
                <span
                  data-positive={state.stockInfo?.regularMarketChange > 0}
                  className="stock-mini-change d-flex"
                >
                  <i
                    className={
                      "bi " +
                      (state.stockInfo?.regularMarketChange >= 0
                        ? "bi-caret-up-fill"
                        : "bi-caret-down-fill")
                    }
                  ></i>
                  {state.stockInfo?.regularMarketChangePercent?.toFixed(2)}%
                </span>
              </div>
              <div
                style={{
                  display: prepostData.mode !== "default" ? "flex" : "none",
                }}
                className="dash-yesterday-count"
              >
                <span className="prepost-title">{prepostData.mode}</span>
                <span>
                  {prepostData.price.toFixed(2)}{" "}
                  <span data-positive={prepostData.change >= 0}>
                    <i
                      className={
                        "bi " +
                        (prepostData.change >= 0
                          ? "bi-caret-up-fill"
                          : "bi-caret-down-fill")
                      }
                    ></i>
                    {prepostData.change.toFixed(2)}%
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <ComponentError componentKey={"Stock info"} />
      )}
    </GeneralBox>
  );
});

export default StockMiniWidget;
