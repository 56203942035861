import { useEffect, useState } from "react";

const DBPieTable = ({ data }) => {
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    if (data && data.labels) {
      const table = [];

      for (var i = 0; i < data.labels.length; i++) {
        table.push({
          label: data.labels[i],
          percentage: data.percentages[i],
          absolute_number: Math.trunc(
            (data.checkout_customers * data.percentages[i]) / 100
          ),
        });
      }

      setTableData(table);
    }
  }, [data]);
  return (
    <div className="d-flex flex-column dbpie-table-container ">
      {tableData.map((item, idx) => {
        return (
          <div
            key={"dpt_" + idx}
            data-others={item.label.toLowerCase() === "other"}
            data-top={idx === 0}
            className="dbpie-row d-flex flex-wrap flex-grow-1 justify-content-between"
          >
            <div className="d-flex piecell_label">{item.label}</div>
            <div className="d-flex piecell_percentage">{item.percentage}%</div>
            <div className="d-flex piecell_abs">{item.absolute_number}</div>
          </div>
        );
      })}
    </div>
  );
};

export default DBPieTable;
