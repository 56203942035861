import React, { useEffect,useState,useRef  }  from 'react';
import { observer } from "mobx-react-lite"
 import MerchantsFilter from './MerchantsFilter';
 import { state } from "../../state/stateManager";
const MerchantsHeader = observer(({count, onSearch, onChannelFilter,onCountryFilter})=> {
    const scrollerRef = useRef(null);
    const [isExpended , setIsExpended] = useState(false);
    const [searching, setSearching] = useState(false);
    const inputRef = useRef(null);
    useEffect(()=> {
      
        setInterval(() => {
            scrollerRef?.current?.scrollToBottom()
        }, 100);
    },[])
 

    const keyUp =(event)=> {
        const phrse = event.target.value;
        if (phrse.length>1) {
            setSearching(true);
            onSearch(phrse);
            //show X to delete content
        }
        else if (phrse.length===0) {
            setSearching(false);
            onSearch("")
        }
    }
    const clearSearch =()=> {
        setSearching(false);
        onSearch("");

        if (inputRef.current) {
            inputRef.current.value = "";
        }
    }
    const onChannelFilterEvent = (channel) => {
        onChannelFilter(channel)
    }

    const onCountryFilterEvent = (country) => {
   
        onCountryFilter(country)
    }
    return (      
        <div className='d-flex flex-grow-1 justify-content-between merchants-header-row' style={{maxHeight:"42px"}}>
           <div className="h4 merchant-title-text">{"MERCHANTS (" + count + ")" }</div>
           <div className='d-flex align-items-start merchants-filter-container'>
                <MerchantsFilter onChannelFilter={onChannelFilterEvent} onCountryFilter={onCountryFilterEvent} sales={state.dashboardData?.today?.merchant_sales} />
            </div>
 

            <div className='merchant-search-container'>
                    <div onClick={clearSearch} className='clear-search-text' data-visible={searching}>
                        <i className="bi bi-x-circle"></i>
                    </div>
                    <input ref={inputRef} onKeyUp={keyUp} placeholder='search merchant' type='text' className='form-control'></input>
            </div>
        

         </div>   
      )
})

export default MerchantsHeader