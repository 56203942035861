import React, { useEffect, useRef, useState } from "react";
import fetcher from "../../lib/api-fetcher";
import { config } from "../../state/config";
import Login from "./Login";
const AdminLogin = ({ onAuthenticate, onCancel }) => {
  const passRef = useRef(null);
  const [authStatus, setAuthStatus] = useState(""); // [1]
  const onAuthenticateClick = () => {
    fetcher.login(passRef.current.value).then((data) => {
      if (data.success) {
        config.isAdmin = true;
        setAuthStatus("all good");
        onAuthenticate();
      } else {
        setAuthStatus("oops, something went wrong");
      }
    });
  };
  const onCancelClick = () => {
    onCancel();
  };

  const onLogin = (username, password) => {
    fetcher.login(username, password, false).then((data) => {
      if (data.success) {
        config.isAdmin = true;
        setAuthStatus("all good");
        onAuthenticate();
      } else {
        setAuthStatus("oops, something went wrong");
      }
    });
  };

  useEffect(() => {
    //add class to body
    document.body.classList.add("admin-login-body");
  }, []);
  return (
    <div className="d-flex w-100 justify-content-center">
      <Login onLogin={onLogin} onCancel={onCancelClick} />
    </div>
  );
};
export default AdminLogin;
