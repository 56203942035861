import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import DBLineChart from "../common/DBLIneChart";
import GeneralBox from "../general/GeneralBox";
import { NumericFormat } from "react-number-format";
import { state } from "../../state/stateManager";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { isMobile } from "react-device-detect";
import moment from "moment";
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    registerables as registerablesJS,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';
  import { faker } from '@faker-js/faker';
  ChartJS.register(...registerablesJS);
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
  );
  const formatNumber = (num) => {
    //console.log(num);
    if (Math.abs(num) >= 1e6) {
      return `${(num / 1e6).toFixed(1)}M`;
    } else if (Math.abs(num) >= 1e3) {
      return `${(num / 1e3).toFixed(1)}K`;
    }
    return num.toString();
  };

  
  export const options = {
    responsive: true,
    maintainAspectRatio : false,
    interaction: {
        mode: 'nearest',
        axis: 'x',
        intersect: false
      },
    scales: {
        y2: {
            display: false,
            title: {
                display: true,
                color: "#ccc",
              },
            ticks: {
                // Include a dollar sign in the ticks
                callback: function(value) {
                    return formatNumber(value);
                }
            
            }
        },
        y1: {
            display: false,
            title: {
                display: false,
                color: "#fff",
              },
            ticks: {
                // Include a dollar sign in the ticks
                callback: function(value) {
                    return '$' + formatNumber(value);
                }
            
            }
        }
    },   
    plugins: {     
      legend: {
        display: true,
        position: 'top',
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
      tooltip: {
        displayColors: false, // This option hides the color boxes
        callbacks: {
            label: function(context) {
                var label =  context.dataset.label || '';
        
                if (label) {
                label += ': ';
                }
                if (context.parsed.y !== null) {
                    let num = parseInt(context.parsed.y);
                    if (label === 'GMV: ') {
                        label+= "$" + formatNumber(num)                ;
                    }
                    else {
                        label += formatNumber(num);
                    }
                   // label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(formatNumber(num));
                }
                return label;
            },
            // footer: function (context) {
            //     let index = context[0].dataIndex;
            //     let otherDatasetIndex = context[0].datasetIndex === 0 ? 1 : 0; // Get the other dataset index
            //     let otherDataset = context[0].chart.data.datasets[otherDatasetIndex];
            //     let otherValue = otherDataset.data[index];
            //     let formattedOtherValue = formatNumber(otherValue);
            //     return `${otherDataset.label}: ${formattedOtherValue}`;
            //   },
            // labelColor: function (context) {
            //     return {
            //         borderColor: 'rgba(0, 0, 0, 0)', // Transparent border color
            //         backgroundColor: 'rgba(0, 0, 0, 0)' // Transparent background color
            //     };
            //   }                        
        }
      },
      datalabels: {
        display: true,
        align: 'top',
        color: '#fff',
        formatter: (value) => {
          return formatNumber(value);
        },
      },
    },
  };
//   const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
//   export const data = {
//     labels,
//     datasets: [
//       {
//         label: 'Dataset 1',
//         data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
//         borderColor: 'rgb(255, 99, 132)',
//         backgroundColor: 'rgba(255, 99, 132, 0.5)',
//       }
//     ],
//   };
const SalesLastXDays = observer(()=> {
    const [chartData, setChartData] = useState({labels:[], datasets:[]});
    const [numDays, setNumDays] = useState(30);
    useEffect(() => {        
        if (isMobile) {
            prepareData(7);
        }
        else{
            prepareData(30);
        }
     
    },[state.xDaysSales]);

    const prepareData = (days) => {
        if(state.xDaysSales){
            setNumDays(days);
            let labels = [];
            let data = [];
            let countArr = [];
            state.xDaysSales.data.slice(-days).forEach((item)=>{
                labels.push(moment(item.date).format("MM/DD"));
                data.push(item.amountGMV);
                countArr.push(item.count);
            });
            let chartDataObj = {
                labels: labels,
                datasets: [
                    {
                        label: 'GMV',
                        data: data,
                        borderColor: 'rgb(255, 99, 132)',
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        yAxisID: 'y1',
                        datalabels: {
                            color: '#F7A52E', // Color for Dataset 1 labels
                        }
                    },
                    {
                        label: 'Orders',
                        data: countArr,
                        borderColor: 'rgba(99, 132, 255,0.2)',
                        backgroundColor: 'rgba(99, 132, 255, 0.5)',
                        yAxisID: 'y2',
                        datalabels: {
                            color: '#fff', // Color for Dataset 1 labels
                        }
                    }
                ],
            };
        
            setChartData(chartDataObj);
            }
    }

return (      <GeneralBox
 
    hasBorder={false}
    hasBackground={true}
    css="mb-2 minheight_200 xdayssales-container"
  >

      <div className="xdayssales-title d-flex align-content-center" style={{gap:"8px"}}>
        <i style={{fontSize:"24px"}} className="bi bi-crosshair2"></i> 
        <div className="d-flex align-items-center"> Daily Sales for the last {numDays} days</div>
      </div>
      <div style={{height:"400px", width:"100%"}}>
      <Line  style={{width:"100%", minWidth:"100%"}} options={options} data={chartData} />
      </div>
  </GeneralBox>)
});

export default SalesLastXDays;